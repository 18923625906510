import { useState, useEffect } from "react";
import Button from "../../../../components/ui/Button/Button";
import "./EditForm.scss";
import { FlatDescription } from "../../../../components/forms/PolymorhicForm/FlatDescription";
import { useForm, FormProvider } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { editFlat } from "../../../../api";

/**
 * @param {{
 *  id : string,
 *  buildingId : string,
 *  defaultValues : import("../../../../components/forms/PolymorhicForm/FlatDescription").DefaultFlatFormValues | undefined
 * }}
 * @returns {import("react").ReactNode}
 */
export function FlatEditForm({ id, flat, buildingId, defaultValues, planImg }) {
  const [editMode, setEditMode] = useState(false);

  const formHook = useForm({ defaultValues });

  const queryClient = useQueryClient();
  const editFlatMutation = useMutation(editFlat, {
    onSuccess: () => {
      queryClient.invalidateQueries(["flats", "flat"]);
    },
  });

  useEffect(() => {
    formHook.reset(
        defaultValues => ({
          ...defaultValues,
          blockNumber: flat.blockNumber,
          entranceNumber: flat.entranceNumber,
          floor: flat.floor,
          number: flat.number,
          footage: flat.footage,
          roomsCount: flat.roomsCount,
          externalLink: flat.externalLink
        }))
  }, [flat]);

  return (
    <>
      <FormProvider {...formHook}>
        <form
          className="form edit-form"
          onSubmit={formHook.handleSubmit((data) => {
            if (!editMode) {
              editFlatMutation.mutate({
                id,
                body: { ...data, buildingId },
              });
            }
          })}
        >
          <p className="h8 form__title">Описание</p>
          <FlatDescription
            defaultValue={defaultValues}
            control={formHook.control}
            disabled={!editMode}
            planImg={planImg}
          />
          <div className="form__row">
            <div className="form__edit">
              <Button
                type="submit"
                buttonType={editMode ? "primary" : "secondary"}
                size={"m"}
                onClick={() => setEditMode((prev) => !prev)}
                addClass="form__edit-btn"
              >
                {editMode ? "Сохранить" : "Редактировать"}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
