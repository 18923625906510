import React from "react";

const Icon = () => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 6.55773C19.5 5.0609 18.2943 3.83259 16.7584 3.70763L16.1224 1.93532C15.5695 0.394627 13.7812 -0.395792 12.1908 0.197587L2.63569 3.76269C2.54025 3.7983 2.45378 3.84635 2.37753 3.90413C1.27675 4.32788 0.5 5.35631 0.5 6.55773V15.1394C0.5 16.7193 1.84315 18 3.5 18L16.5 18C18.1569 18 19.5 16.7193 19.5 15.1394V12.9773C20.0978 12.6475 20.5 12.0312 20.5 11.3253V9.4183C20.5 8.71242 20.0978 8.09612 19.5 7.76638V6.55773ZM17.5 7.51125V6.55773C17.5 6.03112 17.0523 5.60421 16.5 5.60421L3.5 5.60421C2.94772 5.60421 2.5 6.03112 2.5 6.55773L2.5 15.1394C2.5 15.666 2.94772 16.093 3.5 16.093L16.5 16.093C17.0523 16.093 17.5 15.666 17.5 15.1394V13.2324H16.5C14.8431 13.2324 13.5 11.9517 13.5 10.3718C13.5 8.79198 14.8431 7.51126 16.5 7.51125H17.5ZM14.2301 2.55276L14.6408 3.69716L8.2999 3.69717L12.9196 1.97351C13.4497 1.77572 14.0458 2.03919 14.2301 2.55276ZM15.5 10.3718C15.5 9.84521 15.9477 9.4183 16.5 9.4183H18.5V11.3253H16.5C15.9477 11.3253 15.5 10.8984 15.5 10.3718Z"
      fill="white"
    />
  </svg>
);

export default Icon;
