import Input from "../../ui/Input/Input";
import Select from "../../ui/Select/Select";
import SelectSearch from "../../ui/SelectSearch/SelectSearch";
import { useQuery } from "react-query";
import { getAllEmitters, getPoolByBuildingId } from "../../../api";
import { formatId } from "../../../utils";
import DateInput from "../../ui/DateInput/DateInput";
import { Controller } from "react-hook-form";
import { Loader } from "../../ui/Loader/Loader";

export const tokenizationReleaseOptions = [
  { label: "Не токенизирован", value: "NOT_TOKENIZED" },
  { label: "Ожидает токенизации", value: "WAIT_TOKENIZATION" },
  { label: "Идет токенизация", value: "TOKENIZATION" },
  { label: "Токенизирован", value: "TOKENIZED" },
];

const ReleaseDescription = ({ methods, chosenBuilding = null, disabled }) => {
  // const poolId = methods.watch("poolId")?.value;

  const { data: emitters, isLoading: isLoadingEmitters } = useQuery(
    "emitters",
    getAllEmitters
  );

  const { data: pools, isLoading: isPoolsLoading } = useQuery({
    queryFn: () => getPoolByBuildingId(chosenBuilding),
    queryKey: ["pools", chosenBuilding],
    enabled: !!chosenBuilding,
  });

  const isLoading = isLoadingEmitters && isPoolsLoading;

  const poolId = methods.watch("poolId");

  if (isLoading) return <Loader isBlue isLarge />;
  if (!pools || !emitters) return null;

  return (
      <>
          <div className="form__row">
              <div className="form__item">
                  <SelectSearch
                      withLabel="Укажите эмитента"
                      name="emitterId"
                      disabled={!emitters || disabled}
                      options={emitters?.map((emitter) => ({
                          value: emitter.id,
                          label: emitter.name || formatId(emitter.id),
                      }))}
                  />
              </div>
          </div>

          <div className="form__row">
              <div className="form__item">
                  <Input
                      withLabel="Укажите ID OIS"
                      addClass="form__input"
                      size="lg"
                      labelSize="small"
                      placeholder={""}
                      name="idOis"
                      disabled={disabled}
                  />
              </div>
          </div>

          <div className="form__row">
              <div className="form__item">
                  <Controller
                      name="poolId"
                      control={methods.control}
                      render={({field: {ref, onChange, ...field}}) => (
                          <Select
                              withLabel="Выберите пул"
                              onChangeValue={onChange}
                              options={pools?.map((pool) => ({
                                  label: pool.name || formatId(pool.id),
                                  value: pool.id,
                              }))}
                              defaultOption={methods.getValues("poolId")}
                              whiteType
                              disabled={!chosenBuilding || disabled}
                          />
                      )}
                  />
              </div>
          </div>

          <div className="form__row">
              <div className="form__item">
                  <SelectSearch
                      withLabel="Выберите квартиры"
                      isMulti
                      name={"flatsList"}
                      disabled={!poolId || disabled}
                      options={pools
                          ?.find((pool) => pool.id === poolId)
                          ?.flatsList?.map((flat) => ({
                              value: flat.id,
                              label: `кв. ${formatId(flat.number)}`,
                          }))}
                  />
              </div>
          </div>

          <div className="form__row">
              <div className="form__item">
                  <Controller
                      name="status"
                      control={methods.control}
                      render={({field: {ref, onChange, ...field}}) => (
                          <Select
                              {...field}
                              options={tokenizationReleaseOptions}
                              withLabel="Статус выпуска"
                              whiteType
                              onChangeValue={onChange}
                              defaultOption={methods.getValues("status")}
                              disabled={disabled}
                          />
                      )}
                  />
              </div>
          </div>
          <div className="form__row">
              <div className="form__item">
                  <Input
                      withLabel="Укажите цену токена"
                      addClass="form__input"
                      size="lg"
                      labelSize="small"
                      placeholder={""}
                      name="price"
                      disabled={disabled}
                  />
              </div>
          </div>
          <div className="form__row">
              <div className="form__item">
                  <DateInput
                      addClass="form__input"
                      name="plannedStartDate"
                      withLabel="Дата начала выпуска"
                      disabled={disabled}
                  />
              </div>

              <div className="form__item">
                  <DateInput
                      addClass="form__input"
                      name="plannedEndDate"
                      withLabel="Дата завершения выпуска"
                      disabled={disabled}
                  />
              </div>
          </div>
          <div className="form__row" style={{maxWidth: '205px'}}>
              <DateInput
                  addClass="form__input"
                  name="burningDate"
                  withLabel="Дата погашения выпуска"
                  disabled={disabled}
              />
          </div>
      </>
  );
};

export default ReleaseDescription;
