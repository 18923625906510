import BuildingEditForm from "../forms/EditForm/BuildingEditForm";
const ChangeDeveloper = ({ building }) => {

  return (
    <div>
      <BuildingEditForm isAdmin building={building}/>
    </div>
  );
};

export default ChangeDeveloper;
