import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { useQuery } from "react-query";
import Tabs from "../../../components/ui/Tabs/Tabs.jsx";
import SearchInput from "../SearchInput/SearchInput.jsx";
import ChangeEmitter from "./ChangeEmitter.jsx";
import { getEmitterById } from "../../../api.js";
import { Loader } from "../../../components/ui/Loader/Loader.jsx";
import usePathId from "../../../hooks/usePathId.jsx";

const Emitter = () => {
  const id = usePathId();

  const { data, isSuccess } = useQuery("emitters", async () =>
    getEmitterById(id)
  );

  return data && isSuccess ? (
    <div className="admin-user">
      <SearchInput disabled />
      <Tabs underlineView size="14" addClass="admin-user__tabs">
        <div
          label="Данные об эмитенте"
          style={{
            display: "grid",
            gridTemplateColumns: "380px 1fr",
            justifyContent: "space-between",
            gap: "10%",
          }}
        >
          <ChangeEmitter emitter={data} />
          {/* <EmitterInfo  /> */}
        </div>

        <div label="Выпуски эмитента">Выпуски эмитента</div>
      </Tabs>
    </div>
  ) : (
    <Loader isBlue isLarge />
  );
};

export default Emitter;
