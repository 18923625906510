import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classNames from "classnames";
import { useFormContext, Controller } from "react-hook-form";
const DateInput = ({
  size = "lg",
  addClass,
  disabled = false,
  withLabel,
  labelSize = "small",
  required = false,
  control,
  name,
  ...arg
}) => {
  const formContext = useFormContext();

  const labelClass = classNames({
    "input-wrapper__label": true,
    "body-smaller": labelSize === "small",
  });

  const inputClass = classNames({
    "meter-datepicker input": true,
    [`input_${size}`]: true,
    [`${addClass}`]: addClass,
    [`input_disabled`]: disabled,
    // [`input_error`]: errors[name],
  });

  return (
    <label className="input-wrapper">
      {withLabel && <span className={labelClass}>{withLabel}</span>}
      <Controller
        name={name}
        control={formContext.control}
        render={({ field }) => (
          <DatePicker
            className={inputClass}
            onChange={field.onChange}
            selected={field.value}
            dateFormat="dd.MM.yyyy"
            placeholderText="25.06.1992"
            {...field}
          />
        )}
      />
    </label>
  );
};

export default DateInput;
