import { getAllBuildings, getAllDevelopers } from "../../../../api";
import { useQuery } from "react-query";
import Button from "../../../../components/ui/Button/Button";
import { Loader } from "../../../../components/ui/Loader/Loader";
import Select from "../../../../components/ui/Select/Select";

const ChooseStep = ({
  nextStep,
  chosen,
  setChosen,
  noTitle = false,
  type = "developers", // buildings
}) => {
  const isDevelopers = type === "developers";

  const title = `Выберите ${isDevelopers ? "застройщика" : "ЖК"}`;

  const label = isDevelopers
    ? "Выберите застройщика для добавления ЖК"
    : "Выберите ЖК для добавления квартир";

  const querySettings = isDevelopers
    ? {
        queryKey: "developers",
        queryFn: getAllDevelopers,
      }
    : {
        queryKey: "buildings",
        queryFn: getAllBuildings,
      };
  const { data, isSuccess } = useQuery(querySettings);

  if (!isSuccess) return <Loader isBlue isLarge />;
  return (
    <>
      {!noTitle && <span className="modal__title">{title}</span>}
      <Select
        withLabel={label}
        options={data.map((it) => ({
          label: it.name,
          value: it.id,
        }))}
        defaultOption={title}
        onChangeValue={setChosen}
        whiteType
      />
      <Button
        disabled={!chosen}
        onClick={() => nextStep()}
        style={{ marginTop: 30 }}
        isFull
      >
        Далее
      </Button>
    </>
  );
};
export default ChooseStep;
