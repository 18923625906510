import Input from "../../ui/Input/Input";
import Select from "../../ui/Select/Select";
import Checkbox from "../../ui/Checkbox/Checkbox";
import SelectSearch from "../../ui/SelectSearch/SelectSearch";
import { InputWithSlider } from "../../ui/Input/InputWithSlider";
import { Controller } from "react-hook-form";
import { useQuery } from "react-query";
import { getAllEmitters, getFlatsByBuildingId } from "../../../api";
import { formatId } from "../../../utils";
import { RepaymentMethods } from "../../../constants";
import { Loader } from "../../ui/Loader/Loader";

const PoolDescription = ({ methods, chosenBuilding = null, disabled }) => {
  const { data: emitters, isLoading: isLoadingEmitters } = useQuery(
    "emitters",
    getAllEmitters
  );
  const { data: flats, isLoading: isLoadingFlats } = useQuery({
    queryFn: () => getFlatsByBuildingId(chosenBuilding),
    queryKey: "flats",
    enabled: !!chosenBuilding,
  });

  const isLoading = isLoadingEmitters && isLoadingFlats;

  if (isLoading) return <Loader isBlue isLarge />;

  const additionalCheck = methods.watch("exchangeTokensOnFlat");

  return (
    <>
      <div className="form__row">
        <div className="form__item">
          <Input
            type="text"
            withLabel="Название пула"
            {...inputProps({ name: "name" })}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="form__row">
        <div className="form__item">
          <SelectSearch
            withLabel="Укажите эмитента"
            name="emitterId"
            disabled={!emitters || disabled}
            options={emitters?.map((emitter) => ({
              value: emitter.id,
              label: emitter.name || formatId(emitter.id),
            }))}
          />
        </div>
      </div>
      {flats && (
        <div className="form__row">
          <div className="form__item">
            <SelectSearch
              withLabel="Выберите квартиры"
              isMulti
              name={"flatsList"}
              options={flats.map((flat) => ({
                value: flat.id,
                label: `кв. ${formatId(flat.number)}`,
              }))}
              disabled={disabled}
            />
          </div>
        </div>
      )}

      <div className="form__row">
        <div className="form__item">
          <Controller
            name="repaymentMethod"
            control={methods.control}
            render={({ field: { ref, onChange, ...field } }) => (
              <Select
                whiteType
                withLabel="Выберите способ погашения ЦФА"
                onChangeValue={onChange}
                options={Object.keys(RepaymentMethods).map((it) => ({
                  value: it,
                  label: RepaymentMethods[it],
                }))}
                defaultOption={methods.getValues("repaymentMethod")}
                disabled={disabled}
              />
            )}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          <Controller
            name="percentOfIncome"
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <InputWithSlider
                defaultValue={5}
                withLabel="Выберите процент дохода"
                step={0.1}
                disabled={disabled}
                {...inputProps({ name: "percentOfIncome" })}
              />
            )}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          <Input
            type="number"
            withLabel="Выберите ожидаемую цену на выходе"
            disabled={disabled}
            {...inputProps({ name: "exitPrice" })}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          <label className="input-wrapper">
            <span className={"input-wrapper__label body-smaller"}>
              Дополнительная опция включения акций
            </span>
            <Checkbox
              name="exchangeTokensOnFlat"
              disabled={disabled}
              withLabel="Обмен токенов на квартиру"
            />
          </label>
        </div>
      </div>
      {additionalCheck && (
        <div className="form__row">
          <div className="form__item">
            <Input
              type="text"
              withLabel="Ссылка на страницу с описанием акции"
              disabled={disabled}
              {...inputProps({ name: "externalPromoLink" })}
            />
          </div>
        </div>
      )}
    </>
  );
};

function inputProps({ withLabel, name, placeholder = "" }) {
  return {
    placeholder,
    ...(withLabel ? { withLabel } : {}),
    name,
    addClass: "form__input",
    size: "lg",
    labelSize: "small",
  };
}

export default PoolDescription;
