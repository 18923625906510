import Button from "../Button/Button";
import Icon from "./Icon";
import "./ReleaseLink.scss";
import CloseButton from "../CloseButton/CloseButton";
import Modal from "react-modal";
import useModal from "../../../hooks/useModal";
import { formatDateFrom, formatId } from "../../../utils";
import { useQuery } from "react-query";
import { getReleaseById } from "../../../api";

const ReleaseLink = ({ release, noIcon = false, children }) => {
  const [modalIsOpen, openModal, closeModal] = useModal();
  const { data, isLoading } = useQuery(["releases", release.id], async () =>
    getReleaseById(release.id)
  );
  const docReleaseLink = data?.docReleaseLink;
  const riskNotificationLink = data?.riskNotificationLink;

  return (
    <>
      <Button addClass="release-link" isClean onClick={openModal}>
        <span className="link link_blue">
          {children || release.name || formatId(release.id)}
        </span>
        {!noIcon && <Icon />}
      </Button>
      {release && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modal-wrapper"
          overlayClassName="modal-overlay"
        >
          <div className="modal">
            <CloseButton addClass="modal__close-btn" onClick={closeModal} />
            <p className="h8" style={{ marginBottom: 10 }}>
              {formatId(release.id)}
            </p>

            <dl className="dl">
              {data?.plannedEndDate && (
                  <div>
                    <dt>Ожидается распределение токенов до</dt>
                    <dd>
                      <span>{formatDateFrom(data?.plannedEndDate, true)}</span>
                    </dd>
                  </div>
              )}

              {data?.burningDate && (
                  <div>
                    <dt>Дата погашения ЦФА</dt>

                    <dd>
                      <span>{formatDateFrom(data?.burningDate, true)}</span>
                  </dd>
                </div>
              )}

              {docReleaseLink && (
                <div>
                  <dt>Решение о выпуске</dt>
                  <dd>
                    <a className="link link_blue" href={docReleaseLink}>
                      Ознакомиться
                    </a>
                  </dd>
                </div>
              )}

              {riskNotificationLink && (
                <div>
                  <dt>Уведомление о рисках</dt>
                  <dd>
                    <a className="link link_blue" href={riskNotificationLink}>
                      Ознакомиться
                    </a>
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ReleaseLink;
