import RoundedBlock from "../../../components/ui/RoundedBlock/RoundedBlock";
import {InfoKey, InfoPair, InfoValue} from "../../../components/ui/InfoPair/InfoPair";

const BuildingInfo = ({ building, style, ...props }) => {
    if (!building) return null;

    return (
        <div
            className="flexbox flexbox_direction-column"
            style={{...style, marginTop: "50px"}}
            {...props}
        >
            <p className="h8 form__title" style={{marginBottom: "10px"}}>
                Информация
            </p>
            <RoundedBlock>
                {[
                    ["Застройщик", building?.name]
                ].map(([key, value], ind) => (
                        <InfoPair key={`${value}-${ind}`}>
                            <InfoKey>{key}</InfoKey>
                            <InfoValue>{value}</InfoValue>
                        </InfoPair>
                    ))}
            </RoundedBlock>
        </div>
    )
}
export default BuildingInfo;