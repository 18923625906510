import Button from "../ui/Button/Button";
import Icon from "./Icon";
import "./WalletButton.scss";

const WalletButton = ({ balance }) => {
  return (
    <Button addClass="wallet-button">
      <div className="wallet-button__container">
        <Icon />
        <span>{balance} ₽</span>
      </div>
    </Button>
  );
};

export default WalletButton;
