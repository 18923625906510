import { addRelease } from "../../../../api";
import Button from "../../../../components/ui/Button/Button";

import { useMutation, useQueryClient } from "react-query";

import { useForm, FormProvider } from "react-hook-form";

import { formatDate } from "../../../../utils";
import ReleaseDescription from "../../../../components/forms/PolymorhicForm/ReleaseDescription";
import ErrorMessage from "../../../../components/ui/ErrorMessage/ErrorMessage";
import { tokenizationReleaseOptions } from "../../../../components/forms/PolymorhicForm/ReleaseDescription";
const AddReleaseForm = ({ closeModal, chosenDeveloper }) => {
  const queryClient = useQueryClient();

  const formHook = useForm({
    defaultValues: {
      status: tokenizationReleaseOptions[0].value,
    },
  });

  const addReleaseMutation = useMutation({
    mutationFn: addRelease,
    onSuccess: () => {
      queryClient.invalidateQueries("releases");
      closeModal();
    },
  });

  const onSubmit = (values) => {
    addReleaseMutation.mutate({
      ...values,
      buildingId: chosenDeveloper,
      emitterId: values.emitterId.value,
      flatsList: values.flatsList && values?.flatsList.map((it) => it.value),
      poolId: values.poolId,
      plannedStartDate: formatDate(values.plannedStartDate),
      plannedEndDate: formatDate(values.plannedEndDate),
    });
  };

  return (
    <FormProvider {...formHook}>
      <form
        className="form feedback"
        onSubmit={formHook.handleSubmit(onSubmit)}
      >
        <ReleaseDescription
          methods={formHook}
          chosenBuilding={chosenDeveloper}
        />

        <Button
          // isLoading={addEmitterMutation.isLoading}
          // disabled={addEmitterMutation.isLoading}
          addClass="modal__btn"
          isFull
          type="submit"
        >
          Добавить выпуск
        </Button>

        <ErrorMessage
          forRequest={addReleaseMutation}
          isHidden={addReleaseMutation.isLoading}
        />
      </form>
    </FormProvider>
  );
};

export default AddReleaseForm;
