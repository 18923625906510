import { useMutation, useQueryClient } from "react-query";
import { useUserStore } from "../store";
import { login } from "../api";
const useLogin = () => {
  const setIsLoggedIn = useUserStore((state) => state.login);
  const setUser = useUserStore((state) => state.setUser);
  const queryClient = useQueryClient();

  const loginMutation = useMutation({
    mutationFn: (body) => {
      return login(body);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["user"] });

      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      setUser(data?.user);
      setIsLoggedIn();
      // closeModal();
    },
  });

  return loginMutation;
};

export default useLogin;
