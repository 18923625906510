import classNames from "classnames";
import "./AdminTable.scss";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Label from "../../../components/ui/Label/Label";
import {
  tokenizedStatusLabelMap,
  tokenizedReleaseStatusLabelMap,
  orderStatusLabelMap,
} from "./AdminTableFormaters";
import ProgressLabel from "../../../components/ui/Label/ProgressLabel";
const AdminTable = ({ data = {} }) => {
  const getColClasses = (title) =>
    classNames({
      "a-table__col": true,
      "a-table__col_centered": [
        // "ID ЦМ пользователя",
        // "ID застройщика",
        // "Общий метраж",
        // "Количество квартир",
        // "Токенизация",
        // "Стоимость токена",
      ].includes(title),
      // "a-table__col_short": shortTitles.includes(title),
    });

  const colsRef = useRef([]);

  const onTitleClickHandler = (element) => {
    const domElem = element.current;
    const elemWidth = domElem.offsetWidth;
    if (domElem.classList.contains("a-table__col_short")) {
      domElem.classList.remove("a-table__col_short");
      domElem.style.width = "auto";
      return;
    }
    domElem.classList.add("a-table__col_short");
    domElem.style.width = elemWidth / 2 + "px";
  };

  useEffect(() => {
    if (colsRef) {
      colsRef.current
        .filter((it) => it.current.classList.contains("a-table__col_short"))
        .forEach((shortElem) => {
          const domElem = shortElem.current;
          const elemWidth = domElem.offsetWidth;
          domElem.style.width = elemWidth / 2 + "px";
        });
    }
  }, [colsRef]);

  return (
    <div className="a-table">
      {Object.keys(data).map((title, index) => {
        const element = React.createRef();

        colsRef.current[index] = element;

        return (
          <div
            className={getColClasses(title)}
            key={`${index}-${title}`}
            ref={element}
          >
            <div
              className="a-table__header"
              onClick={() => onTitleClickHandler(element)}
            >
              {title}
            </div>
            {data[title].map((value, index) => {
              return title === "Токенизация" ||
                title === "Статус токенизации" ||
                title === "Статус сделки" ? (
                <div
                  className="a-table__item a-table__item_label"
                  key={`${index}-${value}`}
                >
                  <ProgressLabel status={value}>
                    {title === "Статус токенизации" &&
                      tokenizedStatusLabelMap[value ?? "NOT_TOKENIZED"]}
                    {title === "Токенизация" &&
                      tokenizedReleaseStatusLabelMap[value ?? "NOT_TOKENIZED"]}
                    {title === "Статус сделки" && orderStatusLabelMap[value]}
                  </ProgressLabel>
                </div>
              ) : typeof value === "string" || typeof value === "number" ? (
                <div
                  className="a-table__item"
                  key={`${index}-${value}`}
                  onClick={() => {
                    navigator.clipboard.writeText(value);
                  }}
                >
                  {value}
                </div>
              ) : (
                <Link
                  className="a-table__item"
                  key={`${index}-${value}`}
                  to={value?.link}
                >
                  {value?.name}
                </Link>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default AdminTable;
