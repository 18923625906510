import Popup from "../Popup/Popup";

const LimitInvestor = ({ limit = 6000000 }) => {
  return (
    <div className="limit-investor">
      <Popup>
        У пользователей которые не являются квалифицированными инвесторами,
        общая сумма покупок ЦФА не может превышать {limit.toLocaleString()} руб.
        в год.
      </Popup>
      <p>
        Лимит инвестора: <b>{limit.toLocaleString()} ₽</b>
      </p>
    </div>
  );
};

export default LimitInvestor;
