import classNames from "classnames";
import Button from "../../../ui/Button/Button";
import Label from "../../../ui/Label/Label";
import Collapse from "../../../ui/Collapse/Collapse";
import { Fragment, useState } from "react";
import CollapseToggler from "../../../ui/Collapse/CollapseToggler";
import PurchaseCard from "../../../ui/PurchaseCard/PurchaseCard";
import { formatId, numWord } from "../../../../utils";
import { RepaymentMethods } from "../../../../constants";
import { formatDateFrom } from "../../../../utils";
import "moment/locale/ru";
import ReleaseLink from "../../../ui/ReleaseLink/ReleaseLink";
import PoolLabel from "../../../ui/PoolLabel/PoolLabel";
import StatusLabel from "../../../ui/Card/StatusLabel";
import { formatTokensForDisplay } from "../../../../utils";
const CardHorizontalToken = ({ tokenData }) => {
  const [collapseOpened, setCollapseOpened] = useState(false);
  const [purchaseCardOpened, setPurchaseCardOpened] = useState(false);
  const [purchaseCardSellOpened, setPurchaseCardSellOpened] = useState(false);

  const cardClass = classNames({
    "card card_h card_token": true,
    "card_collapse-opened": collapseOpened,
  });

  if (!tokenData) return null;

  const { releases, pool, building, developer } = tokenData;
  const availableTokens = formatTokensForDisplay(tokenData.amount);

  if (!building) return null;

  return (
    <article className={cardClass}>
      <div className="card__labels-list">
        <StatusLabel data={tokenData} />
        <Label labelType="secondary">1 токен = 1 м²</Label>
        {pool.repaymentMethod && (
          <Label labelType="secondary">
            {RepaymentMethods[pool.repaymentMethod]}
          </Label>
        )}
      </div>

      <PoolLabel addClass="card__pool-label">
        {pool.name || formatId(pool.id)}
      </PoolLabel>

      <img className="card__img" src={building?.imagesLinks[0]} alt="" />

      <div className="card__body">
        {/* <div className="card__labels-list">
          <Label labelType="tertiary">Построено 25%</Label>
        </div> */}

        <div className="sell-info">
          <div className="sell-info__col">
            <span className="sell-info__title">Цифровой токен</span>
            <span className="sell-info__key">{building.name}</span>
            <p className="sell-info__value">{developer.name}</p>

            <span className="sell-info__key">{building.city}</span>
            <p className="sell-info__value">{building.district}</p>

            <span className="sell-info__key">
              1 токен ≈ {releases[0].price} ₽
            </span>
            {
              // тут другая цифра с бэка должна быть ^
            }
            <p className="sell-info__value">Рыночная цена на данный момент</p>
          </div>

          <div className="sell-info__col">
            <span className="sell-info__title">Количество</span>
            <span className="sell-info__key">
              {availableTokens}{" "}
              {numWord(availableTokens, ["токен", "токена", "токенов"])}
            </span>
            <p className="sell-info__value">Общее кол-во токенов</p>

            {releases.map((release, i) => (
              <span
                key={`my-token-release-${release.id}`}
                className={`sell-info__key${i === 0 ? " with-separator" : ""}`}
              >
                {
                  <ReleaseLink release={release}>
                    {formatId(release.id)}
                  </ReleaseLink>
                }
                <p className="sell-info__value">
                  {formatTokensForDisplay(release.amount)}
                </p>
              </span>
            ))}
          </div>

          {pool.repaymentMethod === "GUARANTEED_INCOME" && (
            <div className="sell-info__col">
              <span className="sell-info__title">Гарантированный доход</span>
              <span className="sell-info__key">{pool.exitPrice} ₽</span>
              <p className="sell-info__value">Общая сумма</p>
            </div>
          )}

          {pool.repaymentMethod === "SALES_INCOME" && (
            <div className="sell-info__col">
              <span className="sell-info__title">Доход от продаж</span>
              <span className="sell-info__key">
                {pool.exitPrice} ₽ + % от продажи
              </span>
              <p className="sell-info__value">Гарантированный доход</p>
            </div>
          )}
        </div>

        <div className="card__operations">
          <CollapseToggler
            addClass={"card__collapse-btn"}
            collapseOpened={collapseOpened}
            setCollapseOpened={setCollapseOpened}
          >
            Операции
          </CollapseToggler>
          <div className="card__btns">
            <Button
              size={"xs"}
              addClass="card__buy-btn"
              // onClick={() => setPurchaseCardOpened(true)}
              isLink={`/${pool.id}`}
            >
              Купить ещё
            </Button>
            {/* <Button
              size={"xs"}
              addClass="card__buy-btn"
              onClick={() => setPurchaseCardSellOpened(true)}
            >
              Продать
            </Button> */}
          </div>
        </div>

        <Collapse isOpened={collapseOpened} addClass="card__collapse">
          <div className="sell-info sell-info_single">
            <div className="sell-info__col">
              {releases.map((release) => {
                if (release.operations.length === 0) return null;

                return release.operations.map((op) => (
                  <Fragment key={op.initOrder.internalId}>
                    <span className="sell-info__key">
                      Вы купили {formatTokensForDisplay(op.amount)}{" "}
                      {numWord(op.amount, ["токен", "токена", "токенов"])}{" "}
                      {building.name} выпуск{" "}
                      {
                        <ReleaseLink release={release} noIcon>
                          {formatId(release.id)}
                        </ReleaseLink>
                      }{" "}
                      за {op.price} ₽
                    </span>
                    <p className="sell-info__value">
                      {formatDateFrom(op.date)}
                    </p>
                  </Fragment>
                ));
              })}
            </div>
          </div>
        </Collapse>
      </div>

      {/* <PurchaseCard
        isHorizontal
        addClass="card__purchase"
        isOpened={purchaseCardOpened}
        setIsOpened={setPurchaseCardOpened}
        buttonText="Купить токены"
      />

      <PurchaseCard
        isHorizontal
        addClass="card__purchase"
        isOpened={purchaseCardSellOpened}
        setIsOpened={setPurchaseCardSellOpened}
        buttonText="Выставить на продажу"
      /> */}
    </article>
  );
};

export default CardHorizontalToken;
