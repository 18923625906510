import PersonalEditForm from "../../../components/forms/PersonalEditForm/PersonalEditForm";
const ChangePersonal = () => {
  return (
    <div>
      <PersonalEditForm isAdmin />
    </div>
  );
};

export default ChangePersonal;
