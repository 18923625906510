import { useQueryClient } from "react-query";
import { makeAdmin } from "../../../api";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
const useMakeAdmin = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const makeAdminMutation = useMutation({
    mutationFn: () => makeAdmin(id),
    onSuccess: () => {
      queryClient.invalidateQueries("users", id);
    },
  });
  return makeAdminMutation;
};
export default useMakeAdmin;
