import { formatDate, formatDateFrom, formatId, numWord } from "../../../utils";

/** @type {Record<import("../../../api").FlatTokenizationStatus, string>} */
export const tokenizedStatusLabelMap = {
  NOT_TOKENIZED: "Не токенизирована",
  WAIT_TOKENIZATION: "Ожидает токенизации",
  TOKENIZATION: "Идет токенизация",
  TOKENIZED: "Токенизирована",
};

export const tokenizedReleaseStatusLabelMap = {
  NOT_TOKENIZED: "Не токенизирован",
  WAIT_TOKENIZATION: "Ожидает токенизации",
  TOKENIZATION: "Идет токенизация",
  TOKENIZED: "Токенизирован",
};

/** @type {Record<import("../../../api").FlatBookingStatus, string>} */
export const bookingStatusLabelMap = {
  NOT_BOOKED: "Доступна для бронивания",
  BOOKED: "Забронирована",
  RELEASED: "Продана",
};

/** @type {Record<import("../../../api").OrderStatus, string>} */
export const orderStatusLabelMap = {
  INIT_PROSPECTUS_CREATED: "Ожидается отправка в ЦФА ХАБ",
  INIT_PROSPECTUS_SENT: "Отправлено в ЦФА ХАБ",
  WAITING_FOR_PAYMENT: "Деньги зачисляются",
  WAITING_FOR_CONFIRMATION: "Проверка оплаты",
  PAYMENT_CONFIRMATION_SENT: "Подтверждение оплаты",
  PAYMENT_CONFIRMED: "Оплата подтверждена",
  CANCELED_BY_OIS: "Отклонено ЦФА ХАБ",
  WAITING_ISSUE: "Подтверждено ЦФА ХАБ, ожидается выпуск",
  CLOSED: "Завершено",
  CANCELED_BY_INVESTOR: "Отклонено",
  CANCEL_CONFIRMED_BY_OIS: "Отклонено ЦФА ХАБ",
  CANCELED: "Выпуск отменен",
};

export function formatFlatsInfo(flatsData) {
  if (!flatsData || flatsData.length === 0) return null;

  return {
    "Номер Квартиры": flatsData.map((flat) => ({
      name: `Кв.  ${flat.number}`,
      link: `../flats/${flat.id}`,
    })),
    "ID Квартиры": flatsData.map((flat) => ({
      name: formatId(flat.id),
      link: `../flats/${flat.id}`,
    })),
    "ID ЖК": flatsData.map((flat) => ({
      name: formatId(flat.building.id),
      link: `../buildings/${flat.building.id}`,
    })),

    ЖК: flatsData.map((flat) => ({
      name: flat.building.name,
      link: `../buildings/${flat.building.id}`,
    })),
    Метраж: flatsData.map((flat) => `${flat.footage} м²`),
    Адрес: flatsData.map(
      (flat) =>
        `${flat.blockNumber} корпус, ${flat.entranceNumber} подъезд, ${flat.floor} этаж`
    ),
    "Статус токенизации": flatsData.map((flat) => flat.tokenizationStatus),
    "Статус бронирования": flatsData.map(
      (flat) => bookingStatusLabelMap[flat.bookingStatus ?? "NOT_BOOKED"]
    ),
  };
}

export function formatReleasesInfo(releasesData) {
  if (!releasesData || releasesData.length === 0) return null;
  return {
    "ID выпуска": releasesData?.map((it) => ({
      name: formatId(it.id),
      link: `../releases/${it.id}`,
    })),
    "ID пула": releasesData?.map((release) => ({
      name: formatId(release.pool.id),
      link: `../pools/${release.pool.id}`,
    })),

    "ID эмитента": releasesData?.map((it) => formatId(it.emitter.userId)),

    ЖК: releasesData?.map((it) => ({
      name: it.building?.name,
      link: `../buildings/${it.building?.id}`,
    })),

    "Общий метраж": releasesData?.map((it) => `${it.totalFootage} м²`),
    "Количество квартир": releasesData?.map(
      (it) =>
        `${it.flatsList.length} ${numWord(it.flatsList.length, [
          "квартира",
          "квартиры",
          "квартир",
        ])}`
    ),
    Токенизация: releasesData?.map((it) => it.status),
    "Стоимость токена": releasesData?.map((it) => `${it.price} ₽`),
  };
}

export function formatOrdersInfo(ordersData) {
  if (!ordersData || ordersData.length === 0) return null;
  return {
    "ID заявки": ordersData?.map((it) => ({
      name: formatId(it.id),
      link: `../orders/${it.id}`,
    })),
    "ID заявки в ОИС": ordersData?.map((it) => formatId(it.idOis)),
    "ID эмитента": ordersData?.map((it) => formatId(it.issuer?.id)),

    "ID выпуска": ordersData?.map((it) => formatId(it.release?.id)),
    "ID покупателя": ordersData?.map((it) => formatId(it.owner?.id)),
    "Кол-во": ordersData?.map((el) => el.amount / 100),
    "Цена токена": ordersData?.map((el) => el.priceForOne),
    "Статус сделки": ordersData.map((it) => it.status),
    Дата: ordersData?.map((it) => formatDateFrom(it.createdAt)),
    // Время: ordersData?.map((el) => el.time),
  };
}
