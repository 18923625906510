import Container from "../components/layout/Container/Container";

const AboutPage = () => {
  return (
    <section className="home">
      <Container isReading>
        <h1 className="title">­О проекте</h1>
        <div className="text-block">
          <p>
            <b>Цифровой метр. Новостройки</b> – это специализированная площадка,
            в которой с инвестиционными целями можно приобрести «условные» м²
            недвижимости в строящемся жилом комплексе. Приобретается денежное
            требование.
          </p>

          <p>
            Приобретение токенизированных квадратных метров осуществляется путем
            приобретения цифровых финансовых активов (ЦФА), удостоверяющих
            денежное требование покупателя ЦФА к эмитенту ЦФА.
          </p>

          <p>
            Выпуск ЦФА осуществляется через Оператора информационной системы,
            который внесен Банком России в реестр ОИС. Каждый выпуск проходит
            проверку Центральным банком Российской Федерации.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default AboutPage;
