import CloseButton from "../../../components/ui/CloseButton/CloseButton";
import { useState } from "react";

import AddPoolForm from "../forms/AddForm/AddPoolForm";
import ChooseStep from "../forms/AddForm/ChooseStep";

const AddPoolModal = ({ closeModal }) => {
  const [chosenBuilding, setChosenBuilding] = useState("");

  const [step, setStep] = useState(1);

  return (
    <div className={`modal${step === 3 ? " modal_lg" : ""}`}>
      <CloseButton addClass="modal__close-btn" onClick={closeModal} />
      {step === 1 && (
        <ChooseStep
          type="buildings"
          nextStep={() => setStep(2)}
          chosen={chosenBuilding}
          setChosen={setChosenBuilding}
        />
      )}

      {step === 2 && (
        <AddPoolForm chosenBuilding={chosenBuilding} closeModal={closeModal} />
      )}
    </div>
  );
};

export default AddPoolModal;
