import { useQuery } from "react-query";
import { getFavoritesByUserId } from "../../../api";
import useUserId from "../../useUserId";
import useIsLoggedIn from "../../useIsLoggedIn";

const useFavorites = () => {
  const userId = useUserId();
  const isLoggedIn = useIsLoggedIn();
  return useQuery({
    queryKey: ["favorites"],
    queryFn: () => getFavoritesByUserId(userId),
    enabled: isLoggedIn,
  });
};

export default useFavorites;
