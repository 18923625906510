import { Link } from "react-router-dom";
import {
  InfoKey,
  InfoPair,
  InfoValue,
} from "../../../components/ui/InfoPair/InfoPair";
import RoundedBlock from "../../../components/ui/RoundedBlock/RoundedBlock";
import {
  formatDateFrom,
  formatId,
  getFullAdress,
  sumFootage,
} from "../../../utils";
import Label from "../../../components/ui/Label/Label";
import { tokenizedReleaseStatusLabelMap } from "../AdminTable/AdminTableFormaters";
import Button from "../../../components/ui/Button/Button";
import ProgressLabel from "../../../components/ui/Label/ProgressLabel";
import { get } from "react-hook-form";

export function ReleaseInfo({ release, style, ...props }) {
  if (!release || !release.building) return null;

  return (
    <div
      className="flexbox flexbox_direction-column"
      style={{ ...style, marginTop: "50px" }}
      {...props}
    >
      <p className="h8 form__title" style={{ marginBottom: "10px" }}>
        Информация
      </p>
      <RoundedBlock>
        {[
          [
            "Статус выпуска",
            <ProgressLabel status={release.status}>
              {tokenizedReleaseStatusLabelMap[release.status]}
            </ProgressLabel>,
          ],
          ["ID эмитента", formatId(release?.emitter?.id)],
          [
            "Дата начала выпуска",
            formatDateFrom(release?.plannedStartDate, true),
          ],
          [
            "Дата завершения выпуска",
            formatDateFrom(release?.plannedEndDate, true),
          ],

          ["Общий метраж пула", release?.totalFootage],

          ["Цена ЦМ [токена]", `${release?.price} ₽`],

          ["Участие в сделках", "0"],
        ].map(([key, value], ind) => (
          <InfoPair key={`${value}-${ind}`}>
            <InfoKey>{key}</InfoKey>
            <InfoValue>{value}</InfoValue>
          </InfoPair>
        ))}
      </RoundedBlock>

      <RoundedBlock>
        {[
          [
            "Название ЖК",
            <Link
              className="link link_blue"
              target="_blank"
              to={`../buildings/${release?.building?.id}`}
            >
              {release?.building?.name}
            </Link>,
          ],
          ["ID ЖК", formatId(release?.building?.id)],

          ["Адрес ЖК", getFullAdress(release?.building)],

          ["ID пула", formatId(release?.pool?.id)],
        ].map(([key, value], ind) => (
          <InfoPair key={`${value}-${ind}`}>
            <InfoKey>{key}</InfoKey>
            <InfoValue>{value}</InfoValue>
          </InfoPair>
        ))}
      </RoundedBlock>

      <Button isLink={`../../${release.pool.id}`} target="_blank">
        К странице пула на сайте
      </Button>
    </div>
  );
}
