import { addDeveloper } from "../../../../api";
import Button from "../../../../components/ui/Button/Button";
import Flexbox from "../../../../components/ui/Flexbox/Flexbox";
import Input from "../../../../components/ui/Input/Input";
import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import useUserId from "../../../../hooks/useUserId";
const AddDeveloperForm = ({ closeModal }) => {
  const [preview] = useState(null);
  const [developerName, setDeveloperName] = useState("");
  const queryClient = useQueryClient();

  const methods = useForm();
  const userId = useUserId();
  const nameChangeHandler = (value) => {
    //

    setDeveloperName(value);
  };

  const addDeveloperMutation = useMutation({
    mutationFn: (body) => {
      return addDeveloper(body);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["developers"],
      });
      closeModal();
    },
  });

  const onSubmit = (data) => {
    addDeveloperMutation.mutate({
      ...data,
      userId: userId,
    });
  };

  return (
    <FormProvider {...methods}>
      <form className="form feedback" onSubmit={methods.handleSubmit(onSubmit)}>
        {/* <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="ID представителя"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              name="userId"
            />
          </div>
        </div> */}

        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="Название застройщика"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              name="name"
              onChange={(evt) => nameChangeHandler(evt.target.value)}
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="Описание"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              name="description"
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="ИНН"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              name="inn"
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__item">
            {/* <UploadField
            setValue={() => {}}
            preview={preview}
            handleSetPreview={setPreview}
            withLabel="Загрузите логотип застройщика"
          /> */}
          </div>
        </div>

        {developerName && (
          <div className="form__row">
            <div className="form__item">
              <div className="input-wrapper">
                <span className="input-wrapper__label body-smaller">
                  Отображение в строке выдачи
                </span>
              </div>

              <Flexbox addClass="form__preview">
                <span className="form__preview-name">{developerName}</span>
                {preview && (
                  <img className="form__preview-image" src={preview} alt="" />
                )}
              </Flexbox>
            </div>
          </div>
        )}

        {true ? (
          <Button
            isLoading={addDeveloperMutation.isLoading}
            disabled={addDeveloperMutation.isLoading}
            addClass="modal__btn"
            isFull
            type="submit"
          >
            Добавить застройщика
          </Button>
        ) : (
          <Button addClass="modal__btn" isFull disabled>
            Запрос отправлен
          </Button>
        )}
      </form>
    </FormProvider>
  );
};

export default AddDeveloperForm;
