import "./index.scss";
import "react-dropdown/style.css";
import { Controller } from "react-hook-form";
import Select from "./Select";

/**
 * Dropdown element can't be used with react-hook-form
 * without using Controller. This component tries to capture
 * most use cases.
 * @param {{
 * name : string,
 * control : import("react-hook-form").Control<FieldValues, any>,
 * options : import("react-dropdown").Option[],
 * addClass : string,
 * withLabel : string,
 * whiteType : boolean,
 * disabled : boolean,
 * onChangeValue : (value : string) => void,
 * onChange : (value : import("react-dropdown").Option) => void,}}
 * @returns {import("react").ReactNode}
 */
export function FormSelect({
  name,
  control,
  options,
  disabled,
  onChangeValue,
  ...arg
}) {
  return (
    <Controller
      name={name}
      control={control}
      disabled={disabled}
      render={({ field: { ref, onChange: onChangeForm, value, ...field } }) => (
        <Select
          options={options}
          onChangeValue={(value) => {
            onChangeForm(value);
            onChangeValue?.(value);
          }}
          defaultOption={options.find(
            (option) => option.value === value.toString()
          )}
          {...arg}
          {...field}
        />
      )}
    />
  );
}
