import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { useQuery } from "react-query";
import Tabs from "../../../components/ui/Tabs/Tabs";
import SearchInput from "../SearchInput/SearchInput";
import ChangePool from "./ChangeRelease.jsx";
import { getPoolById, getReleaseById } from "../../../api";
import { Loader } from "../../../components/ui/Loader/Loader";
import { ReleaseInfo } from "./ReleaseInfo.jsx";
import { formatFlatsInfo } from "../AdminTable/AdminTableFormaters.js";
import AdminTable from "../AdminTable/AdminTable";
import usePathId from "../../../hooks/usePathId.jsx";
import ChangeRelease from "./ChangeRelease.jsx";

const Release = () => {
  const id = usePathId();

  const { data, isLoading } = useQuery(["releases", id], async () =>
    getReleaseById(id)
  );
  if (isLoading) return <Loader isBlue isLarge />;
  if (!data) return null;

  return (
    <div className="admin-user">
      <SearchInput disabled />
      <Tabs underlineView size="14" addClass="admin-user__tabs">
        <div
          label="Данные о выпуске"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 540px",
            justifyContent: "space-between",
            gap: "10%",
          }}
        >
          <ChangeRelease release={data} />
          <ReleaseInfo release={data} />
        </div>
        <div label="Список квартир">
          <AdminTable data={formatFlatsInfo(data.flatsList)} />
        </div>
      </Tabs>
    </div>
  );
};

export default Release;
