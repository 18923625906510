import "./RoundedBlock.scss";
const RoundedBlock = ({ children }) => {
  return (
    <div className="flexbox flexbox_direction-column rounded-block">
      {children}
    </div>
  );
};

export default RoundedBlock;
