import "./Flexbox.scss";
import classNames from "classnames";

const Flexbox = ({
  addClass = "",
  flexDirection = "row",
  align = "left",
  fitContent = false,
  children,
  ...args
}) => {
  const flexClasses = classNames({
    flexbox: true,
    [`flexbox_align-${align}`]: true,
    [`flexbox_direction-${flexDirection}`]: true,
    [`${addClass}`]: addClass,
    [`flexbox_fit-content`]: fitContent,
  });

  return <div className={flexClasses} {...args}>{children}</div>;
};

export default Flexbox;
