const usePurchaseData = () => {
  const savePurchaseData = ({ id, price, amount }) => {
    localStorage.setItem("purchaseData", JSON.stringify({ id, price, amount }));
  };

  const getPurchaseData = () => {
    return JSON.parse(localStorage.getItem("purchaseData"));
  };

  const clearPurchaseData = () => {
    localStorage.removeItem("purchaseData");
  };

  return { savePurchaseData, getPurchaseData, clearPurchaseData };
};

export default usePurchaseData;
