import classNames from "classnames";
import "./index.scss";
import Label from "../Label/Label";

const InfoBlock = () => {
  const cardClass = classNames({
    "info-block": true,
  });

  return (
    <article className={cardClass}>
      <div className="info-block__labels-list">
        <Label labelType="primary" size={"xs"}>
          Токенов осталось: XXX,XX
        </Label>
        <Label labelType="primary" size={"xs"}>
          Построено 25%
        </Label>
      </div>

      <div className="info-block__row">
        <div className="info-block__col">
          <span className="info-block__key">ЖК «Аист»</span>
          <p className="info-block__value base-14 text-gray">
            СК «Красная стрела»
          </p>
        </div>

        <div className="info-block__col">
          <span className="info-block__key">Санкт-Петербург</span>
          <p className="info-block__value base-14 text-gray">
            Красносельский район
          </p>
        </div>

        <div className="info-block__col">
          <span className="info-block__key">
            Сдается в I квартале 2025 года
          </span>
        </div>
      </div>
    </article>
  );
};

export default InfoBlock;
