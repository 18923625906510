import classNames from "classnames";
import Button from "../Button/Button";
import Icon from "./Icon";
import "./PoolLabel.scss";
import Popup from "../Popup/Popup";

const PoolLabel = ({ addClass = "", children }) => {
  const poolLabelClasses = classNames({
    "pool-label": true,
    [`${addClass}`]: addClass,
  });
  return (
    <div className={poolLabelClasses}>
      <Popup>
        Наименование пула. Пул - это определенный список квартир в данном ЖК,
        которые Застройщик выделил для токенизации.
      </Popup>
      {children}
    </div>
  );
};

export default PoolLabel;
