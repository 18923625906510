import { useQueryClient } from "react-query";
import classNames from "classnames";
import "./index.scss";

import heartSvg from "../../../assets/img/icons/heart.svg";
import heartFilledSvg from "../../../assets/img/icons/heart-filled.svg";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { addToFavorites, removeFromFavorites } from "../../../api";
import useUserId from "../../../hooks/useUserId";
import useFavorites from "../../../hooks/fetchers/user/useFavorites";

const LikeButton = ({ buildingId }) => {
  const [liked, setLiked] = useState(false);

  const { data: favorites } = useFavorites();

  useEffect(() => {
    setLiked(
      favorites?.map((favorite) => favorite.id).find((it) => it === buildingId)
    );
  }, [favorites, buildingId]);

  const btnClass = classNames({
    "like-btn": true,
  });

  const userId = useUserId();

  const queryClient = useQueryClient();

  const addToFavoritesMutation = useMutation({
    mutationFn: addToFavorites,
    onSuccess: () => {
      queryClient.invalidateQueries(["pools", "favorites", "releases"]);
      setLiked(true);
    },
  });
  const removeFromFavoritesMutation = useMutation({
    mutationFn: removeFromFavorites,
    onSuccess: () => {
      queryClient.invalidateQueries(["pools", "favorites", "releases"]);
      setLiked(false);
    },
  });

  if (!userId || !buildingId) return null;

  return (
    <button
      type="button"
      className={btnClass}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        liked
          ? removeFromFavoritesMutation.mutate({ userId, buildingId })
          : addToFavoritesMutation.mutate({ userId, buildingId });
      }}
    >
      <img src={liked ? heartFilledSvg : heartSvg} alt="" />
    </button>
  );
};

export default LikeButton;
