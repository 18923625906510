import { useQuery } from "react-query";
import { getFlatsByBuildingId } from "../../../api";
import { formatFlatsInfo } from "../AdminTable/AdminTableFormaters";

import AdminTable from "../AdminTable/AdminTable";
import { Loader } from "../../../components/ui/Loader/Loader";
import Flexbox from "../../../components/ui/Flexbox/Flexbox";
import AddBtn from "../AddButton";
import useModal from "../../../hooks/useModal";
import Modal from "react-modal";
import AddFlatModal from "../AddModal/AddFlatModal";
/**
 * Returns a table of flats in the building
 * @param {{buildingId : string}}
 * @return {import("react").ReactNode}
 */
export function FlatsOfBuilding({ buildingId }) {
  const [modalIsOpen, openModal, closeModal] = useModal();

  const { data, isLoading } = useQuery("flats", async () =>
    getFlatsByBuildingId(buildingId)
  );

  if (isLoading) return <Loader isBlue isLarge />;
  if (!data) return null;

  return (
    <>
      <Flexbox flexDirection="column" fitContent>
        {data.length === 0 ? (
          <p style={{ marginTop: 15 }}>Нет квартир</p>
        ) : (
          <AdminTable data={formatFlatsInfo(data)} />
        )}

        <AddBtn onClick={openModal}>Добавить квартиру</AddBtn>
      </Flexbox>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <AddFlatModal buildingId={buildingId} closeModal={closeModal} />
      </Modal>
    </>
  );
}
