import classNames from "classnames";
import "./index.scss";
import {
  formatId,
  numWord,
  formatDateFrom,
  checkActiveRelease,
  formatTokensForDisplay,
} from "../../../utils";
import ReleaseLink from "../ReleaseLink/ReleaseLink";

const TokenPriceHistoryTable = ({ data }) => {
  const priceClass = classNames({
    table: true,
  });

  if (!data || data.length === 0) return null;

  return (
    <article className={priceClass}>
      <div className="table__col">
        <span className="table__key">Выпуск</span>
        {data
          .map((release) => checkActiveRelease(release))
          .map((activeRelease) => {
            return activeRelease && activeRelease.id ? (
              <span
                className="table__value"
                key={`table-price-history-${activeRelease.id}`}
              >
                <ReleaseLink release={activeRelease}>
                  {formatId(activeRelease.id)}
                </ReleaseLink>
              </span>
            ) : null;
          })}
      </div>

      <div className="table__col">
        <span className="table__key">Количество</span>
        {data.map((release) => {
          const availableTokens = formatTokensForDisplay(
            release.availableTokens
          );
          return (
            <span className="table__value">
              {availableTokens}{" "}
              {numWord(availableTokens, ["токен", "токена", "токенов"])}
            </span>
          );
        })}
      </div>

      <div className="table__col">
        <span className="table__key">Цена за 1 токен</span>
        {data.map((release) => (
          <span className="table__value">
            {release.price.toLocaleString()} ₽
          </span>
        ))}
      </div>

      <div className="table__col">
        <span className="table__key">Пул</span>
        {data.map((release) => (
          <span className="table__value" key={release.pool.id}>
            {release.pool.name || formatId(release.pool.id)}
          </span>
        ))}
      </div>

      <div className="table__col">
        <span className="table__key">Дата</span>
        {data.map((release) => (
          <span className="table__value">
            {formatDateFrom(release.plannedStartDate, true)}
          </span>
        ))}
      </div>
    </article>
  );
};

export default TokenPriceHistoryTable;
