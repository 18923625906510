import { useParams } from "react-router-dom";

import EmitterEditForm from "../forms/EditForm/EmitterEditForm";

const ChangeEmitter = ({ emitter }) => {
  const { id } = useParams();

  return (
    <div>
      <EmitterEditForm id={id} currentEmitter={emitter} isAdmin />
    </div>
  );
};

export default ChangeEmitter;
