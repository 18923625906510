import { Navigate, Outlet } from "react-router-dom";
import useIsLoggedIn from "../hooks/useIsLoggedIn";

const ProtectedRoute = ({ redirectPath = "/" }) => {
  const isLoggedIn = useIsLoggedIn();

  if (!isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
