import DeveloperEditForm from "../forms/EditForm/DeveloperEditForm";
import useUserId from "../../../hooks/useUserId";
import { useParams } from "react-router-dom";
import useDeveloper from "../../../hooks/fetchers/useDeveloper";
import { Loader } from "../../../components/ui/Loader/Loader";
const ChangeDeveloper = () => {
  const userId = useUserId();
  const { id } = useParams();
  const { data: currentDeveloper, isLoading } = useDeveloper();

  return (
    <div>
      {isLoading ? (
        <Loader isLarge isBlue />
      ) : (
        <DeveloperEditForm
          id={id}
          userId={userId}
          currentDeveloper={currentDeveloper}
          isAdmin
        />
      )}
    </div>
  );
};

export default ChangeDeveloper;
