import classNames from "classnames";
import Label from "../Label/Label";
import "./index.scss";
import { formatDateFrom } from "../../../utils";
import { BuildingStatuses } from "../../../constants";

const StatusTable = ({ data, withLabels = false, noGap }) => {
  const priceClass = classNames({
    "table table_cols-3 table_status": true,
    "table_no-gap": noGap,
  });

  if (!data || data.length === 0) return null;

  const statuses = data.toReversed();

  return (
    <article className={priceClass}>
      {withLabels && (
        <div className="table__col">
          <span className="table__key table__key_hidden">Статус</span>
          <span className="table__value">
            <Label size={"xs"}>Куплено</Label>
          </span>
          <span className="table__value">
            <Label size={"xs"}>Куплено</Label>
          </span>
          <span className="table__value">
            <Label size={"xs"} labelType="secondary-gray">
              Избранное
            </Label>
          </span>
        </div>
      )}

      <div className="table__col table__col_no-wrap">
        <span className="table__key">Дата</span>

        {statuses.map((status, i) => (
          <span className={`table__value${i !== 0 ? " text-gray" : ""}`}>
            {formatDateFrom(status.dateTime)}
          </span>
        ))}
      </div>

      <div className="table__col">
        <span className="table__key">Статус</span>
        {statuses.map((status, i) => (
          <span className={`table__value${i !== 0 ? " text-gray" : ""}`}>
            {BuildingStatuses[status.status]}
            {status.status === "PERCENT_BUILDING" &&
              ` - ${status.percentBuilding}%`}
          </span>
        ))}
      </div>
    </article>
  );
};

export default StatusTable;
