import { useQueryClient } from "react-query";
import { demoteAdmin } from "../../../api";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
const useDemoteAdmin = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const demoteAdminMutation = useMutation({
    mutationFn: () => demoteAdmin(id),
    onSuccess: () => {
      queryClient.invalidateQueries("users", id);
    },
  });
  return demoteAdminMutation;
};
export default useDemoteAdmin;
