import React from "react";
import Button from "../ui/Button/Button";
import "./OisAuthPanel.scss";
import useUser from "../../hooks/useUser";
import useIsLoggedIn from "../../hooks/useIsLoggedIn";
import useOisAuth from "../../hooks/fetchers/user/useOisAuth";
import useIsVerified from "../../hooks/useIsVerified";
import useOisHealth from "../../hooks/fetchers/useOisHealth";
const OisAuthPanel = () => {
  const { isLoading: isUserLoading } = useUser();
  const isLoggedIn = useIsLoggedIn();
  const isVerified = useIsVerified();
  const oisAuth = useOisAuth();
  const { data: oisHealth, isLoading: isHealthLoading } = useOisHealth();

  const isLoading = isUserLoading || isHealthLoading;

  if (isLoading || !isLoggedIn || (isVerified && oisHealth)) return null;

  return (
    <div className="ois-panel">
      {oisHealth ? (
        <p>
          {`Для совершения покупок необходимо пройти `}
          <Button
            isClean
            target="_blank"
            disabled={oisAuth.isLoading}
            // isLoading={oisAuth.isLoading}
            onClick={() => oisAuth.mutate()}
          >
            <span className="link link_blue">авторизацию</span>
          </Button>
          {` у партнёра ЦФА ХАБ`}
        </p>
      ) : (
        <p>
          Партнерское API недоступно. Покупка и продажа на платформе недоступны.
          Информация по начисленным токенам может быть устаревшей.
        </p>
      )}
    </div>
  );
};

export default OisAuthPanel;
