import styles from "./InfoPair.module.scss";
import classNames from "classnames";
/**
 * Component that is meant to display info in this way :
 * Key 1             Value1
 * Key 2       BiggerValue2
 * Key 3   EvenBiggerValue3
 */
export function InfoPair({ children, className, ...args }) {
  return (
    <div className={classNames(className, styles.InfoPair)} {...args}>
      {children}
    </div>
  );
}

export function InfoKey({ children, className, ...args }) {
  return (
    <div className={classNames(className, styles.InfoKey)} {...args}>
      {children}
    </div>
  );
}

export function InfoValue({ children, className, ...args }) {
  return (
    <div className={classNames(className, styles.InfoValue)} {...args}>
      {children}
    </div>
  );
}
