import "./index.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import { ArrowIconBlue } from "./ArrowIcon";
import CardMain from "../Card/CardMain";

import "swiper/scss";

const SwiperButtonPrev = ({ children }) => {
  const swiper = useSwiper();
  return (
    <button
      className="slider__nav-btn slider__prev slider__nav_content"
      type="button"
      onClick={() => swiper.slidePrev()}
    >
      {children}
    </button>
  );
};

const SwiperButtonNext = ({ children }) => {
  const swiper = useSwiper();
  return (
    <button
      className="slider__nav-btn slider__next slider__nav_content"
      type="button"
      onClick={() => swiper.slideNext()}
    >
      {children}
    </button>
  );
};

const SliderContent = ({ releases, favoritesIds = [] }) => {
  if (!releases) return null;
  return (
    <div className="slider">
      <Swiper slidesPerView={1}>
        {releases &&
          releases.length > 0 &&
          releases.map((release) => {
            if (!release.building) return null;
            return (
              <SwiperSlide key={`main-slider-${release.id}`}>
                <CardMain
                  release={release}
                  isFavorite={favoritesIds.find(
                    (it) => it === release.building.id
                  )}
                />
              </SwiperSlide>
            );
          })}

        {releases.length > 1 && (
          <>
            <SwiperButtonPrev>
              <ArrowIconBlue />
            </SwiperButtonPrev>
            <SwiperButtonNext>
              <ArrowIconBlue />
            </SwiperButtonNext>
          </>
        )}
      </Swiper>
    </div>
  );
};

export default SliderContent;
