import { QRCodeSVG } from "qrcode.react";
import { useQrCodeOrder } from "../../../hooks/fetchers/orders";
import { Loader } from "../Loader/Loader";

const Qr = ({ orderId }) => {
  const { data: qrcode, isLoading: qrCodeIsLoading } = useQrCodeOrder(orderId);

  if (qrCodeIsLoading) return <Loader isBlue isLarge />;

  if (!qrcode) return null;

  return (
    <div className="qr">
      <QRCodeSVG value={qrcode} />
    </div>
  );
};

export default Qr;
