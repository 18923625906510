import { useOrderById } from "../../../hooks/fetchers/orders";
import usePathId from "../../../hooks/usePathId";
import SearchInput from "../SearchInput/SearchInput";
import OrderInfo from "./OrderInfo";
import OrderPurchase from "./OrderPurchase";

const Order = () => {
  const id = usePathId();

  const { data: order } = useOrderById();

  return (
    <div className="admin-user">
      <SearchInput disabled />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 540px",
          justifyContent: "space-between",
          gap: "10%",
          marginTop: "30px",
        }}
      >
        <OrderPurchase order={order} />
        <OrderInfo order={order} />
      </div>
    </div>
  );
};

export default Order;
