import DropzoneImage from "../../../components/ui/Dropzone/DropzoneImage";
import {Fragment} from "react";
import {Loader} from "../../../components/ui/Loader/Loader";

const BuildingGallery = (props) => {

    const {
        building,
        editMode,
        onSubmit,
        addImageMutation
    } = props;

    return (
        <div className="edit-form">
            {building.imagesIds && building.imagesIds.length > 0 ? (
                    <>
                        <p className="h8 form__title">Изображения ЖК</p>
                        <div>
                            <span className="input-wrapper__label body-smaller">Отображение на баннере</span>
                            <div className="form__row form__row_grid-2">
                                {building.imagesIds.map((item, i) => {
                                    const imagesIds = `imagesIds[${i}]`;
                                    return (
                                        <Fragment key={i}>
                                            <DropzoneImage
                                                accept={{
                                                    "image/*": [".jpeg", ".jpg", ".png"],
                                                }}
                                                name={imagesIds}
                                                editmode={editMode ? "true" : "false"}
                                                onSubmit={onSubmit}
                                                buildingimagelink={building?.imagesLinks[i]}
                                                buildingimageid={item}
                                                buildingid={building.id}
                                                disabled={!editMode}
                                            />
                                        </Fragment>
                                    )
                                })}
                                {addImageMutation.isLoading &&
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: '165px'
                                    }}>
                                        <Loader isBlue isLarge/>
                                    </div>}
                                <DropzoneImage
                                    accept={{
                                        "image/*": [".jpeg", ".jpg", ".png"],
                                    }}
                                    name={'addImage'}
                                    editmode={editMode ? "true" : "false"}
                                    onSubmit={onSubmit}
                                    buildingimageid={null}
                                    disabled={!editMode}
                                />
                            </div>
                        </div>
                    </>
                )
                :
                (
                    <div>
                        <p className="h8 form__title">Изображения ЖК</p>
                        <span className="input-wrapper__label body-smaller">Вы можете добавить фотографии через редактирование формы ЖК</span>
                        <div className="form__row form__row_grid-2">
                            {addImageMutation.isLoading &&
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: '165px'
                                }}>
                                    <Loader isBlue isLarge/>
                                </div>}
                            <DropzoneImage
                                accept={{
                                    "image/*": [".jpeg", ".jpg", ".png"],
                                }}
                                name={'addImage'}
                                editmode={editMode ? "true" : "false"}
                                onSubmit={onSubmit}
                                buildingimageid={null}
                                disabled={!editMode}
                            />
                        </div>
                    </div>
                )}
        </div>
    )
}
export default BuildingGallery;