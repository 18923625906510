import classNames from "classnames";
import Container from "../../Container/Container";
import { PinSvg } from "./Icons";
import "./index.scss";
import Select from "../../../ui/Select/Select";
import { useState } from "react";
import Nav from "./Nav";
import useMobile from "../../../../hooks/useMobile";
import Burger from "./Burger";
import MobileMenu from "../MobileMenu/MobileMenu";

const TopBar = () => {
  const topbarClass = classNames({
    topbar: true,
  });

  const defaultCity = "Санкт-Петербург";
  const cities = [
    "Санкт-Петербург",
    // "Москва",
    // "Петрозаводск",
    // "Нижний Новгород",
  ];
  const [selectedCity, setSelectedCity] = useState(defaultCity);

  const [mobileMenuShown, setMobileMenuShown] = useState(false);

  const isMobile = useMobile();

  return (
    <section className={topbarClass}>
      <Container>
        {isMobile && (
          <MobileMenu
            mobileMenuShown={mobileMenuShown}
            setMobileMenuShown={setMobileMenuShown}
          />
        )}
        <div className="topbar__block">
          {!isMobile && <Nav />}

          {isMobile && (
            <Burger
              setMobileMenuShown={setMobileMenuShown}
            />
          )}

          <div className="topbar__select-city">
            <PinSvg />
            <Select
              addClass="topbar__select"
              options={cities}
              defaultOption={defaultCity}
              onChange={(choice) => {
                setSelectedCity(choice.value);
              }}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TopBar;
