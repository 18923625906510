import useReleases from "../../../../hooks/fetchers/releases/useReleases";
import { Loader } from "../../../ui/Loader/Loader";
import CardHorizontalFavorites from "./CardHorizontalFavorites";
import "./index.scss";

const Favorites = () => {
  const { data: favoriteReleases, isLoading } = useReleases({
    onlyFavorite: true,
    available: true,
  });

  if (!favoriteReleases) return null;

  if (isLoading) return <Loader isLarge />;

  return (
    <section className="my-tokens">
      {favoriteReleases.map((release) => (
        <CardHorizontalFavorites
          release={release}
          key={`farovite-release-${release.id}`}
        />
      ))}
    </section>
  );
};

export default Favorites;
