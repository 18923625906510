import React from "react";
import Popup from "../Popup/Popup";
import { Link } from "react-router-dom";
const ComissionBlock = () => {
  return (
    <div className="sign__comission">
      <Popup>
        Цифровой метр взимает комиссию в размере 0,025% от суммы покупки
        токенов.
      </Popup>
      <span style={{ marginLeft: 3 }}>Комиссия: 60 ₽.</span>

      <Link href="#" target="_blank" className="link link_blue">
        Подробные условия
      </Link>
    </div>
  );
};

export default ComissionBlock;
