import CloseButton from "../../../components/ui/CloseButton/CloseButton";
import { useState } from "react";
import AddReleaseForm from "../forms/AddForm/AddReleaseForm";
import ChooseStep from "../forms/AddForm/ChooseStep";
const AddReleaseModal = ({ closeModal }) => {
  const [chosenDeveloper, setChosenDeveloper] = useState("");
  const [step, setStep] = useState(1);

  return (
    <div className="modal">
      <CloseButton addClass="modal__close-btn" onClick={closeModal} />
      <span className="modal__title">Добавление выпуска</span>

      {step === 1 && (
        <ChooseStep
          noTitle
          type="buildings"
          nextStep={() => setStep(2)}
          chosen={chosenDeveloper}
          setChosen={setChosenDeveloper}
        />
      )}
      {step === 2 && (
        <AddReleaseForm
          chosenDeveloper={chosenDeveloper}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default AddReleaseModal;
