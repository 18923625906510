import CloseButton from "../../../components/ui/CloseButton/CloseButton";
import { useState } from "react";

import {
  AddBuildingFormStep1,
  AddBuildingFormStep2,
  AddBuildingFormStep3,
} from "../forms/AddForm/AddBuildingForm";

const AddBuildingModal = ({ closeModal }) => {
  const [chosenDeveloper, setChosenDeveloper] = useState("");
  const [buildingInfo, setBuildingInfo] = useState(null);

  const [step, setStep] = useState(1);

  return (
    <div className="modal">
      {/* // {<div className={`modal${step === 3 ? " modal_lg" : ""}`}>} */}
      <CloseButton addClass="modal__close-btn" onClick={closeModal} />
      {step === 1 && (
        <AddBuildingFormStep1
          nextStep={() => setStep(2)}
          {...{ chosenDeveloper, setChosenDeveloper }}
        />
      )}

      {step === 2 && (
        <AddBuildingFormStep2
          setBuildingInfo={setBuildingInfo}
          nextStep={() => setStep(3)}
        />
      )}

      {step === 3 && (
        <AddBuildingFormStep3
          closeModal={closeModal}
          buildingInfo={buildingInfo}
          developerId={chosenDeveloper}
        />
      )}
    </div>
  );
};

export default AddBuildingModal;
