import classNames from "classnames";
import Button from "../../../ui/Button/Button";
import Label from "../../../ui/Label/Label";
import { useState } from "react";

import { formatId, numWord } from "../../../../utils";
import { RepaymentMethods } from "../../../../constants";
import "moment/locale/ru";
import PoolLabel from "../../../ui/PoolLabel/PoolLabel";
import StatusLabel from "../../../ui/Card/StatusLabel";
import ProgressLabel from "../../../ui/Label/ProgressLabel";
import { orderStatusLabelMap } from "../../../../admin/components/AdminTable/AdminTableFormaters";

const CardHorizontalOrderInProgress = ({ tokenData }) => {
  const [collapseOpened, setCollapseOpened] = useState(false);

  const cardClass = classNames({
    "card card_h card_token": true,
    "card_collapse-opened": collapseOpened,
  });

  if (!tokenData) return null;

  const { orders, pool, building, developer } = tokenData;
  // const availableTokens = formatTokensForDisplay(tokenData.amount);
  const status = orders && orders.length > 0 && orders[0]?.status;
  return (
    <article className={cardClass}>
      <div className="card__labels-list">
        <StatusLabel data={tokenData} />
        <Label labelType="secondary">1 токен = 1 м²</Label>
        {pool.repaymentMethod && (
          <Label labelType="secondary">
            {RepaymentMethods[pool.repaymentMethod]}
          </Label>
        )}
      </div>

      <PoolLabel addClass="card__pool-label">
        {pool.name || formatId(pool.id)}
      </PoolLabel>

      <img className="card__img" src={building?.imagesLinks[0]} alt="" />

      <div className="card__body">
        {status && (
          <div style={{ marginBottom: 15 }}>
            <ProgressLabel status={status}>
              {orderStatusLabelMap[status]}
            </ProgressLabel>
          </div>
        )}

        <div className="sell-info">
          <div className="sell-info__col">
            <span className="sell-info__title">Цифровой токен</span>
            <span className="sell-info__key">{building.name}</span>
            <p className="sell-info__value">{developer.name}</p>

            <span className="sell-info__key">{building.city}</span>
            <p className="sell-info__value">{building.district}</p>

            <span className="sell-info__key">
              1 токен ≈ {orders[0].priceForOne} ₽
            </span>

            <p className="sell-info__value">Рыночная цена на данный момент</p>
          </div>

          <div className="sell-info__col">
            <span className="sell-info__title">Количество</span>
            <span className="sell-info__key">
              {tokenData.amount / 100}{" "}
              {numWord(tokenData.amount / 100, ["токен", "токена", "токенов"])}
            </span>
            <p className="sell-info__value">Приобретаемые токены</p>
          </div>

          {pool.repaymentMethod === "GUARANTEED_INCOME" && (
            <div className="sell-info__col">
              <span className="sell-info__title">Гарантированный доход</span>
              <span className="sell-info__key">{pool.exitPrice} ₽</span>
              <p className="sell-info__value">Общая сумма</p>
            </div>
          )}

          {pool.repaymentMethod === "SALES_INCOME" && (
            <div className="sell-info__col">
              <span className="sell-info__title">Доход от продаж</span>
              <span className="sell-info__key">
                {pool.exitPrice} ₽ + % от продажи
              </span>
              <p className="sell-info__value">Гарантированный доход</p>
            </div>
          )}
        </div>

        {/* <div className="card__operations">
          <CollapseToggler
            addClass={"card__collapse-btn"}
            collapseOpened={collapseOpened}
            setCollapseOpened={setCollapseOpened}
          >
            Операции
          </CollapseToggler>
           */}
        {status &&
          ![
            "WAITING_FOR_CONFIRMATION",
            "WAITING_ISSUE",
            "PAYMENT_CONFIRMATION_SENT",
            "PAYMENT_CONFIRMED",
          ].includes(status) && (
            <div className="card__btns">
              <Button
                size={"xs"}
                addClass="card__buy-btn"
                target={"_blank"}
                isLink={`/${tokenData.pool.id}?orderId=${
                  tokenData.orders[0].id
                }&amount=${tokenData.amount / 100}&status=${status}`}
              >
                Продолжить покупку
              </Button>
            </div>
          )}
      </div>

      {/* <Collapse isOpened={collapseOpened} addClass="card__collapse">
          <div className="sell-info sell-info_single">
            <div className="sell-info__col">
              {releases.map((release) => {
                if (release.operations.length === 0) return null;

                return release.operations.map((op) => (
                  <Fragment key={op.initOrder.internalId}>
                    <span className="sell-info__key">
                      Вы купили {formatTokensForDisplay(op.amount)}{" "}
                      {numWord(op.amount, ["токен", "токена", "токенов"])}{" "}
                      {building.name} выпуск{" "}
                      {
                        <ReleaseLink release={release} noIcon>
                          {formatId(release.id)}
                        </ReleaseLink>
                      }{" "}
                      за {op.price} ₽
                    </span>
                    <p className="sell-info__value">
                      {formatDateFrom(op.date)}
                    </p>
                  </Fragment>
                ));
              })}
            </div>
          </div>
        </Collapse> 
      </div>*/}
    </article>
  );
};

export default CardHorizontalOrderInProgress;
