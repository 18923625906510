export const SignIcon = ({ isChecked = false }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM1.4 7C1.4 3.90721 3.90721 1.4 7 1.4C10.0928 1.4 12.6 3.90721 12.6 7C12.6 10.0928 10.0928 12.6 7 12.6C3.90721 12.6 1.4 10.0928 1.4 7ZM10.316 5.37301C10.5772 5.08802 10.558 4.64523 10.273 4.38399C9.98802 4.12276 9.54523 4.14201 9.28399 4.42699L5.9751 8.03669L4.73775 6.55187C4.49026 6.25488 4.04886 6.21475 3.75187 6.46225C3.45488 6.70974 3.41475 7.15114 3.66225 7.44813L5.41224 9.54813C5.54154 9.70329 5.73156 9.79504 5.93348 9.7998C6.13539 9.80457 6.32953 9.72189 6.46601 9.57301L10.316 5.37301Z"
        fill={isChecked ? "#00D1B4" : "#909090"}
      />
    </svg>
  );
};
