import Button from "../Button/Button";
import CoinsIcon from "../../../assets/img/icons/coins.svg";
import useLoginModal from "../../../hooks/useLoginModal";

const NeedToEnterScreen = ({ closeModal }) => {
  const { openModal, setIsLoginAction } = useLoginModal();
  return (
    <div className="success-screen success-screen_inner-padding">
      <div className="success-screen__content">
        <div>
          <img src={CoinsIcon} alt="" />
        </div>

        <span className="h7 text-blue">Вы не авторизованы</span>
        <p className="text-gray" style={{ marginTop: 8 }}>
          Для продолжения оформления заявок необходимо выполнить вход или
          зарегистрироваться
        </p>
      </div>

      <div className="sign__btns">
        <div className="sign__btns-row">
          <Button
            buttonType="secondary"
            size={"m"}
            onClick={() => {
              setIsLoginAction(false);
              openModal();
            }}
            isFull
          >
            Регистрация
          </Button>
          <Button
            buttonType="primary"
            size={"m"}
            onClick={() => {
              setIsLoginAction(true);
              openModal();
            }}
            isFull
          >
            Вход
          </Button>
        </div>

        <Button buttonType="secondary" size="m" isFull onClick={closeModal}>
          Отмена
        </Button>
      </div>
    </div>
  );
};

export default NeedToEnterScreen;
