import { useParams } from "react-router-dom";
import { Loader } from "../../../components/ui/Loader/Loader";
import useRelease from "../../../hooks/fetchers/releases/useRelease";
import ReleaseEditForm from "../forms/EditForm/ReleaseEditForm";
const ChangeRelease = () => {
  const { id } = useParams();
  const { data: currentRelease, isLoading } = useRelease();

  return (
    <div>
      {isLoading ? (
        <Loader isLarge isBlue />
      ) : (
        <ReleaseEditForm id={id} currentRelease={currentRelease} isAdmin />
      )}
    </div>
  );
};

export default ChangeRelease;
