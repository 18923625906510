import {
  CheckIcon,
  CheckpointRedIcon,
  CheckpointBlueIcon,
  CheckpointGrayIcon,
} from "./Icons";

import "./History.scss";
import classNames from "classnames";

const mocData = [
  {
    status: "checked",
    title: "Заявка создана",
    descr: "11 октября 2023, 11:00:01",
  },
  {
    status: "checked",
    title: "Ожидание оплаты",
    descr: "12 октября 2023, 11:00:01",
  },
  {
    status: "checked",
    title: "Проверка оплаты",
    descr: "12 октября 2023, 11:00:01",
  },
  {
    status: "checked",
    title: "Оплата подтверждена эмитентом",
    descr: "12 октября 2023, 11:00:01",
  },
  {
    status: "canceled",
    title: "Отмена",
    descr: "12 октября 2023, 11:00:01",
  },
  {
    status: "checkpoint",
    title: "Вы находитесь здесь",
    descr: "12 октября 2023, 11:00:01",
  },
  {
    status: "nextpoint",
    title: "Будущее",
    descr: "13 октября 2023, 11:00:01",
  },
];

export const HistoryItem = ({
  title,
  descr,
  status,
  isBeforeNextpoint = false,
  renderElement,
}) => {
  const itemClasses = classNames({
    "history-item": true,
    "history-item_nextpoint": isBeforeNextpoint || status === "nextpoint",
  });
  return (
    <div className={itemClasses}>
      <div className="history-item__icon">
        {status === "checked" && <CheckIcon />}
        {status === "canceled" && <CheckpointRedIcon />}
        {status === "checkpoint" && <CheckpointBlueIcon />}
        {status === "nextpoint" && <CheckpointGrayIcon />}
      </div>
      <div className="history-item__content">
        <p className="history-item__title">{title}</p>
        {descr && <p className="history-item__descr">{descr}</p>}
        {renderElement && renderElement()}
      </div>
    </div>
  );
};

const History = ({ data = mocData }) => {
  return (
    <div className="history">
      {data.map((it, i, arr) => (
        <HistoryItem
          key={it.title}
          {...it}
          isBeforeNextpoint={arr[i + 1]?.status === "nextpoint"}
        />
      ))}
    </div>
  );
};

export default History;
