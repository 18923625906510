import React from "react";
import Input from "../../../components/ui/Input/Input";
import "./SearchInput.scss";
import { config } from "../../../config";
const SearchInput = ({ ...args }) => {
  if (!config.dev_mode) return null;
  return (
    <Input placeholder="Поиск" addClass="search-input" size="lg" {...args} />
  );
};

export default SearchInput;
