import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Controller, useFormContext } from "react-hook-form";
const animatedComponents = makeAnimated();

const SelectSearch = ({
  name,
  selected,
  setSelected,
  options = [],
  isMulti = false,
  withLabel = "",
  disabled = false,
  defaultValue = "",
  ...args
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <label className="input-wrapper">
          {withLabel && (
            <span className="input-wrapper__label body-smaller">
              {withLabel}
            </span>
          )}

          <Select
            {...field}
            isDisabled={disabled}
            closeMenuOnSelect={!isMulti}
            components={animatedComponents}
            options={options}
            isMulti={isMulti}
            styles={{
              control: (base) => ({
                ...base,
                backgroundColor: "white",
                borderColor: "#EBF4FF",
                height: 56,
                fontSize: 20,
                paddingLeft: 20,
                color: "#1D1D1D",
              }),
              option: (base, { isSelected }) => ({
                ...base,
                backgroundColor: isSelected ? "#3399CC" : "white",
                color: "#1D1D1D",
                ":hover": {
                  backgroundColor: "#3399CC",
                  color: "white",
                  cursor: "pointer",
                },
                ":focus": {
                  backgroundColor: "#3399CC",
                },
              }),
              multiValue: (base) => ({
                ...base,

                backgroundColor: "#52A8D5", //#
                color: "white",
              }),
              multiValueLabel: (base) => ({
                ...base,
                color: "white",
              }),
              multiValueRemove: (base) => ({
                ...base,

                ":hover": {
                  backgroundColor: "#3399CC",
                },
              }),
            }}
            {...args}
          />
        </label>
      )}
    />
  );
};

export default SelectSearch;
