import "./Loader.scss";
import classNames from "classnames";

export const Loader = ({ isLarge = false, isBlue = false }) => {
  const loaderClasses = classNames({
    loader: true,
    loader_lg: isLarge,
    loader_blue: isBlue,
  });
  return (
    <div className={loaderClasses}>
      <span className="loader__bar"></span>
      <span className="loader__bar"></span>
      <span className="loader__bar"></span>
    </div>
  );
};
