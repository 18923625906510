import SectionTitle from "../../components/ui/SectionTitle/SectionTitle";
import {Link, parsePath} from "react-router-dom";
import { formatId } from "../../utils";
const ConnectedPools = ({ pools }) => {
  if (!pools || pools.length === 0 || (pools[0] && !pools[0].building))
    return null;

  return (
    <section className="history">
      <SectionTitle>Пулы {pools[0]?.building?.name}</SectionTitle>
      <article className="table">
        <div className="table__col">
          {pools?.map((pool) => (
            <span className="table__value" key={`connected-pool-${pool.id}`}>
              <Link
                className="link link_blue"
                to={`/${pool.id}`}
                target="_blank"
              >
                {pool.name || formatId(pool.id)}
              </Link>
            </span>
          ))}
        </div>
      </article>
    </section>
  );
};

export default ConnectedPools;
