import classNames from "classnames";
import "./index.scss";
import { Controller, useFormContext } from "react-hook-form";

/** This component is made exclusively for AddBuildingForm
 * But if needed can be reworked to be used in other places
 */
export function InputWithSlider({
  size = "default",
  addClass,
  labelSize = "default",
  defaultValue,

  disabled = false,
  required = false,
  name,
  step = 1,
  withLabel = "",
  ...arg
}) {
  const formContext = useFormContext();

  const inputClass = classNames({
    input: true,
    [`input_${size}`]: true,
    [`${addClass}`]: addClass,
    [`input_disabled`]: disabled,
  });

  const labelClass = classNames({
    "input-wrapper__label": true,
    "body-smaller": labelSize === "small",
  });

  return (
    <label className="input-wrapper">
      {withLabel && <span className={labelClass}>{withLabel}</span>}

      <Controller
        control={formContext.control}
        name={name}
        render={({ field: { value = defaultValue, onChange, ...field } }) => (
          <>
            <input
              className={inputClass}
              disabled={disabled}
              value={`${value}%`}
              onChange={(evt) => onChange(evt.target.value.replace("%", ""))}
              {...arg}
              {...field}
            />
            <div className="input-slider">
              <div className="input-slider__bar">
                <input
                  className="input-slider__range"
                  type="range"
                  value={value}
                  onChange={(evt) => onChange(evt.target.value)}
                  max={100}
                  min={0}
                  step={step}
                  disabled={disabled}
                />
              </div>
            </div>
          </>
        )}
      />
    </label>
  );
}
