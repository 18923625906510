import CoinsIcon from "../../../assets/img/icons/coins.svg";

const SuccessScreen = () => {
  return (
    <div className="success-screen">
      <img src={CoinsIcon} alt="coins" style={{ width: "100%" }} />
      <div>
        <p
          style={{
            color: "#3399CC",
            fontWeight: "500",
            fontSize: "18px",
            marginBottom: "8px",
          }}
        >
          Отлично!
        </p>
        <p style={{ color: "#909090", fontSize: "16px" }}>
          Ожидайте выпуск ЦФА.
        </p>
      </div>
    </div>
  );
};
export default SuccessScreen;
