import Button from "../../../../components/ui/Button/Button";
import { getFilesFormData } from "../../../../utils";
import { useState } from "react";
import Select from "../../../../components/ui/Select/Select";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addFlat, getAllBuildings, addFiles } from "../../../../api";
import { Loader } from "../../../../components/ui/Loader/Loader";
import { FlatDescription } from "../../../../components/forms/PolymorhicForm/FlatDescription";
/**
 * @param {{close : () => unknown}}
 * @returns {import("react").ReactNode}
 */
export default function AddFlatForm({ buildingId = null, closeModal }) {
  const { data: buildings, isSuccess } = useQuery("buildings", getAllBuildings);

  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const formHook = useForm();

  if (!isSuccess) return <Loader isBlue isLarge />;

  if (selectedBuilding || buildingId)
    return (
      <FlatDescriptionForm
        closeModal={closeModal}
        buildingId={selectedBuilding || buildingId}
      />
    );

  return (
    <FormProvider {...formHook}>
      <form
        className="form feedback"
        onSubmit={formHook.handleSubmit((data) =>
          setSelectedBuilding(data.building?.value)
        )}
      >
        <p className="form__title base-24">Выберите ЖК</p>
        <div className="form__row">
          <div className="form__item">
            <Controller
              name="building"
              control={formHook.control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  withLabel="Выберите ЖК для добавления квартиры"
                  options={buildings.map((building) => ({
                    label: building.name,
                    value: building.id,
                  }))}
                  whiteType
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <Button style={{ marginTop: 20 }} isFull type="submit">
          Далее
        </Button>
      </form>
    </FormProvider>
  );
}

/**
 *
 * @param {{buildingId : string, close : () => unknown}}
 * @returns {import("react").ReactNode}
 */
function FlatDescriptionForm({ buildingId, closeModal }) {
  const queryClient = useQueryClient();
  const addFlatMutation = useMutation(addFlat, {
    onSuccess: () => {
      queryClient.invalidateQueries("flats");
      closeModal();
    },
  });
  const addFilesMutation = useMutation({ mutationFn: addFiles });

  const formHook = useForm();

  const addFlatAction = (data, fileResp) => {
    addFlatMutation.mutate(
      {
        description: "Без описания",
        buildingId,
        imagesIds: fileResp && fileResp.length > 0 ? fileResp.flat() : [],
        ...data,

        tokenizationStatus: data.tokenizationStatus ?? "NOT_TOKENIZED",
        bookingStatus: data.bookingStatus ?? "NOT_BOOKED",
      },
      {
        onSuccess: closeModal,
      }
    );
  };

  return (
    <FormProvider {...formHook}>
      <form
        className="form feedback"
        onSubmit={formHook.handleSubmit((data) => {
            const promisesFilesGroup = data.photos.map(async (file) => {
                return await addFilesMutation.mutateAsync(getFilesFormData([file]))
            });
            Promise.all(promisesFilesGroup).then((fileResp) => {
                addFlatAction(data, fileResp);
            }).catch(error => {
                console.log(error)
            })
        })}
      >
        <p className="form__title base-24">Описание квартиры</p>
        <FlatDescription control={formHook.control} />

        <Button
          disabled={addFilesMutation.isLoading || addFlatMutation.isLoading}
          isLoading={addFilesMutation.isLoading || addFlatMutation.isLoading}
          style={{ marginTop: 20 }}
          isFull
          type="submit"
        >
          Добавить квартиру
        </Button>
      </form>
    </FormProvider>
  );
}
