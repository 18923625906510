import CloseButton from "../../../components/ui/CloseButton/CloseButton";

import AddDeveloperForm from "../forms/AddForm/AddDeveloperForm";
const AddDeveloperModal = ({ closeModal }) => {
  return (
    <div className="modal">
      <CloseButton addClass="modal__close-btn" onClick={closeModal} />
      <span className="modal__title">Добавить застройщика</span>
      <AddDeveloperForm closeModal={closeModal} />
    </div>
  );
};

export default AddDeveloperModal;
