import React, {useCallback, useEffect, useState} from "react";
import { useDropzone } from "react-dropzone";
import {useFormContext} from "react-hook-form";

import "./Dropzone.scss";
import classNames from "classnames";
import CloseButton from "../CloseButton/CloseButton";

const UploadIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6585 10.2526C15.0741 9.88889 15.1163 9.25713 14.7526 8.84149C14.3889 8.42586 13.7571 8.38374 13.3415 8.74742L11 10.7962V1C11 0.447715 10.5523 0 10 0C9.44771 0 9 0.447715 9 1V10.7962L6.6585 8.74742C6.24287 8.38374 5.61111 8.42586 5.24742 8.84149C4.88374 9.25713 4.92586 9.88889 5.3415 10.2526L9.34149 13.7526C9.71852 14.0825 10.2815 14.0825 10.6585 13.7526L14.6585 10.2526ZM2 13C2 12.4477 1.55228 12 1 12C0.447715 12 0 12.4477 0 13V16C0 17.1046 0.895431 18 2 18H18C19.1046 18 20 17.1046 20 16V13C20 12.4477 19.5523 12 19 12C18.4477 12 18 12.4477 18 13V16H2V13Z"
      fill="#AED6EE"
    />
  </svg>
);

const Dropzone = (props) => {
  const { name, label = name, disabled = false } = props;
  const [filesArray, setFilesArray] = useState([]);
  const { register, unregister, setValue, watch } = useFormContext();
  const files = watch(name);

  const onDrop = (droppedFiles) => {
    setFilesArray([...filesArray, droppedFiles].flat())
    }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.accept,
    disabled: disabled,
  });

  useEffect(() => {
    let isMounted = true;
    if(filesArray.length > 0) {
      isMounted && setValue(name, filesArray, { shouldValidate: true });
    } else {
      isMounted && setValue(name, [], { shouldValidate: true });
    }
    return () => {
      isMounted = false;
    }
  }, [filesArray]);

  function onButtonClickDeleteImage(indexFile) {
    setFilesArray(filesArray.filter((el, index) => index !== indexFile))
  }

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  const uploadClasses = classNames({
    "upload-field": true,
    "upload-field_hovered": isDragActive,
  });

  return (
    <>
      <label className="input-wrapper" htmlFor={name}>
        {label && (
          <span className="input-wrapper__label body-smaller">{label}</span>
        )}

        <div
          {...getRootProps()}
          type="file"
          role="button"
          aria-label="File Upload"
          id={name}
          className={uploadClasses}
          disabled={disabled}
        >
          <UploadIcon />
          <input disabled={disabled} {...props} {...getInputProps()} />
        </div>
      </label>

      {!!filesArray?.length && (
        <div className="upload-field__preview">
          {filesArray.map((file, i) => {
            return (
              <div key={i} className="upload-field__preview-box">
                <CloseButton
                  isclean
                  addClass="upload-field__remove-btn"
                  onClick={() => onButtonClickDeleteImage(i)}
                />

                <img
                  className="upload-field__img-preview"
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Dropzone;
