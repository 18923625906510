export const BurgerSvg = () => (
  <svg
    width="11"
    height="8"
    viewBox="0 0 11 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.666667C0 0.298477 0.307804 0 0.6875 0H10.3125C10.6922 0 11 0.298477 11 0.666667C11 1.03486 10.6922 1.33333 10.3125 1.33333H0.6875C0.307804 1.33333 0 1.03486 0 0.666667ZM0 4C0 3.63181 0.307804 3.33333 0.6875 3.33333H10.3125C10.6922 3.33333 11 3.63181 11 4C11 4.36819 10.6922 4.66667 10.3125 4.66667H0.6875C0.307804 4.66667 0 4.36819 0 4ZM0.6875 6.66667C0.307804 6.66667 0 6.96514 0 7.33333C0 7.70152 0.307804 8 0.6875 8H10.3125C10.6922 8 11 7.70152 11 7.33333C11 6.96514 10.6922 6.66667 10.3125 6.66667H0.6875Z"
      fill="currentColor"
    />
  </svg>
);

export const PinSvg = () => (
  <svg
    className="topbar__select-icon"
    width="15"
    height="21"
    viewBox="0 0 15 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5487 10.1638L7.04549 16.7204L2.50811 10.1857C1.76428 8.70878 1.68438 6.94781 2.29099 5.40036C4.04849 0.916948 9.95134 0.901451 11.7347 5.38593C12.3475 6.92699 12.2791 8.68519 11.5487 10.1638ZM13.1983 11.11L8.11425 18.8424L8.11353 18.8439L8.06422 18.9437L7.58734 19.657C7.35035 20.1367 6.71297 20.1393 6.47233 19.6615L6.04378 18.9519L5.9937 18.8525L5.99297 18.851L0.865787 11.1452C-0.149012 9.13029 -0.25802 6.72782 0.569567 4.61664C2.96992 -1.50669 11.0219 -1.5177 13.4522 4.59379C14.2878 6.69506 14.1949 9.09258 13.1983 11.11ZM7.97776 7.38284C7.97912 7.93872 7.56193 8.39038 7.04594 8.39164C6.52994 8.39291 6.11054 7.9433 6.10918 7.38742C6.10782 6.83154 6.52501 6.37988 7.04101 6.37862C7.557 6.37735 7.9764 6.82696 7.97776 7.38284ZM9.84634 7.37826C9.85042 9.04591 8.59885 10.4009 7.05087 10.4047C5.50289 10.4085 4.24469 9.05964 4.2406 7.392C4.23652 5.72435 5.48809 4.36938 7.03608 4.36559C8.58406 4.3618 9.84225 5.71062 9.84634 7.37826Z"
      fill="currentColor"
    />
  </svg>
);

export default PinSvg;
