import classNames from "classnames";
import "./Dropdown.scss";

const Dropdown2 = ({ children, isShown = false }) => {
  const dropdownClasses = classNames({
    dropdown2: true,
    dropdown2_shown: isShown,
  });
  return isShown ? <div className={dropdownClasses}>{children}</div> : null;
};

export default Dropdown2;
