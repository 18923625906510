import Button from "../ui/Button/Button";
import QrCode from "../ui/QrCode/QrCode";
import CloseButton from "../ui/CloseButton/CloseButton";
import "./index.scss";
const ExchangeModalQr = ({ closeModal }) => {
  return (
    <div className="modal">
      <CloseButton addClass="modal__close-btn" onClick={closeModal} />
      <span className="modal__title">ЖК «Аист»</span>

      <div className="sell-info">
        <div className="sell-info__col">
          <span className="sell-info__title">Продам</span>
          <span className="sell-info__key">ЖК «Аист»</span>
          <p className="sell-info__value">
            СК «Красная стрела»
          </p>

          <span className="sell-info__key">Санкт-Петербург</span>
          <p className="sell-info__value">
            Красносельский район
          </p>

          <span className="sell-info__key">
            1 токен ≈ 185 273 ₽
          </span>
          <p className="sell-info__value">
            Рыночная цена на данный момент
          </p>
        </div>

        <div className="sell-info__col">
          <span className="sell-info__title">Количество</span>
          <span className="sell-info__key">2 токена</span>
          <p className="sell-info__value">Цифрового метра</p>
        </div>

        <div className="sell-info__col">
          <span className="sell-info__title">Стоимость</span>
          <span className="sell-info__key">300 000 ₽</span>
          <p className="sell-info__value">От пользователя</p>

          <span className="sell-info__key">150 000 ₽</span>
          <p className="sell-info__value">
            Стоимость за 1 м²
          </p>
        </div>
      </div>

      <div className="modal__info text-block">
        <p>QR-код для оплаты:</p>
      </div>

      <div className="modal__qr">
        <QrCode />
      </div>

      <Button addClass="modal__btn" isFull>
        Оплатить банковской картой
      </Button>
    </div>
  );
};

export default ExchangeModalQr;
