import classNames from "classnames";

const TableNewTokens = ({ noGap }) => {
  const priceClass = classNames({
    table: true,
    "table_no-gap": noGap,
  });

  return (
    <article className={priceClass}>
      <div className="table__col">
        <span className="table__key">ЖК</span>
        <span className="table__value">ЖК «Аист»</span>
        <span className="table__value">ЖК «Аист»</span>
        <span className="table__value">2ЖК «Аист»</span>
      </div>
      <div className="table__col">
        <span className="table__key">Дата</span>
        <span className="table__value">24 июня 2023 года</span>
        <span className="table__value">12 мая 2023 года</span>
        <span className="table__value">24 марта 2023 года</span>
      </div>

      <div className="table__col">
        <span className="table__key">Количество м²</span>
        <span className="table__value">123,32 м²</span>
        <span className="table__value">500,23 м²</span>
        <span className="table__value">500,23 м²</span>
      </div>

      <div className="table__col">
        <span className="table__key">Цена за 1 токен</span>
        <span className="table__value">185 273 ₽</span>
        <span className="table__value">182 223 ₽</span>
        <span className="table__value">178 234 ₽</span>
      </div>
    </article>
  );
};

export default TableNewTokens;
