import { useParams } from "react-router-dom";
import { Loader } from "../../../components/ui/Loader/Loader";
import { usePool } from "../../../hooks/fetchers/pools";
import PoolEditForm from "../forms/EditForm/PoolEditForm";
const ChangePool = () => {
  const { id } = useParams();
  const { data: currentPool, isLoading } = usePool();

  return (
    <div>
      {isLoading ? (
        <Loader isLarge isBlue />
      ) : (
        <PoolEditForm id={id} currentPool={currentPool} isAdmin />
      )}
    </div>
  );
};

export default ChangePool;
