import { useQuery } from "react-query";
import { getReleasesByPoolId } from "../../../api";
import { formatReleasesInfo } from "../AdminTable/AdminTableFormaters";

import AdminTable from "../AdminTable/AdminTable";
import { Loader } from "../../../components/ui/Loader/Loader";

const ReleasesOfPool = ({ poolId }) => {
  const { data: releases, isLoading } = useQuery("releases", async () =>
    getReleasesByPoolId(poolId)
  );

  if (isLoading) return <Loader />;
  if (!releases) return null;

  return <AdminTable data={formatReleasesInfo(releases)} />;
};

export default ReleasesOfPool;
