import { useEffect, useState } from "react";
import Button from "../../../../components/ui/Button/Button";
import Input from "../../../../components/ui/Input/Input";
import "./EditForm.scss";
import Flexbox from "../../../../components/ui/Flexbox/Flexbox";
import BanBtn from "../../BanBtn";
import { useForm, FormProvider } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { editDeveloper } from "../../../../api";
import { UploadField } from "../../../../components/ui/UploadField/UploadField";

const DeveloperEditForm = ({
  currentDeveloper,
  id,
  userId,
  isAdmin = false,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [preview, setPreview] = useState(null);
  const [developerName, setDeveloperName] = useState(currentDeveloper?.name);
  const nameChangeHandler = (value) => {
    //

    setDeveloperName(value);
  };
  const queryClient = useQueryClient();

  const methods = useForm({
    defaultValues: currentDeveloper,
  });

  const editDeveloperMutation = useMutation({
    mutationFn: (body) => {
      return editDeveloper(body, id);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["developers"],
      });
      // afterSubmit();
    },
  });

  const onSubmit = (data) => {
    editDeveloperMutation.mutate({
      ...data,
      userId: userId,
    });
    setEditMode(false);
  };

  return (
    <FormProvider {...methods}>
      <form
        className="form edit-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <p className="h8 form__title">Описание</p>
        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="ID developer"
              addClass="form__input"
              size="lg"
              labelSize="small"
              // withError={"Обязательное поле*"}
              disabled
              name={"id"}
            />
          </div>
          <div className="form__item">
            <Input
              withLabel="ID ЦМ пользователя"
              addClass="form__input"
              size="lg"
              labelSize="small"
              // withError={"Обязательное поле*"}
              disabled
              name={"userId"}
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="Название застройщика"
              addClass="form__input"
              size="lg"
              labelSize="small"
              disabled={!editMode}
              name={"name"}
              onChange={(evt) => nameChangeHandler(evt.target.value)}
            />
          </div>
          <div className="form__item">
            <Input
              withLabel="Описание застройщика"
              addClass="form__input"
              size="lg"
              labelSize="small"
              disabled={!editMode}
              name="description"
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__item form__item_single">
            <Input
              withLabel="ИНН"
              addClass="form__input"
              size="lg"
              labelSize="small"
              name="inn"
              disabled={!editMode}
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__edit">
            {isAdmin && (
              <div className="form__admin-btns">
                <BanBtn text={"застройщика"} />
              </div>
            )}
            {!editMode ? (
              <Button
                type="button"
                buttonType="secondary"
                size={"m"}
                addClass="form__edit-btn"
                onClick={(evt) => {
                  evt.preventDefault();
                  setEditMode(true);
                }}
              >
                Редактировать
              </Button>
            ) : (
              <Button
                type="submit"
                isLoading={editDeveloperMutation.isLoading}
                buttonType="primary"
                size={"m"}
                addClass="form__edit-btn"
              >
                Сохранить
              </Button>
            )}
          </div>
        </div>

        <p className="h8 form__title">Изображение застройщика</p>

        <div className="form__row">
          <div className="form__item">
            <UploadField
              smallView
              setValue={() => {}}
              preview={preview}
              handleSetPreview={setPreview}
            />
          </div>
        </div>

        {developerName && (
          <div className="form__row">
            <div className="form__item">
              <div className="input-wrapper">
                <span className="input-wrapper__label body-smaller">
                  Отображение в строке выдачи
                </span>
              </div>

              <Flexbox
                addClass="form__preview"
                style={{ width: "428px" }}
                fitContent
              >
                {<span className="form__preview-name">{developerName}</span>}
                {preview && (
                  <img className="form__preview-image" src={preview} alt="" />
                )}
              </Flexbox>
            </div>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default DeveloperEditForm;
