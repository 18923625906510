import AdminTable from "../AdminTable/AdminTable";
import Tabs from "../../../components/ui/Tabs/Tabs";
import SearchInput from "../SearchInput/SearchInput";
import ChangeDeveloper from "./ChangeDeveloper";

const Developer = () => {
  return (
    <div className="admin-user">
      <SearchInput disabled />
      <Tabs underlineView size="14" addClass="admin-user__tabs">
        <div label="Данные о застройщике">
          <ChangeDeveloper />
        </div>
        <div label="ЖК застройщика">
          <AdminTable data={{}} />
        </div>
      </Tabs>
    </div>
  );
};

export default Developer;
