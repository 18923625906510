import classNames from "classnames";
import Label from "../Label/Label";
import Button from "../Button/Button";
import Slider from "../Slider/Slider";
import PurchaseCard from "../PurchaseCard/PurchaseCard";
import StatusLabel from "./StatusLabel";
import LikeButton from "../LikeButton/LikeButton";
import PoolLabel from "../PoolLabel/PoolLabel";
import { formatId, formatTokensForDisplay } from "../../../utils";
import { tokenizedReleaseStatusLabelMap } from "../../../admin/components/AdminTable/AdminTableFormaters";
import { RepaymentMethods } from "../../../constants";
import useClickOutside from "../../../hooks/useClickOutside";
const CardMain = ({ release, releases, buyTokens }) => {
  const { isOpened, setIsOpened } = useClickOutside();

  const cardClass = classNames({
    "card card_main": true,
  });

  const tokenCardClass = classNames({
    "card__token-card": true,
    "card__token-card_shown": isOpened,
  });

  if (!release) return null;

  const availableTokens = formatTokensForDisplay(release?.availableTokens);

  return (
    <article className={cardClass}>
      <div className="card__wrapper">
        <div className="card__labels-list">
          <StatusLabel data={release} />
          <Label labelType="secondary">1 токен = 1 м²</Label>
          <Label labelType="secondary">
            {tokenizedReleaseStatusLabelMap[release.status]}
          </Label>
          <Label labelType="secondary">
            {RepaymentMethods[release.pool.repaymentMethod]}
          </Label>
          {availableTokens > 0 ? (
            <Label labelType="primary">
              Токенов осталось: {availableTokens.toLocaleString()}
            </Label>
          ) : (
            <Label labelType="secondary-gray">Нет доступных токенов</Label>
          )}
        </div>

        <LikeButton buildingId={release.building.id} />

        {/* <img className="card__img" src={mocImg} alt="" /> */}

        <PoolLabel addClass="card__pool-label">
          {release.pool.name || formatId(release.pool.id)}
        </PoolLabel>

        {!!release.building?.imagesLinks && (
          <Slider photos={release.building.imagesLinks} />
        )}

        {/* <div className="card__timer-block">
          {!buyTokens && <TimerBlock isSmall />}
        </div> */}
      </div>

      {buyTokens && (
        <div className={tokenCardClass}>
          <PurchaseCard
            activeReleases={releases}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            availableTokens={availableTokens}
          />
        </div>
      )}

      <div className="card__body">
        <div className="sell-info">
          <div className="sell-info__col">
            <span className="sell-info__key">{release.building.name}</span>
            <p className="sell-info__value base-14 text-gray">
              {release.building.developer.name}
            </p>
          </div>

          <div className="sell-info__col">
            <span className="sell-info__key">{release.building.city}</span>
            <p className="sell-info__value base-14 text-gray">
              {release.building.district}
            </p>
          </div>

          {release.building.status && (
            <div className="sell-info__col">
              <span className="sell-info__key">
                {`${
                  ["FOR_SALE", "PAYMENTS"].includes(release.building.status)
                    ? "Сдан"
                    : "Сдается"
                } в ${release.building.plannedDeliveryQuarter} квартале ${
                  release.building.plannedDeliveryYear
                } года`}
              </span>
              <p className="sell-info__value base-14 text-gray">
                {release.building.district}
              </p>
            </div>
          )}

          <div className="sell-info__col">
            <span className="sell-info__key">{`1 токен = ${release.price.toLocaleString()} ₽`}</span>
            <p className="sell-info__value base-14 text-gray">От застройщика</p>
          </div>
        </div>

        {!buyTokens ? (
          <Button isLink={`/${release.pool.id}`}>Подробнее</Button>
        ) : (
          <Button onClick={() => setIsOpened(true)} disabled={isOpened}>
            Купить токены
          </Button>
        )}
      </div>
    </article>
  );
};

export default CardMain;
