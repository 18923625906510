import { getUserById } from "../../../api";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
const useUserById = () => {
  const { id } = useParams();

  const user = useQuery({
    queryKey: ["users", id],
    queryFn: () => getUserById(id),
    enabled: !!id,
  });
  return user;
};

export default useUserById;
