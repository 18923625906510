import classNames from "classnames";
import Container from "../../Container/Container";
import "./index.scss";
import Logo from "../../../ui/Logo/Logo";
import UserMenu from "../../UserMenu/UserMenu";
import { Link } from "react-router-dom";
import { useUserStore } from "../../../../store";

const BottomBar = () => {
  const bottombarClass = classNames({
    bottombar: true,
  });
  const setIsLoggedIn = useUserStore((state) => state.login);
  if (localStorage.getItem("accessToken")) {
    setIsLoggedIn();
  }
  return (
    <section className={bottombarClass}>
      <Container>
        <div className="bottombar__block">
          <Logo />
          <ul className="bottombar__nav">
            <li>
              <Link to={"/"} className="link">
                Новостройки
              </Link>
            </li>
            <li>
              <Link to={"/user/tokens"} className="link">
                Мой метр
              </Link>
            </li>
          </ul>

          <UserMenu />
        </div>
      </Container>
    </section>
  );
};

export default BottomBar;
