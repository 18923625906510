import News from "../../components/content/News/News";
import Card from "../../components/ui/Card/Card";
import { Loader } from "../../components/ui/Loader/Loader";
import "./index.scss";

import SliderContent from "../../components/ui/Slider/SliderContent";
import { config } from "../../config";
import useFavorites from "../../hooks/fetchers/user/useFavorites";
import useReleases from "../../hooks/fetchers/releases/useReleases";

const HomePage = () => {
  const { data: releases, isLoading } = useReleases({
    available: true,
  });

  // const { data: releases, isLoading } = useAvailavleReleases();
  const { data: favorites, isLoading: favoritesIsLoading } = useFavorites();
  if (isLoading || favoritesIsLoading) return <Loader isBlue isLarge />;
  if (!releases) return null;

  const favoritesIds = favorites?.map((favorite) => favorite.id);

  return (
    <section className="home">
      <h1 className="title">Новостройки</h1>

      {releases.length === 0 && <p>Активных выпусков пока нет :(</p>}
      <div className="home__slider">
        <SliderContent releases={releases} favoritesIds={favoritesIds} />
      </div>

      <div className="home__grid">
        {releases &&
          releases.map((release) => (
            <Card key={`homepage-${release.id}`} release={release} />
          ))}
      </div>

      {config.dev_mode && <News />}
    </section>
  );
};

export default HomePage;
