import Input from "../../ui/Input/Input";
import { FormSelect } from "../../ui/Select/FormSelect";
import { InputWithSlider } from "../../ui/Input/InputWithSlider";

import {
  yearsOptions,
  buildingStatusOptions,
  quarterOptions,
} from "../../../admin/components/forms/AddForm/AddBuildingForm";

/**
 * @param {{status : string}}
 * @returns {import("react").ReactNode}
 */

export function BuildingDescription({ status }) {

  return (
    <>
      <div className="form__row">
        <div className="form__item">
          <Input {...inputProps({ withLabel: "Название ЖК", name: "name" })} />
        </div>
      </div>
      <div className="form__row">
        <div className="form__item">
          <Input
            as="textarea"
            {...inputProps({
              withLabel: "Описание ЖК",
              name: "description",
            })}
          />
        </div>
      </div>
      <div className="form__row">
        <div
          className="form__item flexbox flexbox_direction-column"
          style={{ gap: "10px" }}
        >
          <Input
            {...inputProps({
              withLabel: "Адрес ЖК",
              name: "city",
              placeholder: "Город",
            })}
          />
          <Input
            {...inputProps({
              name: "district",
              placeholder: "Район",
            })}
          />
          <Input
            {...inputProps({
              name: "street",
              placeholder: "Улица",
            })}
          />
          <Input
            {...inputProps({
              name: "houseNumber",
              placeholder: "Номер дома",
            })}
          />
        </div>
      </div>
      <div className="form__row">
        <div className="form__item">
          <FormSelect
            name="status"
            whiteType
            options={buildingStatusOptions}
            withLabel="Статус ЖК"
          />
        </div>
      </div>
      {status === "START_BUILDING" ? (
        <div className="input-wrapper" style={{ marginTop: 20 }}>
          <div className="form__row">
            <div className="form__item">
              <FormSelect
                name="startBuildingYear"
                options={yearsOptions}
                whiteType
              />
            </div>
            <div className="form__item">
              <FormSelect
                name="startBuildingQuarter"
                options={quarterOptions}
                whiteType
              />
            </div>
          </div>
        </div>
      ) : null}
      {status === "PERCENT_BUILDING" ? (
        <div className="form__row">
          <div className="form__item">
            <InputWithSlider {...inputProps({ name: "percentBuilding" })} />
          </div>
        </div>
      ) : null}
      <div className="input-wrapper" style={{ marginTop: 20 }}>
        <span className="input-wrapper__label body-smaller">
          Планируемый срок сдачи
        </span>
        <div className="form__row">
          <div className="form__item">
            <FormSelect
              name="plannedDeliveryYear"
              options={yearsOptions}
              whiteType
            />
          </div>
          <div className="form__item">
            <FormSelect
              name="plannedDeliveryQuarter"
              options={quarterOptions}
              whiteType
            />
          </div>
        </div>
      </div>
    </>
  );
}

function inputProps({ withLabel, name, placeholder = "" }) {
  return {
    placeholder,
    ...(withLabel ? { withLabel } : {}),
    name,
    addClass: "form__input",
    size: "lg",
    labelSize: "small",
  };
}
