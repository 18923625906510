const Icon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ flexShrink: 0 }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.11846 0.0396562C6.78745 0.0427237 6.52153 0.321219 6.52451 0.661693C6.52749 1.00217 6.79825 1.27569 7.12926 1.27262L9.94042 1.24657L5.89093 5.41182C5.65685 5.65258 5.65685 6.04293 5.89093 6.28369C6.125 6.52445 6.5045 6.52445 6.73857 6.28369L10.7881 2.11845L10.7627 5.00996C10.7598 5.35043 11.0257 5.62893 11.3567 5.632C11.6877 5.63506 11.9585 5.36154 11.9614 5.02107L12 0.622062C12.0014 0.456635 11.9382 0.29755 11.8244 0.180571C11.7107 0.0635916 11.5561 -0.00146578 11.3952 2.50698e-05L7.11846 0.0396562ZM0.599376 0.286341C0.26835 0.286341 0 0.562361 0 0.90285V11.3835C0 11.724 0.26835 12 0.599376 12H11.3881C11.7192 12 11.9875 11.724 11.9875 11.3835V8.30095C11.9875 7.96046 11.7192 7.68444 11.3881 7.68444C11.0571 7.68444 10.7888 7.96046 10.7888 8.30095V10.767H1.19875V1.51936H3.89595C4.22697 1.51936 4.49532 1.24334 4.49532 0.90285C4.49532 0.562361 4.22697 0.286341 3.89595 0.286341H0.599376Z"
      fill="#3399CC"
    />
  </svg>
);

export default Icon;
