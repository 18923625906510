import Container from "./Container/Container";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Outlet } from "react-router-dom";
import classNames from "classnames";
import useUser from "../../hooks/useUser";
import useScrollTop from "../../hooks/useScrollTop";
import OisAuthPanel from "../OisAuthPanel/OisAuthPanel";

const Layout = ({ isReadingPage = false }) => {
  useScrollTop();

  useUser();
  const mainClasses = classNames({
    main: true,
    "main_pb-100": isReadingPage,
  });
  return (
    <div className="App">
      <OisAuthPanel />
      <Header />
      <main className={mainClasses}>
        <Container isReading={isReadingPage}>
          <Outlet />
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
