import CollapseToggler from "../Collapse/CollapseToggler";
import Collapse from "../Collapse/Collapse";
import { useState } from "react";

const CertInfo = () => {
  const [collapseOpened, setCollapseOpened] = useState(false);

  return (
    <div className="sign__cert">
      <CollapseToggler
        addClass={"sign__cert-toggler"}
        collapseOpened={collapseOpened}
        setCollapseOpened={setCollapseOpened}
      >
        Информация о вашем сертификате
      </CollapseToggler>
      <Collapse addClass={"sign__cert-content"} isOpened={collapseOpened}>
        CN= OOO Цифровой метр, Выдан: 15.02.2024 12:48:23
      </Collapse>
    </div>
  );
};

export default CertInfo;
