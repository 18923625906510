import { useQuery } from "react-query";
import { getOisHealth } from "../../api";
const useOisHealth = (
  { onSuccess } = {
    onSuccess: () => {},
  }
) => {
  return useQuery({
    queryKey: ["health"],
    queryFn: getOisHealth,
    onSuccess: onSuccess,
  });
};

export default useOisHealth;
