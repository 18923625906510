import Button from "../ui/Button/Button";
import CloseButton from "../ui/CloseButton/CloseButton";
import "./index.scss";
import useOisAuth from "../../hooks/fetchers/user/useOisAuth";
import oisSvg from "../../assets/img/icons/ois.svg";

const OisModal = ({ closeModal }) => {
  const oisAuth = useOisAuth();
  return (
    <div className="modal">
      <CloseButton addClass="modal__close-btn" onClick={closeModal} />
      <img src={oisSvg} alt="" />
      <p className="text-center">
        Регистрация успешно завершена. Для совершения покупок в Цифровом метре
        необходимо пройти регистрацию на сайте партнера ЦФА ХАБ (оператор
        информационных систем).
      </p>
      <Button addClass="modal__btn" isFull onClick={() => oisAuth.mutate()}>
        Пройти регистрацию у партнёра
      </Button>
    </div>
  );
};

export default OisModal;
