import { useState, useEffect } from "react";
import Button from "../../../../components/ui/Button/Button";
import "./EditForm.scss";
import { BuildingDescription } from "../../../../components/forms/PolymorhicForm/BuildingDescription";
import {FormProvider, useForm} from "react-hook-form";

import {extractExceptFields, formatId} from "../../../../utils";
import { useMutation, useQueryClient } from "react-query";
import {addImagesToBuildingById, editBuilding} from "../../../../api";
import BuildingGallery from "../../Building/BuildingGallery";
import {config} from "../../../../config";

/**
 *
 * @param {{building : import("../../../api").Building, isAdmin : boolean}}
 * @returns
 */
const BuildingEditForm = ({ isAdmin = false, building }) => {
  const [editMode, setEditMode] = useState(false);

  const formHook = useForm({
    defaultValues: {
      ...{
        status: "START_BUILDING",
        startBuildingYear: "2024",
        startBuildingQuarter: "1",
        plannedDeliveryYear: "2024",
        plannedDeliveryQuarter: "1",
        percentBuilding: 0,
      },
      ...extractExceptFields(building, [
        "id",
        "imagesIds",
        "imagesLinks",
        "developer",
      ]),
    },
    disabled: !editMode,
  });

  useEffect(() => {
    formHook.reset(
      defaultValues => ({
        ...defaultValues,
        description: building?.description,
        name: building?.name,
        city: building?.city,
        district: building?.district,
        street: building?.street,
        houseNumber: building?.houseNumber,
        status: building?.status,
        startBuildingYear: building?.startBuildingYear,
        startBuildingQuarter: building?.startBuildingQuarter,
        percentBuilding: building?.percentBuilding,
        plannedDeliveryYear: building?.plannedDeliveryYear,
        plannedDeliveryQuarter: building?.plannedDeliveryQuarter
      }))
  }, [building]);

  const status = formHook.watch("status");
 
  const queryClient = useQueryClient();
  const onSubmit = (data) => {
    if(data.addImage) {
      addImageMutation.mutate({ id: building?.id , imageId: data.addImage })
      setEditMode(false);
    } else {
      editBuildingMutation.mutate({ body: {...data, developerId: building.developer.id }, id: building.id});
      setEditMode(false);
    }
  }

  const editBuildingMutation = useMutation({
    mutationFn: (body) => {
      return editBuilding(body.body, body.id)
          },
          onSuccess: () => {
            queryClient.invalidateQueries(["building", "buildings"]);
            queryClient.invalidateQueries(["flat"]);
            }
          })

  const addImageMutation = useMutation({
    mutationFn: (body) => addImagesToBuildingById(body.id, body.imageId),
    onSuccess: () => {
      queryClient.invalidateQueries(["building", "buildings"]);
      queryClient.invalidateQueries(["flat"]);
    }
  })

  return (
    <>
      <FormProvider {...formHook}>
        <form
          onSubmit={formHook.handleSubmit((data) => {
            if (!editMode) {
              onSubmit(data)
            }
          })}
          className="form edit-form"
        >
          <p className="h8 form__title">Описание</p>
          <BuildingDescription {...{ status }} />

          <div className="form__row" style={{ marginTop: "30px" }}>
            <div className="form__edit">
              <Button
                type="submit"
                buttonType={editMode ? "primary" : "secondary"}
                size={"m"}
                onClick={() => setEditMode((prev) => !prev)}
                addClass="form__edit-btn"
              >
                {editMode ? "Сохранить" : "Редактировать"}
              </Button>
            </div>
          </div>
            <BuildingGallery
                building={building}
                editMode={editMode}
                onSubmit={formHook.handleSubmit(onSubmit)}
                addImageMutation={addImageMutation}
            />
        </form>
      </FormProvider>

      {/* <>
        <p className="h8 form__title">Фотографии ЖК</p>
        <div className="input-wrapper">
          <span className="input-wrapper__label body-smaller">
            Отображение на баннере
          </span>

          <div className="form__row form__row_grid-2">
            {banners &&
              banners.map((banner) => (
                <UploadField
                  key={`banner-${banner.id}`}
                  setValue={() => {}}
                  preview={banner.src}
                  handleSetPreview={(newPreview) =>
                    setBanners((prevBanners) => {
                      return prevBanners.map((prevBanner) => {
                        return prevBanner.id === banner.id
                          ? {
                              ...prevBanner,
                              src: newPreview,
                            }
                          : prevBanner;
                      });
                    })
                  }
                  fullPreview
                />
              ))}
          </div>
        </div>

        <div className="input-wrapper" style={{ marginTop: 20 }}>
          <span className="input-wrapper__label body-smaller">
            Отображение в строке выдачи
          </span>

          <div className="form__row form__row_grid-4">
            {photos &&
              photos.map((photo) => (
                <UploadField
                  key={`photo-${photo.id}`}
                  setValue={() => {}}
                  preview={photo.src}
                  handleSetPreview={(newPreview) =>
                    setPhotos((prevPhotos) => {
                      return prevPhotos.map((prevPhoto) => {
                        return prevPhoto.id === photo.id
                          ? {
                              ...prevPhoto,
                              src: newPreview,
                            }
                          : prevPhoto;
                      });
                    })
                  }
                  fullPreview
                />
              ))}
          </div>
        </div>
      </> */}
    </>
  );
};

export default BuildingEditForm;
