import { useQuery } from "react-query";
import { getMyTokens } from "../../api";
const useMyTokens = () => {
  return useQuery({
    queryKey: ["tokens"],
    queryFn: getMyTokens,
  });
};

export default useMyTokens;
