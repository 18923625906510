import useMyTokens from "../../../../hooks/fetchers/useMyTokens";
import { useOrdersPoolView } from "../../../../hooks/fetchers/orders";
import { Loader } from "../../../ui/Loader/Loader";
import CardHorizontalToken from "./CardHorizontalToken";
import Tabs from "../../../ui/Tabs/Tabs";
import "./index.scss";
import CardHorizontalOrderInProgress from "./CardHorizontalOrderInProgress";

const MyTokens = () => {
  const { data: tokens, isLoading } = useMyTokens();

  const { data: orders, isLoading: isLoadingOrders } = useOrdersPoolView();
  if (isLoading || isLoadingOrders) return <Loader isBlue isLarge />;
  if (!tokens || !orders) return null;

  return (
    <Tabs underlineView size="14" addClass="admin-user__tabs">
      <div label="Активные">
        {tokens.length === 0 ? (
          <p style={{ marginTop: 15 }}>У вас еще нет токенов</p>
        ) : (
          <section className="my-tokens">
            {tokens.map((token) => (
              <CardHorizontalToken
                key={`my-token-${token.pool?.id}`}
                tokenData={token}
              />
            ))}
          </section>
        )}
      </div>
      <div label="В процессе">
        {orders && orders.length === 0 ? (
          <p style={{ marginTop: 15 }}>
            У вас нет незаконченных сделок по токенам
          </p>
        ) : (
          <section className="my-tokens">
            {orders.map((order) => {
              return (
                <CardHorizontalOrderInProgress
                  key={`my-order-${order.amount}`}
                  tokenData={order}
                />
              );
              //
            })}
          </section>
        )}
      </div>
    </Tabs>
  );
};

export default MyTokens;
