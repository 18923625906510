import useRole from "../../../hooks/useRole";
import Button from "../../ui/Button/Button";
import useMakeAdmin from "../../../hooks/fetchers/user/useMakeAdmin";
import useUserById from "../../../hooks/fetchers/user/useUserById";
import { checkAdminRole } from "../../../utils";
import { Loader } from "../../ui/Loader/Loader";
import useDemoteAdmin from "../../../hooks/fetchers/user/useDemoteAdmin";
import useUserId from "../../../hooks/useUserId";
import { useParams } from "react-router-dom";
const CreateAdminButton = () => {
  const { isAdmin } = useRole();
  const makeAdmin = useMakeAdmin();
  const demoteAdmin = useDemoteAdmin();
  const { data, isLoading } = useUserById();
  const { id } = useParams();
  const isMe = id === useUserId();
  if (isLoading) return <Loader isBlue isLarge />;
  if (!isAdmin || !data || isMe) return null;

  const isUserAdmin = checkAdminRole(data.roles);

  return (
    <Button
      type="button"
      buttonType={isUserAdmin ? "secondary-red" : "primary"}
      size={"m"}
      onClick={isUserAdmin ? demoteAdmin.mutate : makeAdmin.mutate}
      disabled={makeAdmin.isLoading || demoteAdmin.isLoading}
    >
      {isUserAdmin
        ? "Разжаловать из администраторов"
        : "Сделать администратором"}
    </Button>
  );
};

export default CreateAdminButton;
