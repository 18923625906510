import Container from "../components/layout/Container/Container";
import Button from "../components/ui/Button/Button";
import OiIcon from "../assets/img/icons/oi.svg";
const ErrorBoundary = () => {
  return (
    <div className="App">
      <main
        className="main"
        style={{
          display: "flex",
          alignItems: "center",
          minHeight: "100vh",
          background: "#399ACA",
          color: "white",
        }}
      >
        <Container>
          <section className="home">
            <img
              src={OiIcon}
              alt=""
              width={340}
              style={{ marginBottom: "35px" }}
            />
            <h1 className="title">Что-то пошло не так!</h1>
            <p style={{ marginBottom: "25px" }}>
              Страница не найдена или произошла ошибка сервера. Проверьте
              правильность введенного адреса страницы
            </p>

            <Button isLink={"/"} buttonType="secondary">
              Вернуться на главную
            </Button>
          </section>
        </Container>
      </main>
    </div>
  );
};

export default ErrorBoundary;
