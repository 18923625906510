import CollapseToggler from "../Collapse/CollapseToggler";
import Collapse from "../Collapse/Collapse";
import { useState } from "react";
import ReleaseLink from "../ReleaseLink/ReleaseLink";
import { formatId } from "../../../utils";
import { SignIcon } from "./SignIcon";
import Checkbox from "../Checkbox/Checkbox";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import CertInfo from "./CertInfo";

const SignItem = ({
  release,
  isDefaultOpened = false,
  isSigned,
  onSignOrder,
}) => {
  const [collapseOpened, setCollapseOpened] = useState(isDefaultOpened);
  const [docsCheckboxChecked, setDocsCheckboxChecked] = useState(false);
  const [agreementCheckboxChecked, setAgreementCheckboxChecked] =
    useState(false);

  const docReleaseLink = release?.docReleaseLink ? release?.docReleaseLink : "#";
  const riskNotificationLink = release?.riskNotificationLink ? release?.riskNotificationLink : "#";

  return (
    <div className="sign-card">
      <CollapseToggler
        addClass={"sign-card__toggler"}
        collapseOpened={collapseOpened}
        setCollapseOpened={setCollapseOpened}
      >
        <SignIcon isChecked={isSigned} />
        <ReleaseLink release={release}>{formatId(release.id)}</ReleaseLink>
      </CollapseToggler>
      <Collapse isOpened={collapseOpened}>
        <div className="sign-card__content">
          <label style={{ display: "flex", gap: 8, cursor: "pointer" }}>
            <Checkbox
              checkboxValue={docsCheckboxChecked}
              setCheckboxValue={setDocsCheckboxChecked}
            />
            <div>
              Я согласен с{" "}
              <span>
                <Link
                  className="link link_blue"
                  href={docReleaseLink}
                  target="_blank"
                >
                  Решением о выпуске
                </Link>{" "}
              </span>
              и{" "}
              <span>
                <Link
                  className="link link_blue"
                  href={riskNotificationLink}
                  target="_blank"
                >
                  Уведомлением о рисках
                </Link>
              </span>
            </div>
          </label>

          <label style={{ display: "flex", gap: 8, cursor: "pointer" }}>
            <Checkbox
              checkboxValue={agreementCheckboxChecked}
              setCheckboxValue={setAgreementCheckboxChecked}
            />
            <div>Я соглашаюсь и подписываю заявку о покупке</div>
          </label>

          <Button
            disabled={
              !docsCheckboxChecked || !agreementCheckboxChecked || isSigned
            }
            size="m"
            onClick={onSignOrder}
            isFull
          >
            {isSigned ? "Подписано" : "Подписать"}
          </Button>

          <CertInfo />
        </div>
      </Collapse>
    </div>
  );
};

export default SignItem;
