import Tabs from "../../../components/ui/Tabs/Tabs";
import SearchInput from "../SearchInput/SearchInput";
import ChangeBuilding from "./ChangeBuilding";
import { useQuery } from "react-query";
import { getBuildingById } from "../../../api";
import { FlatsOfBuilding } from "./FlatsOfBuilding";
import { Loader } from "../../../components/ui/Loader/Loader";
import usePathId from "../../../hooks/usePathId";
import BuildingInfo from "./BuildingInfo";

const Building = () => {
  const id = usePathId();

  const { data, isSuccess } = useQuery("flat", async () => getBuildingById(id));

  return isSuccess ? (
    <div className="admin-user">
      <SearchInput disabled />
      <Tabs underlineView size="14" addClass="admin-user__tabs">
        <div label="Данные о ЖК">
            <div   style={{
                display: "grid",
                gridTemplateColumns: "1fr 540px",
                justifyContent: "space-between",
                gap: "10%",
            }}>
                <ChangeBuilding building={data} />
                <BuildingInfo building={data} />
            </div>
        </div>
        <div label="Квартиры ЖК">
          <FlatsOfBuilding buildingId={data.id} />
        </div>
      </Tabs>
    </div>
  ) : (
    <Loader isBlue isLarge />
  );
};

export default Building;
