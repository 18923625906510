import classNames from "classnames";
import Button from "../../ui/Button/Button";
import { useEffect, useState } from "react";
import { BellSvg, UserSvg, DotsSvg, ArrowSvg } from "./Icons";

import Modal from "react-modal";
import useModal from "../../../hooks/useModal";
import LoginModal from "../../modals/LoginModal/LoginModal";

import "./UserMenu.scss";
import { Link } from "react-router-dom";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import DropdownMenu from "./DropdownMenu/DropdownMenu";
import WalletButton from "../../WalletButton/WalletButton";
import { config } from "../../../config";
import OisModal from "../../modals/OisModal";
import useLoginModal from "../../../hooks/useLoginModal";

const UserMenu = () => {
  const isLoggedIn = useIsLoggedIn();

  const {
    modalIsOpen,
    openModal,
    closeModal,
    isLoginAction,
    setIsLoginAction,
  } = useLoginModal();

  const [oisModalIsOpen, openOisModal, closeOisModal] = useModal();

  const userMenuClass = classNames({
    "user-menu": true,
  });

  return (
    <div className={userMenuClass}>
      {isLoggedIn ? (
        <div className="user-menu__interface">
          {config.dev_mode && (
            <>
              <WalletButton balance={"370 000"} />
              <Link to="user/notifications" className="user-menu__notification">
                <BellSvg />
                <span className="user-menu__notification-counter">99</span>
              </Link>
            </>
          )}

          <Link
            to="/settings/personal"
            type="button"
            className="user-menu__profile"
          >
            <UserSvg />
          </Link>

          <DropdownMenu />
        </div>
      ) : (
        <div className="user-menu__buttons">
          <Button
            buttonType="secondary"
            size={"m"}
            onClick={() => {
              setIsLoginAction(false);
              openModal();
            }}
          >
            Регистрация
          </Button>
          <Button
            buttonType="primary"
            size={"m"}
            onClick={() => {
              setIsLoginAction(true);
              openModal();
            }}
          >
            Вход
          </Button>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <LoginModal
          closeModal={closeModal}
          isLoginAction={isLoginAction}
          openOisModal={openOisModal}
        />
      </Modal>

      <Modal
        isOpen={oisModalIsOpen}
        onRequestClose={closeOisModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <OisModal closeModal={closeOisModal} />
      </Modal>
    </div>
  );
};

export default UserMenu;
