import {
  bookingOptions,
  tokenizationOptions,
} from "../../../components/forms/PolymorhicForm/FlatDescription";
import Button from "../../../components/ui/Button/Button";
import {
  InfoKey,
  InfoPair,
  InfoValue,
} from "../../../components/ui/InfoPair/InfoPair";
import { formatId } from "../../../utils";
import RoundedBlock from "../../../components/ui/RoundedBlock/RoundedBlock";

/**
 * @param {{style : import("react").CSSProperties, flat : import("../../../api").Flat}}
 * @returns {import("react").ReactNode}
 */
export function FlatInfo({ flat, style, ...props }) {
  return (
    <div
      className="flexbox flexbox_direction-column"
      style={{ ...style, marginTop: "50px" }}
      {...props}
    >
      <p className="h8 form__title" style={{ marginBottom: "10px" }}>
        Информация
      </p>
      <RoundedBlock>
        {[
          [
            "Статус выпуска",
            <div className="flexbox">
              <Button isLabel size="xs" buttonType="secondary">
                {
                  tokenizationOptions.find(
                    ({ value }) => value === flat.tokenizationStatus
                  ).label
                }
              </Button>
              <Button isLabel size="xs" buttonType="secondary">
                {
                  bookingOptions.find(
                    ({ value }) => value === flat.bookingStatus
                  ).label
                }
              </Button>
            </div>,
          ],
          ["ID Квартиры", formatId(flat.id)],
          [
            <span>
              Площадь квартиры, м<sup>2</sup>
            </span>,
            flat.footage,
          ],
          ["Количество комнат", flat.roomsCount],
          ["№ корпуса", flat.blockNumber],
          ["№ подъезда", flat.entranceNumber],
          ["Этаж", flat.floor],
          ["Номер квартиры", flat.number],
        ].map(([key, value], ind) => (
          <InfoPair key={ind}>
            <InfoKey>{key}</InfoKey>
            <InfoValue>{value}</InfoValue>
          </InfoPair>
        ))}
      </RoundedBlock>
    </div>
  );
}
