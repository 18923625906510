import { CheckboxIconChecked, CheckboxIconUnchecked } from "./Icons";

import classNames from "classnames";
import "./index.scss";
import { useController, useFormContext } from "react-hook-form";

const Checkbox = ({
  checkboxValue,
  setCheckboxValue,
  withLabel = null,
  name = "checkbox",
  required = false,
  ...attr
}) => {
  const formContext = useFormContext();

  // const { field } = useController({
  //   name,

  //   rules: { required: true },
  // });

  const register = formContext ? formContext.register : (name) => name; // костыли потом убрать
  const { errors } = formContext ? formContext.formState : { errors: {} };

  const checkboxClass = classNames({
    checkbox: true,
    [`checkbox_with-label`]: withLabel,
    //[`checkbox_checked`]: field.value,
    checkbox_checked: checkboxValue,
  });

  return (
    <label className={checkboxClass}>
      <div className="checkbox__control">
        {!checkboxValue ? <CheckboxIconUnchecked /> : <CheckboxIconChecked />}
        <input
          type="checkbox"
          className="checkbox__input"
          onChange={() => setCheckboxValue(!checkboxValue)}
          {...register(name, { required })}
          {...attr}
        />
      </div>

      {withLabel && <span>{withLabel}</span>}
    </label>
  );
};

export default Checkbox;
