import { useQuery } from "react-query";
import { getReleases } from "../../../api";
const useReleases = (params = {}) => {
  return useQuery({
    queryKey: ["releases"],
    queryFn: () => getReleases(params),
  });
};

export default useReleases;
