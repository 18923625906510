import { create } from "zustand";
import { persist, createJSONStorage, devtools } from "zustand/middleware";

export const useUserStore = create(
  devtools(
    persist(
      (set) => ({
        user: null,
        isLoggedIn: false,
        setUser: (user) => set(() => ({ user })),
        login: () => set(() => ({ isLoggedIn: true })),
        logout: () => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          return set(() => ({ user: null, isLoggedIn: false }));
        },
      }),
      {
        name: "user-storage", // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      }
    )
  )
);

export const useLoginModalStore = create(
  devtools(
    persist(
      (set) => ({
        loginModalIsOpen: false,
        isLoginAction: true,
        openLoginModal: () => set({ loginModalIsOpen: true }),
        closeLoginModal: () => set({ loginModalIsOpen: false }),
        setIsLoginAction: (isLoginAction) => set({ isLoginAction }),
      }),
      {
        name: "login-modal-storage", // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      }
    )
  )
);

export const useActiveOrdersStore = create(
  devtools(
    persist(
      (set) => ({
        orders: [],
        setOrders: (orders) => set({ orders }),
        addOrder: (order) =>
          set((state) => ({
            orders: [...state.orders, order],
          })),
        removeOrder: (orderId) =>
          set((state) => ({
            orders: state.orders.filter((order) => order.id !== orderId),
          })),
        clearOrders: () => set({ orders: [] }),
        setOrderStatus: (orderId, status) =>
          set((state) => ({
            orders: state.orders.map((order) =>
              order.id === orderId ? { ...order, status } : order
            ),
          })),
      }),
      {
        name: "active-orders-storage", // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      }
    )
  )
);
