import { useQueryClient } from "react-query";
import { useUserStore } from "../store";
const useLogout = () => {
  const queryClient = useQueryClient();
  const logout = useUserStore((state) => state.logout);
  return () => {
    logout();
    queryClient.removeQueries();
    queryClient.invalidateQueries(undefined, { exact: true });
  };
};

export default useLogout;
