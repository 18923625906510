import Button from "../Button/Button";
import QR from "./Qr";
import Status from "../Status/Status";
import DropzoneFilesDoc from "../Dropzone/DropzoneFilesDoc";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { addOrderPaymentDocument } from "../../../api";
import { useMutation } from "react-query";

const PaymentScreen = ({ orderId, onSuccessPayment }) => {
  const [paymentDocument, setPaymentDocument] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const methods = useForm({});

  const addOrderPaymentDocumentMutation = useMutation({
    mutationFn: (body) => {
      return addOrderPaymentDocument(body.body, body.orderId);
    },

    onSuccess: () => {
      setErrorMessage("");
      setPaymentDocument();
      onSuccessPayment();
    },
    onError: (data) => {
      setErrorMessage("");
      if (data.response.data.error) {
        setErrorMessage(data.message);
      } else {
        if (data.response.data.includes("Incorrect order status")) {
          setErrorMessage(data.response.data);
        }
      }
    },
  });

  const onButtonClearFile = () => {
    setErrorMessage("");
    setPaymentDocument();
  };

  const onSubmit = (data) => {
    setPaymentDocument(data.receipt);
  };

  return (
    <FormProvider {...methods}>
      <div className="success-screen">
        <p style={{ color: "#3399CC", fontSize: "18px", fontWeight: "500" }}>
          Оплата
        </p>
        <Status text="Шаг 1. Оплатите токены по QR-коду" status="success" />
        <QR orderId={orderId} />
        <Status text="Шаг 2. Загрузите квитанцию об оплате" status="success" />
        <DropzoneFilesDoc
          accept={{
            "application/octet-stream": [".pdf", ".png", ".docx", ".jpeg"],
          }}
          name={"receipt"}
          onSubmit={methods.handleSubmit(onSubmit)}
          onButtonClearFile={onButtonClearFile}
        />
        <Button
          disabled={addOrderPaymentDocumentMutation.isLoading}
          isLoading={addOrderPaymentDocumentMutation.isLoading}
          style={{ width: "100%" }}
          onClick={() =>
            addOrderPaymentDocumentMutation.mutate({
              body: paymentDocument,
              orderId: orderId,
            })
          }
        >
          Завершить покупку
        </Button>
        {errorMessage !== "" && (
          <p style={{ color: "#FF6E8C", fontSize: "12px" }}>{errorMessage}</p>
        )}
      </div>
    </FormProvider>
  );
};

export default PaymentScreen;
