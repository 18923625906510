import classNames from "classnames";
import Button from "../../../ui/Button/Button";
import Label from "../../../ui/Label/Label";

import { formatId, numWord } from "../../../../utils";
import { RepaymentMethods } from "../../../../constants";
import "moment/locale/ru";
import ReleaseLink from "../../../ui/ReleaseLink/ReleaseLink";
import PoolLabel from "../../../ui/PoolLabel/PoolLabel";
import StatusLabel from "../../../ui/Card/StatusLabel";
import LikeButton from "../../../ui/LikeButton/LikeButton";
import { formatTokensForDisplay } from "../../../../utils";
const CardHorizontalFavorites = ({ release }) => {
  const cardClass = classNames({
    "card card_h card_token": true,
  });

  if (!release) return null;

  const { pool, building } = release;
  if (!building) return null;
  const { developer } = building;

  const availableTokens = formatTokensForDisplay(release?.availableTokens);

  return (
    <article className={cardClass}>
      <div className="card__labels-list">
        <StatusLabel data={release} />
        <Label labelType="secondary">1 токен = 1 м²</Label>
        {pool.repaymentMethod && (
          <Label labelType="secondary">
            {RepaymentMethods[pool.repaymentMethod]}
          </Label>
        )}
      </div>

      <PoolLabel addClass="card__pool-label">
        {pool.name || formatId(pool.id)}
      </PoolLabel>

      <div className="card__header">
        <LikeButton buildingId={building.id} />
        <img className="card__img" src={building?.imagesLinks[0]} alt="" />
      </div>

      <div className="card__body">
        {/* <div className="card__labels-list">
          <Label labelType="tertiary">Построено 25%</Label>
        </div> */}

        <div className="sell-info">
          <div className="sell-info__col">
            <span className="sell-info__title">Цифровой токен</span>
            <span className="sell-info__key">{building.name}</span>
            <p className="sell-info__value">{developer.name}</p>

            <span className="sell-info__key">{building.city}</span>
            <p className="sell-info__value">{building.district}</p>

            <span className="sell-info__key">1 токен ≈ {release.price} ₽</span>
            {
              // тут другая цифра с бэка должна быть ^
            }
            <p className="sell-info__value">Рыночная цена на данный момент</p>
          </div>

          <div className="sell-info__col">
            <span className="sell-info__title">Количество</span>
            <span className="sell-info__key">
              {availableTokens}{" "}
              {numWord(availableTokens, ["токен", "токена", "токенов"])}
            </span>
            <p className="sell-info__value">Общее кол-во токенов</p>

            <span className={`sell-info__key`}>
              {
                <ReleaseLink release={release}>
                  {formatId(release.id)}
                </ReleaseLink>
              }
              <p className="sell-info__value">{release.amount}</p>
            </span>
          </div>

          {pool.repaymentMethod === "GUARANTEED_INCOME" && (
            <div className="sell-info__col">
              <span className="sell-info__title">Гарантированный доход</span>
              <span className="sell-info__key">{pool.exitPrice} ₽</span>
              <p className="sell-info__value">Общая сумма</p>
            </div>
          )}

          {pool.repaymentMethod === "SALES_INCOME" && (
            <div className="sell-info__col">
              <span className="sell-info__title">Доход от продаж</span>
              <span className="sell-info__key">
                {pool.exitPrice} ₽ + % от продажи
              </span>
              <p className="sell-info__value">Гарантированный доход</p>
            </div>
          )}
        </div>

        <div className="card__operations">
          <div className="card__btns">
            <Button
              buttonType="secondary"
              size={"xs"}
              addClass="card__buy-btn"
              onClick={() => {}}
            >
              Подробнее о ЖК
            </Button>
            <Button size={"xs"} addClass="card__buy-btn" isLink={`/${pool.id}`}>
              Купить
            </Button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default CardHorizontalFavorites;
