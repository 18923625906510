import { WaitingTicket } from "./HistoryItems/WaitingTicket";
import { WaitingOis } from "./HistoryItems/WaitingOis";
const OrderHistory = ({ order }) => {
  return (
    <div>
      <p className="h8 form__title">История</p>
      <div className="history">
        {/* {data.map((it, i, arr) => (
          <HistoryItem
            key={it.title}
            {...it}
            isBeforeNextpoint={arr[i + 1]?.status === "nextpoint"}
          />
        ))} */}

        {<WaitingTicket order={order} />}
        {<WaitingOis order={order} />}
      </div>
    </div>
  );
};
export default OrderHistory;
