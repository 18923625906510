export const accountLinks = [
  {
    link: "tokens",
    label: "Мои токены",
  },
  // dev_mode
  // {
  //   link: "exchange",
  //   label: "Биржа",
  // },
  // {
  //   link: "sells",
  //   label: "Мои продажи",
  // },
  // {
  //   link: "history",
  //   label: "История",
  // },
  {
    link: "favorites",
    label: "Избранное",
  },
  // {
  //   link: "notifications",
  //   label: "Уведомления",
  // },
];

export const settingsLinks = [
  {
    link: "personal",
    label: "Персональные данные",
  },
  // dev_mode
  // {
  //   link: "notifications",
  //   label: "Уведомления",
  // },
  // {
  //   link: "support",
  //   label: "Поддержка",
  // },
  // {
  //   link: "requisites",
  //   label: "Реквизиты",
  // },
];

export const adminLinks = [
  {
    link: "users",
    label: "Пользователи",
  },
  {
    link: "emitters",
    label: "Эмитенты",
  },
  {
    link: "developers",
    label: "Застройщики",
  },
  {
    link: "buildings",
    label: "ЖК",
  },
  {
    link: "flats",
    label: "Квартиры",
  },
  {
    link: "pools",
    label: "Пулы",
  },
  {
    link: "releases",
    label: "Выпуски",
  },
  // dev_mode
  {
    link: "orders",
    label: "Сделки",
  },
];
