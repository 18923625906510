import classNames from "classnames";
import "./index.scss";
import mocImg from "../../../assets/img/card.jpg";
import Label from "../Label/Label";
import LikeButton from "../LikeButton/LikeButton";
import { Link } from "react-router-dom";
import StatusLabel from "./StatusLabel";
import { RepaymentMethods } from "../../../constants";
import PoolLabel from "../PoolLabel/PoolLabel";
import { formatId, formatTokensForDisplay } from "../../../utils";
// Карточка пула
const Card = ({ release }) => {
  const cardClass = classNames({
    card: true,
  });

  if (!release || !release.building) return null;

  const availableTokens = formatTokensForDisplay(release.availableTokens);
  return (
    <article className={cardClass}>
      <Link to={release.pool.id}>
        <div className="card__header">
          <div className="card__labels-list">
            <StatusLabel data={release} />
            <Label labelType="secondary">1 токен = 1 м²</Label>
            {release.repaymentMethod && (
              <Label labelType="secondary">
                {RepaymentMethods[release.repaymentMethod]}
              </Label>
            )}
            {availableTokens > 0 ? (
              <Label labelType="primary">
                Токенов осталось: {availableTokens.toLocaleString()}
              </Label>
            ) : (
              <Label labelType="secondary-gray">Нет доступных токенов</Label>
            )}
          </div>
          <LikeButton buildingId={release.building.id} />

          <PoolLabel addClass="card__pool-label">
            {release.pool.name || formatId(release.pool.id)}
          </PoolLabel>

          {release.building?.imagesLinks[0] && (
            <img
              className="card__img"
              style={{ display: "block", minHeight: 420 }}
              src={release.building?.imagesLinks[0] || mocImg}
              alt=""
            />
          )}
        </div>

        <div className="card__body">
          <div className="sell-info">
            <div className="sell-info__col">
              <span className="sell-info__key">{release.building?.name}</span>
              <p className="sell-info__value base-14 text-gray">
                {release.building?.developer?.name}
              </p>
            </div>

            <div className="sell-info__col">
              <span className="sell-info__key">{`1 токен = ${release.price} ₽`}</span>
              <p className="sell-info__value base-14 text-gray">
                От застройщика
              </p>
            </div>
          </div>
        </div>
      </Link>
    </article>
  );
};

export default Card;
