import { accountLinks } from "../../TabsLayout/links";
import Button from "../../../ui/Button/Button";
import "./DropdownMenu.scss";
import { Link } from "react-router-dom";
import { ArrowSvg } from "../Icons";
import { useEffect, useRef, useState } from "react";
import Dropdown2 from "../../../ui/Dropdown2/Dropdown";
import { useUserStore } from "../../../../store";
import useClickOutside from "../../../../hooks/useClickOutside";
import useRole from "../../../../hooks/useRole";
import useLogout from "../../../../hooks/useLogout";

const DropdownMenu = () => {
  const {
    ref: menuRef,
    isOpened: menuIsOpened,
    setIsOpened: setMenuIsOpened,
  } = useClickOutside();

  const logout = useLogout();
  const { isAdmin } = useRole();
  return (
    <div
      className="user-menu__dropdown"
      ref={menuRef}
      style={{ position: "relative" }}
    >
      <button
        type="button"
        onClick={() => setMenuIsOpened(!menuIsOpened)}
        style={menuIsOpened ? {} : { transform: "rotate(180deg)" }}
      >
        <ArrowSvg />
      </button>
      <Dropdown2 isShown={menuIsOpened}>
        <div className="dropdown-menu">
          <ul>
            {isAdmin && (
              <li>
                <Link to={`admin/developers`}>
                  <b style={{ color: "black" }}>{"Админка"}</b>
                </Link>
              </li>
            )}

            {accountLinks.map((link) => (
              <li key={link.link}>
                <Link to={`user/${link.link}`}>{link.label}</Link>
              </li>
            ))}

            <li>
              <Button
                isClean
                onClick={() => {
                  logout();
                }}
              >
                Выйти
              </Button>
            </li>
          </ul>
        </div>
      </Dropdown2>
    </div>
  );
};

export default DropdownMenu;
