import { useLocation } from "react-router-dom";
import { useMemo } from "react";
const usePathId = () => {
  const pathname = useLocation().pathname;
  const id = useMemo(
    () =>
      pathname.slice(Array.from(pathname).findLastIndex((c) => c === "/") + 1),
    [pathname]
  );
  return id;
};

export default usePathId;
