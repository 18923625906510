import { useForm, FormProvider } from "react-hook-form";
import { addPool } from "../../../../api";
import Button from "../../../../components/ui/Button/Button";

import { useMutation, useQueryClient } from "react-query";

import PoolDescription from "../../../../components/forms/PolymorhicForm/PoolDescription";
import ErrorMessage from "../../../../components/ui/ErrorMessage/ErrorMessage";

const AddPoolForm = ({ chosenBuilding = null, closeModal }) => {
  const methods = useForm({
    defaultValues: {},
  });
  const queryClient = useQueryClient();

  const addPoolMutation = useMutation(
    {
      mutationFn: (body) => addPool(body),
    },
    {
      onSuccess: closeModal,
    }
  );

  const onSubmit = (data) => {
    // console.log(data);
    // return;
    addPoolMutation.mutate(
      {
        ...data,
        exitPrice: +data.exitPrice,
        percentOfIncome: +data.percentOfIncome,
        emitterId: data.emitterId.value,
        flatsList:
          data.flatsList.length > 0
            ? data.flatsList.map((flat) => flat.value)
            : [],
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("pools");
          closeModal();
        },
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <form className="form feedback" onSubmit={methods.handleSubmit(onSubmit)}>
        <p className="form__title base-24">Добавление пула</p>

        <PoolDescription methods={methods} chosenBuilding={chosenBuilding} />

        <Button
          type="submit"
          style={{ marginTop: 30 }}
          buttonType="primary"
          isFull
        >
          Создать пул
        </Button>

        <ErrorMessage
          forRequest={addPoolMutation}
          isHidden={addPoolMutation.isLoading}
        />
      </form>
    </FormProvider>
  );
};

export default AddPoolForm;
