import JkInfo from "../../components/content/JkInfo/JkInfo";
import JkStatus from "../../components/content/JkStatus/JkStatus";
import CardMain from "../../components/ui/Card/CardMain";
import ApartmentCard from "../../components/ui/ApartmentCard/ApartmentCard";
import { Loader } from "../../components/ui/Loader/Loader";
import SectionTitle from "../../components/ui/SectionTitle/SectionTitle";
import ComparisonBlock from "../..//components/ui/ComparisonBlock/ComparisonBlock";
import { useQuery } from "react-query";
import { usePool } from "../../hooks/fetchers/pools";
import ConnectedPools from "./ConnectedPools";
import { getPoolByBuildingId } from "../../api";
import Banner from "../../components/ui/Banner/Banner";
import "./index.scss";
import useReleasesByPoolId from "../../hooks/fetchers/releases/useReleasesByPoolId";
import TokenPriceHistoryTable from "../../components/ui/Table/TokenPriceHistoryTable";
import { getActiveReleases, getOldestActiveRelease } from "../../utils";

const JkPage = () => {
  const { data: pool, isLoading } = usePool();
  const { data: jkPools, isLoading: isPoolsLoading } = useQuery({
    queryFn: () => getPoolByBuildingId(pool.building.id),
    queryKey: "pools",
    enabled: !!pool && !!pool.building,
  });

  const { data: releasesInPool, isLoading: isReleaseLoading } =
    useReleasesByPoolId(pool?.id);

  if (isLoading || isPoolsLoading || isReleaseLoading)
    return <Loader isBlue isLarge />;
  if (!pool) return null;
  const FLATS_TO_SHOW_LIMIT = 6;

  return (
    <section className="home jk">
      <div className="jk__title title">
        <span>{pool.building?.name}</span>
        <span className="jk__sub">{pool.building?.developer?.name}</span>
      </div>
      {
        // ??? если нет активного? Фильтрануть проверку что есть активный релиз
      }
      {releasesInPool && (
        <div className="home__slider">
          <CardMain
            releases={getActiveReleases(releasesInPool)}
            release={getOldestActiveRelease(releasesInPool)}
            buyTokens
          />
        </div>
      )}
      <div className="jk__info-grid">
        <JkInfo data={pool} />

        <div className="jk__info-tokens">
          {/* <TimerBlock data={pool} isLarge /> */}
          {
            // ??? если нет активного? Фильтрануть проверку что есть активный релиз
          }

          {releasesInPool && (
            <ComparisonBlock
              data={getOldestActiveRelease(releasesInPool)}
              repaymentMethod={pool.repaymentMethod}
            />
          )}
        </div>
      </div>
      <SectionTitle>Список токенизированых квартир</SectionTitle>
      <div className="jk__grid">
        {pool.flatsList.map((flat) => (
          <ApartmentCard data={flat} key={`jk-flat-${flat.id}`} />
        ))}
      </div>
      {/* {release.flatsList.length > FLATS_TO_SHOW_LIMIT && <ShowmoreBtn /> */}
      {pool.exchangeTokensOnFlat && (
        <Banner addClass="jk__banner" withLink={pool.externalPromoLink} />
      )}
      <JkStatus buildingId={pool?.building?.id} />
      <section className="history">
        <SectionTitle>История изменения цены токена</SectionTitle>
        {releasesInPool && <TokenPriceHistoryTable data={releasesInPool} />}
      </section>
      {jkPools && <ConnectedPools pools={jkPools} />}
    </section>
  );
};

export default JkPage;
