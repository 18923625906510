import Flexbox from "../../../components/ui/Flexbox/Flexbox";
import AdminTable from "../AdminTable/AdminTable";
import { formatOrdersInfo } from "../AdminTable/AdminTableFormaters";
import AddBtn from "../AddButton";
import Tabs from "../../../components/ui/Tabs/Tabs";
import {
  useOrdersForInvestor,
  useOrdersForIssuer,
  useOrdersPage,
} from "../../../hooks/fetchers/orders";
import { Loader } from "../../../components/ui/Loader/Loader";

const Orders = () => {
  // const { data: ordersInvestor, isLoading: isOrdersForInvestorLoading } =
  //   useOrdersForInvestor();

  // const { data: ordersIssuer, isLoading: isOrdersForIssuerLoading } =
  //   useOrdersForIssuer();

  const { data: orders, isLoading } = useOrdersPage();

  if (isLoading) return <Loader isBlue isLarge />;
  return (
    <Flexbox flexDirection="column" fitContent>
      {orders && orders?.length > 0 ? (
        <>
          <AdminTable data={formatOrdersInfo(orders)} />
          {/* <AddBtn>Показать ещё</AddBtn> */}
        </>
      ) : (
        <p style={{ marginTop: 15 }}>Нет сделок</p>
      )}
    </Flexbox>

    // <Tabs underlineView size="14" addClass="admin-user__tabs">
    //   <div label="Заявки на покупку">
    //     <div>

    //     </div>
    //   </div>
    //   <div label="Заявки на продажу">
    //     <div>
    //       <Flexbox flexDirection="column" fitContent>
    //         {ordersIssuer && ordersIssuer?.length > 0 ? (
    //           <>
    //             <AdminTable data={formatOrdersInfo(ordersIssuer)} />
    //             {/* <AddBtn>Показать ещё</AddBtn> */}
    //           </>
    //         ) : (
    //           <p style={{ marginTop: 15 }}>Нет сделок</p>
    //         )}
    //       </Flexbox>
    //     </div>
    //   </div>
    // </Tabs>
  );
};
export default Orders;
