import mocImg from "../../../assets/img/card-main.jpg";
import "./index.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import { ArrowIcon } from "./ArrowIcon";
import "swiper/scss";

const SwiperButtonPrev = ({ children }) => {
  const swiper = useSwiper();
  return (
    <button
      className="slider__nav-btn slider__prev"
      type="button"
      onClick={() => swiper.slidePrev()}
    >
      {children}
    </button>
  );
};

const SwiperButtonNext = ({ children }) => {
  const swiper = useSwiper();
  return (
    <button
      className="slider__nav-btn slider__next"
      type="button"
      onClick={() => swiper.slideNext()}
    >
      {children}
    </button>
  );
};

const Slider = ({ photos }) => {
  return (
    <div className="slider">
      <Swiper slidesPerView={1}>
        {photos &&
          photos.length > 0 &&
          photos.map((photo) => (
            <SwiperSlide key={`photo-slider-${photo}`}>
              <img className="card__img" src={photo} alt="" />
            </SwiperSlide>
          ))}

        {/* <SwiperSlide>
          <img className="card__img" src={mocImg} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="card__img" src={mocImg} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="card__img" src={mocImg} alt="" />
        </SwiperSlide> */}
        {photos.length > 1 && (
          <div className="slider__nav">
            <SwiperButtonPrev>
              <ArrowIcon />
            </SwiperButtonPrev>
            <SwiperButtonNext>
              <ArrowIcon />
            </SwiperButtonNext>
          </div>
        )}
      </Swiper>
    </div>
  );
};

export default Slider;
