import OrderPrice from "./OrderPrice";
import OrderHistory from "./OrderHistory";
import { formatId } from "../../../utils";
const OrderPurchase = ({ order }) => {
  if (!order) return null;
  return (
    <div className="form edit-form">
      <p className="h8 form__title">Заявка на покупку {formatId(order.id)}</p>
      <OrderPrice order={order} />
      <OrderHistory order={order} />
    </div>
  );
};
export default OrderPurchase;
