import { useQuery } from "react-query";
import { getUserCryptoProfile } from "../../../api";
import useIsLoggedIn from "../../useIsLoggedIn";

const useUserCryptoProfile = () => {
  const isLoggedIn = useIsLoggedIn();
  return useQuery({
    queryKey: ["cryptoProfile"],
    queryFn: getUserCryptoProfile,
    enabled: isLoggedIn,
  });
};

export default useUserCryptoProfile;
