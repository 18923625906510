import CloseButton from "../../../components/ui/CloseButton/CloseButton";

import AddEmitterForm from "../forms/AddForm/AddEmitterForm";
const AddEmitterModal = ({ closeModal }) => {
  return (
    <div className="modal">
      <CloseButton addClass="modal__close-btn" onClick={closeModal} />
      <span className="modal__title">Добавить эмитента</span>
      <AddEmitterForm closeModal={closeModal} />
    </div>
  );
};

export default AddEmitterModal;
