import { useForm, FormProvider } from "react-hook-form";
import {
  addBuilding,
  addFiles,
  addImagesToBuilding,
  getAllDevelopers,
} from "../../../../api";
import Button from "../../../../components/ui/Button/Button";
import Select from "../../../../components/ui/Select/Select";

import { useQueryClient, useMutation, useQuery, useQueries } from "react-query";
import { Loader } from "../../../../components/ui/Loader/Loader";
import { BuildingDescription } from "../../../../components/forms/PolymorhicForm/BuildingDescription";
import Dropzone from "../../../../components/ui/Dropzone/Dropzone";
import { getFilesFormData } from "../../../../utils";
import ErrorMessage from "../../../../components/ui/ErrorMessage/ErrorMessage";
import {useEffect, useState} from "react";
export const buildingStatusOptions = [
  { label: "Старт строительства", value: "START_BUILDING" },
  { label: "Процент готовности", value: "PERCENT_BUILDING" },
  { label: "Введение в эксплуатацию", value: "COMMISSIONING" },
  { label: "Выставление на продажу", value: "FOR_SALE" },
  { label: "Произведение выплат", value: "PAYMENTS" },
];

export const yearsOptions = [
  { label: "2024 год", value: "2024" },
  { label: "2025 год", value: "2025" },
  { label: "2026 год", value: "2026" },
  { label: "2027 год", value: "2027" },
  { label: "2028 год", value: "2028" },
];

export const quarterOptions = [
  { label: "I Квартал", value: "1" },
  { label: "II Квартал", value: "2" },
  { label: "III Квартал", value: "3" },
  { label: "IV Квартал", value: "4" },
];

/**
 * @param {{nextStep : () => unknown, chosenDeveloper : string, setChosenDeveloper : (string) => unknown}}
 * @returns {import("react").ReactNode}
 */
export function AddBuildingFormStep1({
  nextStep,
  chosenDeveloper,
  setChosenDeveloper,
}) {
  const { data: developers, isSuccess } = useQuery({
    queryKey: ["developers"],
    queryFn: getAllDevelopers,
  });

  if (!isSuccess) return <Loader isBlue isLarge />;
  return (
    <>
      <span className="modal__title">Выберите застройщика</span>
      <Select
        withLabel="Выберите застройщика для добавления ЖК"
        options={developers.map((developer) => ({
          label: developer.name,
          value: developer.id,
        }))}
        defaultOption={"Выберите застройщика"}
        onChangeValue={setChosenDeveloper}
        whiteType
      />
      <Button
        disabled={!chosenDeveloper}
        onClick={() => nextStep()}
        style={{ marginTop: 30 }}
        isFull
      >
        Далее
      </Button>
    </>
  );
}

/**
 * @param {{nextStep : () => unknown, setBuildingInfo : () => unknown}}
 * @returns {import("react").ReactNode}
 */
export function AddBuildingFormStep2({ nextStep, setBuildingInfo }) {
  const methods = useForm({
    defaultValues: {
      status: "START_BUILDING",
      startBuildingYear: "2024",
      startBuildingQuarter: "1",
      plannedDeliveryYear: "2024",
      plannedDeliveryQuarter: "1",
      percentBuilding: 0,
    },
  });

  const status = methods.watch("status");

  return (
    <FormProvider {...methods}>
      <form
        className="form feedback"
        onSubmit={methods.handleSubmit(
          ({
            percentBuilding,
            plannedDeliveryQuarter,
            plannedDeliveryYear,
            startBuildingQuarter,
            startBuildingYear,
            ...data
          }) => {
            setBuildingInfo({
              ...data,
              ...(data.status === "PERCENT_BUILDING"
                ? { percentBuilding: percentBuilding }
                : {}),
              ...(data.status === "START_BUILDING"
                ? { startBuildingQuarter, startBuildingYear }
                : {}),
              ...(data.status === "PERCENT_BUILDING" ||
              data.status === "START_BUILDING"
                ? {
                    plannedDeliveryQuarter,
                    plannedDeliveryYear,
                  }
                : {}),
            });
            nextStep();
          }
        )}
      >
        <p className="form__title base-24">Описание ЖК</p>

        <BuildingDescription status={status} />

        <Button
          type="submit"
          style={{ marginTop: 30 }}
          buttonType="primary"
          isFull
        >
          Далее
        </Button>
      </form>
    </FormProvider>
  );
}
/**
 * @param {{close : () => unknown, developerId : string, buildingInfo : object }}
 * @returns {import("react").ReactNode}
 */
export function AddBuildingFormStep3({
  developerId,
  buildingInfo,
  closeModal,
}) {
  const queryClient = useQueryClient();

  const postBuilding = useMutation({
    mutationFn: addBuilding,
    onSuccess: () => {
      queryClient.invalidateQueries("buildings");
      closeModal();
    },
  });
  const postImages = useMutation({ mutationFn: addImagesToBuilding });

  const methods = useForm();

  const addFilesMutation = useMutation({ mutationFn: addFiles });

  const isLoading =
    postBuilding.isLoading ||
    postImages.isLoading ||
    addFilesMutation.isLoading;
  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data) => {
          const promisesFilesGroup = data.files.map(async (file) => {
            return await addFilesMutation.mutateAsync(getFilesFormData([file]))
          });
          Promise.all(promisesFilesGroup).then((data) => {
            postBuilding.mutate(
              {
                developerId,
                imagesIds: data && data.length > 0 ? data.flat() : [],
                ...buildingInfo,
              },
              {
                onSuccess: closeModal,
              });
            }).catch(error => {
              console.log(error)
            })
          })}
        >
          <p className="form__title base-24">Фотографии ЖК</p>
      
          <Dropzone
            accept={{
              "image/*": [".jpeg", ".jpg", ".png"],
            }}
            name="files"
            label="Загрузите фотографии ЖК"
          />

          <Button
            type={"submit"}
            style={{ marginTop: 30 }}
            buttonType="primary"
            isFull
            disabled={isLoading}
            isLoading={isLoading}
          >
            Добавить ЖК
          </Button>
          <ErrorMessage
            forRequest={postBuilding}
            isHidden={postBuilding.isLoading}
          />
        </form>
      </FormProvider>
    </>
  );
}
