import "./assets/styles/index.scss";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";

import Markup from "./pages/Markup/Markup";
import Layout from "./components/layout/Layout";
import HomePage from "./pages/HomePage/HomePage";
import JkPage from "./pages/JkPage/JkPage";
import TabsLayout from "./components/layout/TabsLayout/TabsLayout";

import Exchange from "./components/content/Account/Exchange/Exchange";
import MyTokens from "./components/content/Account/MyTokens/MyTokens";
import MySells from "./components/content/Account/MySells/MySells";
import Favorites from "./components/content/Account/Favorites/Favorites";
import Notifications from "./components/content/Account/Notifications/Notifications";
import {
  accountLinks,
  adminLinks,
  settingsLinks,
} from "./components/layout/TabsLayout/links";
import PersonalInfo from "./components/content/Settings/PersonalInfo/PersonalInfo";
import SettingsNotifications from "./components/content/Settings/Notifications/Notifications";
import Support from "./components/content/Settings/Support/Support";
import Requisites from "./components/content/Settings/Requisites/Requisites";
import ErrorBoundary from "./pages/ErrorBoundary";

import AdminUsers from "./admin/components/AdminTabs/Users";
import AdminUser from "./admin/components/User/User";

import AdminEmitters from "./admin/components/AdminTabs/Emitters";
import AdminDevelopers from "./admin/components/AdminTabs/Developers";
import AdminBuildings from "./admin/components/AdminTabs/Buildings";
import AdminFlats from "./admin/components/AdminTabs/Flats";
import AdminPools from "./admin/components/AdminTabs/Pools";
import AdminReleases from "./admin/components/AdminTabs/Releases";
import AdminOrders from "./admin/components/AdminTabs/Orders";

import AdminEmitter from "./admin/components/Emitter/Emitter";
import AdminDeveloper from "./admin/components/Developer/Developer";
import AdminBuilding from "./admin/components/Building/Building";
import AdminFlat from "./admin/components/Flat/Flat";
import AdminPool from "./admin/components/Pool/Pool";
import AdminRelease from "./admin/components/Release/Release";
import AdminOrder from "./admin/components/Order/Order";

import AboutPage from "./pages/AboutPage";
import FaqPage from "./pages/FaqPage";

import ProtectedRoute from "./components/ProtectedRoute";
import AdminRoute from "./components/AdminRoute";
import { config } from "./config";

let router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />} errorElement={<ErrorBoundary />}>
        <Route index element={<HomePage />} />
        <Route path="/:id" element={<JkPage />} />
        <Route element={<ProtectedRoute />}>
          <Route
            path="user"
            element={<TabsLayout title={"Мой метр"} links={accountLinks} />}
          >
            <Route index path="tokens" element={<MyTokens />} />
            <Route path="favorites" element={<Favorites />} />

            {config.dev_mode && (
              <>
                <Route path="exchange" element={<Exchange />} />
                <Route path="sells" element={<MySells />} />
                <Route path="history" element={<>История</>} />
                <Route path="notifications" element={<Notifications />} />
              </>
            )}
          </Route>

          <Route element={<AdminRoute />}>
            {" "}
            <Route
              path="admin"
              element={
                <TabsLayout
                  title={"Кабинет администратора"}
                  links={adminLinks}
                  style={{ maxWidth: "100%" }}
                />
              }
            >
              <Route path="users" element={<AdminUsers />} />

              <Route path="emitters" element={<AdminEmitters />} />
              <Route path="developers" element={<AdminDevelopers />} />
              <Route path="buildings" element={<AdminBuildings />} />
              <Route path="flats" element={<AdminFlats />} />
              <Route path="pools" element={<AdminPools />} />
              <Route path="releases" element={<AdminReleases />} />
              <Route path="orders" element={<AdminOrders />} />

              <Route path="users/:id" element={<AdminUser />} />
              <Route path="emitters/:id" element={<AdminEmitter />} />
              <Route path="developers/:id" element={<AdminDeveloper />} />
              <Route path="buildings/:id" element={<AdminBuilding />} />
              <Route path="flats/:id" element={<AdminFlat />} />
              <Route path="pools/:id" element={<AdminPool />} />
              <Route path="releases/:id" element={<AdminRelease />} />
              <Route path="orders/:id" element={<AdminOrder />} />
            </Route>
          </Route>

          <Route
            path="settings"
            element={<TabsLayout title={"Настройки"} links={settingsLinks} />}
          >
            <Route index path="personal" element={<PersonalInfo />} />
            <Route path="notifications" element={<SettingsNotifications />} />
            <Route path="support" element={<Support />} />
            <Route path="requisites" element={<Requisites />} />
          </Route>

          <Route
            path="admin"
            element={
              <TabsLayout
                title={"Кабинет администратора"}
                links={accountLinks}
              />
            }
          >
            <Route index path="exchange" element={<Exchange />} />
            <Route path="tokens" element={<MyTokens />} />
            <Route path="sells" element={<MySells />} />
            <Route path="history" element={<>История</>} />
            <Route path="favorites" element={<Favorites />} />
            <Route path="notifications" element={<Notifications />} />
          </Route>

          <Route path="markup" element={<Markup />} />
        </Route>
      </Route>

      <Route
        path="/"
        element={<Layout isReadingPage />}
        errorElement={<ErrorBoundary />}
      >
        <Route path="about" element={<AboutPage />} />
        <Route path="faq" element={<FaqPage />} />
      </Route>
    </>
  )
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>
);

export default App;
