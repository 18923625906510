import { useQueryClient, useMutation } from "react-query";
import { HistoryItem } from "../../../../components/ui/History/History";
import Button from "../../../../components/ui/Button/Button";

import { useOrderPaymentDocument } from "../../../../hooks/fetchers/orders";
import {
  acceptOrderMutation,
  rejectOrderPaymentMutation,
} from "../../../../api";
import { useParams } from "react-router-dom";
import { downloadFile } from "../../../../utils";
import { config } from "../../../../config";

const DownloadTicketButton = ({ ticketLink = null, isTicketAvailable }) => {
  const { refetch } = useOrderPaymentDocument();

  return (
    <Button
      buttonType={isTicketAvailable ? "primary" : "secondary"}
      disabled={!isTicketAvailable}
      size="s"
      style={{ marginTop: 10 }}
      onClick={() => {
        refetch().then((resp) => {
          if (resp && resp.data) {
            downloadFile(new Blob([resp.data]), "payment-document.pdf");
          }
        });
      }}
    >
      {isTicketAvailable ? "Квитанция" : "Ожидание квитанции"}
    </Button>
  );
};

const ConfirmTicketButtons = ({ status, isTicketAvailable = false }) => {
  const queryClient = useQueryClient();
  const { id } = useParams();

  const {
    mutate: acceptOrder,
    isLoading: acceptOrderLoading,
    isSuccess: acceptOrderSuccess,
  } = useMutation({
    mutationFn: () => acceptOrderMutation(id),
    enabled: !!id,
    onSuccess: () => {
      queryClient.invalidateQueries("orders");
    },
  });

  const {
    mutate: rejectOrderPayment,
    isLoading: rejectOrderLoading,
    isSuccess: rejectOrderSuccess,
  } = useMutation({
    mutationFn: rejectOrderPaymentMutation,
    onSuccess: () => {
      queryClient.invalidateQueries("orders");
    },
  });

  const isSuccess = acceptOrderSuccess || rejectOrderSuccess;

  return isTicketAvailable &&
    status === "WAITING_FOR_CONFIRMATION" &&
    !isSuccess ? (
    <div style={{ display: "flex", marginTop: 10, gap: 10 }}>
      <Button size="s" onClick={acceptOrder} isLoading={acceptOrderLoading}>
        Подтвердить
      </Button>
      {config.dev_mode && (
        <Button
          size="s"
          onClick={rejectOrderPayment}
          isLoading={rejectOrderLoading}
        >
          Отклонить
        </Button>
      )}
    </div>
  ) : null;
};

export const WaitingTicket = ({ order }) => {
  const { status } = order;
  const mockTicketLink = null;

  return (
    <>
      <HistoryItem
        title={"Заявка создана"}
        descr={"11 октября 2023, 11:00:01"}
        status={"checked"}
      />
      <HistoryItem
        title={"Оплата"}
        descr={"11 октября 2023, 11:00:01"}
        status={
          status === "PAYMENT_CONFIRMED" ||
          status === "WAITING_ISSUE" ||
          status === "CLOSED"
            ? "checked"
            : "checkpoint"
        }
      />
      <HistoryItem
        title={"Проверка оплаты"}
        status={
          status === "PAYMENT_CONFIRMED" ||
          status === "WAITING_ISSUE" ||
          status === "CLOSED"
            ? "checked"
            : "checkpoint"
        }
        renderElement={() => (
          <DownloadTicketButton
            isTicketAvailable={
              order.status !== "WAITING_FOR_PAYMENT" &&
              order.paymentDocumentId !== null
            }
          />
        )}
      />
      <HistoryItem
        title={
          status === "XXXXXX"
            ? "Оплата отклонена эмитентом"
            : "Оплата подтверждена эмитентом"
        }
        isBeforeNextpoint={status === "WAITING_FOR_PAYMENT"}
        status={
          status === "WAITING_FOR_PAYMENT"
            ? "nextpoint"
            : status === "PAYMENT_CONFIRMED" ||
              status === "WAITING_ISSUE" ||
              status === "CLOSED"
            ? "checked"
            : "checkpoint"
        }
        renderElement={() => (
          <ConfirmTicketButtons
            status={order.status}
            isTicketAvailable={order.paymentDocumentId}
          />
        )}
      />
    </>
  );
};
