import AdminTable from "../AdminTable/AdminTable";
import SearchInput from "../SearchInput/SearchInput";
import AddBtn from "../AddButton";
import Flexbox from "../../../components/ui/Flexbox/Flexbox";
import { getAllReleases } from "../../../api";
import useModal from "../../../hooks/useModal";
import Modal from "react-modal";
import { useQuery } from "react-query";
import { Loader } from "../../../components/ui/Loader/Loader";
import AddReleaseModal from "../AddModal/AddReleaseModal";
import { formatReleasesInfo } from "../AdminTable/AdminTableFormaters";

const Releases = () => {
  const [modalIsOpen, openModal, closeModal] = useModal();

  const { data: releases, isLoading } = useQuery({
    queryKey: ["releases"],
    queryFn: getAllReleases,
  });

  if (isLoading) {
    return <Loader isLarge isBlue />;
  }

  return (
    <>
      {releases && releases.length > 0 && <SearchInput />}

      <Flexbox flexDirection="column" fitContent>
        {releases && releases.length > 0 ? (
          <AdminTable data={formatReleasesInfo(releases)} />
        ) : (
          <p style={{ marginTop: 15 }}>Нет выпусков</p>
        )}

        <AddBtn onClick={openModal}>Добавить выпуск</AddBtn>
      </Flexbox>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <AddReleaseModal closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default Releases;
