import React from "react";

const CheckIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        background: "#3399CC",
        borderRadius: "50%",
      }}
    >
      <path
        d="M8 14.2L10.8571 17L18 10"
        stroke="white"
        strokeWidth="1.17063"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CheckpointRedIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        background: "#fff",
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 25.8671C20.3302 25.8671 25.8671 20.3302 25.8671 13.5C25.8671 6.66982 20.3302 1.13287 13.5 1.13287C6.66982 1.13287 1.13287 6.66982 1.13287 13.5C1.13287 20.3302 6.66982 25.8671 13.5 25.8671ZM13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04416 20.9558 0 13.5 0C6.04416 0 0 6.04416 0 13.5C0 20.9558 6.04416 27 13.5 27Z"
        fill="#FF6E8C"
      />
      <path
        d="M18.0849 13.4999C18.0849 16.0321 16.0321 18.0849 13.4999 18.0849C10.9678 18.0849 8.91504 16.0321 8.91504 13.4999C8.91504 10.9678 10.9678 8.91504 13.4999 8.91504C16.0321 8.91504 18.0849 10.9678 18.0849 13.4999Z"
        fill="#FF6E8C"
      />
    </svg>
  );
};

const CheckpointBlueIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        background: "#fff",
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 25.8671C20.3302 25.8671 25.8671 20.3302 25.8671 13.5C25.8671 6.66982 20.3302 1.13287 13.5 1.13287C6.66982 1.13287 1.13287 6.66982 1.13287 13.5C1.13287 20.3302 6.66982 25.8671 13.5 25.8671ZM13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04416 20.9558 0 13.5 0C6.04416 0 0 6.04416 0 13.5C0 20.9558 6.04416 27 13.5 27Z"
        fill="#3399CC"
      />
      <path
        d="M18.0849 13.4999C18.0849 16.0321 16.0321 18.0849 13.4999 18.0849C10.9678 18.0849 8.91504 16.0321 8.91504 13.4999C8.91504 10.9678 10.9678 8.91504 13.4999 8.91504C16.0321 8.91504 18.0849 10.9678 18.0849 13.4999Z"
        fill="#3399CC"
      />
    </svg>
  );
};

const CheckpointGrayIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        background: "#fff",
      }}
    >
      <rect width="27" height="27" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 25.8671C20.3302 25.8671 25.8671 20.3302 25.8671 13.5C25.8671 6.66982 20.3302 1.13287 13.5 1.13287C6.66982 1.13287 1.13287 6.66982 1.13287 13.5C1.13287 20.3302 6.66982 25.8671 13.5 25.8671ZM13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04416 20.9558 0 13.5 0C6.04416 0 0 6.04416 0 13.5C0 20.9558 6.04416 27 13.5 27Z"
        fill="#CACACA"
      />
      <path
        d="M18.0849 13.4999C18.0849 16.0321 16.0321 18.0849 13.4999 18.0849C10.9678 18.0849 8.91504 16.0321 8.91504 13.4999C8.91504 10.9678 10.9678 8.91504 13.4999 8.91504C16.0321 8.91504 18.0849 10.9678 18.0849 13.4999Z"
        fill="#CACACA"
      />
    </svg>
  );
};

export { CheckIcon, CheckpointRedIcon, CheckpointBlueIcon, CheckpointGrayIcon };
