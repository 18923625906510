import React from "react";
import { BuildingStatuses } from "../../../constants";
import Label from "../Label/Label";

const StatusLabel = ({ data }) => {
  if (!data.building) return null;

  switch (data.building.status) {
    case "START_BUILDING":
      return (
        <Label labelType="secondary">
          {`${BuildingStatuses[data.building.status]}: ${
            data.building.plannedDeliveryQuarter
          } квартал ${data.building.plannedDeliveryYear} года`}
        </Label>
      );

    case "PERCENT_BUILDING":
      return (
        <Label labelType="secondary">
          {`Построено: ${data.building.percentBuilding}%`}
        </Label>
      );
    default:
      return (
        <Label labelType="secondary">
          {BuildingStatuses[data.building.status]}
        </Label>
      );
  }
};

export default StatusLabel;
