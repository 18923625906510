import { HistoryItem } from "../../../../components/ui/History/History";

export const WaitingOis = ({ order }) => {
  const { status } = order;

  return (
    <>
      <HistoryItem
        title={"Заявка подтверждена ОИС"}
        descr={"11 октября 2023, 11:00:01"}
        status={
          status === "WAITING_ISSUE" || status === "CLOSED" ? "checked" : "nextpoint"
        }
      />
      <HistoryItem
        title={"Выпуск состоялся, ЦФА начислены"}
        // descr={"11 октября 2023, 11:00:01"}
        status={status === "CLOSED" ? "checked" : "nextpoint"}
      />
    </>
  );
};
