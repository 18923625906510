export const ArrowIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.5C0 2.91015 2.91015 4.76837e-07 6.5 4.76837e-07C10.0899 4.76837e-07 13 2.91015 13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5ZM1.3 6.5C1.3 9.37188 3.62812 11.7 6.5 11.7C9.37188 11.7 11.7 9.37188 11.7 6.5C11.7 3.62812 9.37188 1.3 6.5 1.3C3.62812 1.3 1.3 3.62812 1.3 6.5ZM6.30962 8.90962C6.05578 9.16346 5.64422 9.16346 5.39038 8.90962C5.13654 8.65578 5.13654 8.24422 5.39038 7.99038L6.88076 6.5L5.39038 5.00962C5.13654 4.75578 5.13654 4.34422 5.39038 4.09038C5.64422 3.83654 6.05578 3.83654 6.30962 4.09038L8.25962 6.04038C8.51346 6.29422 8.51346 6.70578 8.25962 6.95962L6.30962 8.90962Z"
      fill="currentColor"
    />
  </svg>
);
