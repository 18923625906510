import classNames from "classnames";
import "./index.scss";

const Container = ({ isReading = false, children }) => {
  const containerClass = classNames({
    container: true,
    "container_reading-page": isReading,
  });

  return <div className={containerClass}>{children}</div>;
};

export default Container;
