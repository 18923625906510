import SectionTitle from "../../ui/SectionTitle/SectionTitle";

import { NavLink, Outlet } from "react-router-dom";

import "./TabsLayout.scss";

const TabsLayout = ({ title, links, ...props }) => {
  return (
    <section className="tabs-layout" {...props}>
      {title && <SectionTitle isTitle>{title}</SectionTitle>}

      <ul className="tabs-layout__nav">
        {links.map((it) => (
          <li className="tabs-layout__item" key={it.link}>
            <NavLink
              to={it.link}
              className={({ isActive }) =>
                `btn btn_size_m ${
                  isActive ? "btn_primary" : "btn_secondary-gray"
                }`
              }
            >
              {it.label}
            </NavLink>
          </li>
        ))}
      </ul>
      <Outlet />
    </section>
  );
};

export default TabsLayout;
