import { useState, useEffect } from "react";
import SignItem from "./SignItem";
import Button from "../Button/Button";
import ComissionBlock from "./ComissionBlock";
import { useMutation } from "react-query";
import { useProspectusById } from "../../../hooks/fetchers/orders";
import useUserCryptoProfile from "../../../hooks/fetchers/user/useUserCryptoProfile";

import { signOrderByIdMutation, signCertMutation } from "../../../api";
import { Loader } from "../Loader/Loader";

const SignForm = ({ releases, afterSubmit, onProspectusError, order }) => {
  // ToDo - переделать под несколько релизов - order-ов должно быть много, логику перенести в SignItem
  const [signedArray, setSignedArray] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const { data: cryptoProfile, isSuccess: isCryptoProfileSuccess } =
    useUserCryptoProfile();

  // Пункт 2 - получаем проспект файл
  const {
    data: prospectusFile,
    isSuccess: isProspectusSuccess,
    isLoading: isProspectusLoading,
  } = useProspectusById(order.prospectusId, onProspectusError);

  // Пункт 3 - Подписываем /cert, получаем файл подписи
  const [signFile, setSignFile] = useState(null);

  const { mutate: signCert, isLoading: isSignCertLoading } = useMutation({
    mutationFn: (body) => {
      return signCertMutation(body.thumbprint, body.file);
    },
    onSuccess: (data) => {
      setErrorMessage("");

      setSignFile(data);
    },
    onError: (data) => {
      setErrorMessage(data.message);
    },
  });

  useEffect(() => {
    if (isProspectusSuccess && isCryptoProfileSuccess && signCert) {
      const formData = new FormData();
      const file = new File(
        [JSON.stringify(prospectusFile)],
        `prospectus-${order.prospectusId}.json`,
        {
          encoding: "UTF-8",
        }
      );
      formData.append("file", file);

      signCert({
        thumbprint: cryptoProfile
          ? cryptoProfile[cryptoProfile.length - 1]?.Thumbprint
          : null, // В августе добавить проверку даты notAfter
        file: formData,
      });
    }
  }, [isProspectusSuccess, isCryptoProfileSuccess]);

  // Пункт 4 - Отправка файла подписи
  const { mutate: onSignOrder, isSuccess: isSignSuccess } = useMutation({
    mutationFn: () => {
      const file = new File(
        [signFile],
        `prospectus-${order.prospectusId}.json.p7s`,
        {
          encoding: "UTF-8",
        }
      );
      const formularData = new FormData();
      formularData.append("file", file);

      return signOrderByIdMutation({
        id: order.internalId,
        file: formularData,
      });
    },
    onSuccess: () => {
      setSignedArray((prev) => [...prev, order?.release?.id]);
    },
    onError: (data) => {
      setErrorMessage(data.message);
    },
  });

  const isLoading = isProspectusLoading || isSignCertLoading;

  if (isLoading) return <Loader isBlue isLarge />;

  return (
    <div className="sign">
      <span className="sign__title">Подписание</span>
      <div className="sign__grid">
        {releases.map((release, i) =>
          release ? (
            <SignItem
              onSignOrder={onSignOrder}
              release={release}
              isSigned={isSignSuccess}
              isDefaultOpened={i === 0}
              key={`sign-item-${release.id}`}
            />
          ) : null
        )}
      </div>

      <Button
        addClass="sign__buy-btn"
        isFull
        size="m"
        disabled={!(signedArray.length === releases.length)}
        onClick={afterSubmit}
      >
        Купить токены
      </Button>

      <ComissionBlock />

      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

export default SignForm;
