import { Link } from "react-router-dom";
import {
  InfoKey,
  InfoPair,
  InfoValue,
} from "../../../components/ui/InfoPair/InfoPair";
import RoundedBlock from "../../../components/ui/RoundedBlock/RoundedBlock";
import { formatId, getFullAdress, sumFootage } from "../../../utils";
import Button from "../../../components/ui/Button/Button";

export function PoolInfo({ pool, style, ...props }) {
  if (!pool || !pool.building) return null;

  return (
    <div
      className="flexbox flexbox_direction-column"
      style={{ ...style, marginTop: "50px" }}
      {...props}
    >
      <p className="h8 form__title" style={{ marginBottom: "10px" }}>
        Информация
      </p>
      <RoundedBlock>
        {[
          ["ID Пула", formatId(pool?.id)],
          ["ID ЖК", formatId(pool?.building?.id)],
          [
            "Название ЖК",
            <Link
              className="link link_blue"
              target="_blank"
              to={`../buildings/${pool?.building?.id}`}
            >
              {pool?.building?.name}
            </Link>,
          ],
          ["Адрес ЖК", pool?.building && getFullAdress(pool?.building)],
          [
            "Застройщик",
            <Link
              className="link link_blue"
              target="_blank"
              to={`../developers/${pool?.building?.developer?.id}`}
            >
              {pool?.building?.developer?.name}
            </Link>,
          ],
          ["Общий метраж пула", sumFootage(pool?.flatsList)],
          [
            "Участие в выпусках",
            pool?.releases.map((releaseId) => (
              <Link
                className="link link_blue"
                target="_blank"
                to={`../release/${releaseId}`}
              >
                {formatId(releaseId)}
              </Link>
            )),
          ],
        ].map(([key, value], ind) => (
          <InfoPair key={`${value}-${ind}`}>
            <InfoKey>{key}</InfoKey>
            <InfoValue>{value}</InfoValue>
          </InfoPair>
        ))}
      </RoundedBlock>

      <Button isLink={`../../${pool.id}`} target="_blank">
        К странице пула на сайте
      </Button>
    </div>
  );
}
