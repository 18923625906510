import { getDeveloperById } from "../../api";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
const useDeveloper = () => {
  const { id } = useParams();

  const developer = useQuery({
    queryKey: ["developers", id],
    queryFn: () => getDeveloperById(id),
    enabled: !!id,
  });
  return developer;
};

export default useDeveloper;
