import AdminTable from "../AdminTable/AdminTable";
import SearchInput from "../SearchInput/SearchInput";
import AddBtn from "../AddButton";
import Flexbox from "../../../components/ui/Flexbox/Flexbox";
import Modal from "react-modal";
import useModal from "../../../hooks/useModal";
import AddPoolModal from "../AddModal/AddPoolModal";
import { useQuery } from "react-query";
import { getAllPools } from "../../../api";
import { Loader } from "../../../components/ui/Loader/Loader";
import { numWord, formatId, sumFootage } from "../../../utils";
const Pools = () => {
  const [modalIsOpen, openModal, closeModal] = useModal();

  const { data: pools, isLoading } = useQuery({
    queryKey: ["pools"],
    queryFn: getAllPools,
  });

  if (isLoading) {
    return <Loader isLarge isBlue />;
  }

  const getPoolsData = () => {
    if (!pools) return null;

    return {
      "ID пула": pools?.map((pool) => ({
        name: formatId(pool.id),
        link: `../pools/${pool.id}`,
      })),

      "ID ЖК": pools?.map((pool) => formatId(pool?.building?.id)),
      ЖК: pools?.map((pool) => ({
        name: pool?.building?.name,
        link: `../buildings/${pool?.building?.id}`,
      })),
      "Общий метраж": pools?.map((pool) => sumFootage(pool?.flatsList)),
      "Количество квартир в пуле": pools?.map(
        (pool) =>
          `${pool.flatsList.length} ${numWord(pool.flatsList.length, [
            "квартира",
            "квартиры",
            "квартир",
          ])}`
      ),
      "Участие в выпусках": pools?.map(
        (pool) =>
          `${pool.releases.length} ${numWord(pool.releases.length, [
            "выпуск",
            "выпуска",
            "выпусков",
          ])}`
      ),
    };
  };

  return (
    <>
      {pools && pools.length > 0 && <SearchInput />}

      <Flexbox flexDirection="column" fitContent>
        {pools.length > 0 ? (
          <AdminTable data={getPoolsData(pools)} />
        ) : (
          <p style={{ marginTop: 15 }}>Пулов еще нет</p>
        )}
        <AddBtn onClick={openModal}>Добавить пул</AddBtn>
      </Flexbox>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <AddPoolModal closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default Pools;
