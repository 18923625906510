import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { useQuery } from "react-query";
import Tabs from "../../../components/ui/Tabs/Tabs";
import SearchInput from "../SearchInput/SearchInput";
import ChangeFlat from "./ChangeFlat";
import { getFlatById } from "../../../api";
import { Loader } from "../../../components/ui/Loader/Loader";
import { FlatsOfBuilding } from "../Building/FlatsOfBuilding";
import { FlatInfo } from "./FlatInfo";
import usePathId from "../../../hooks/usePathId";

const Flat = () => {
  const id = usePathId();

  const { data, isLoading } = useQuery("flat", async () => getFlatById(id));

  if (isLoading) return <Loader isBlue isLarge />;
  if (!data || !data.building) return null;


  return (
    <div className="admin-user">
      <SearchInput disabled />
      <Tabs underlineView size="14" addClass="admin-user__tabs">
        <div label="Данные о квартире" style={{ display: "flex", gap: "10%" }}>
          <ChangeFlat flat={data} style={{ flex: "1" }} />
          <FlatInfo flat={data} style={{ flex: "1" }} />
        </div>
        <div label="Квартиры ЖК">
          <FlatsOfBuilding buildingId={data?.building?.id} />
        </div>
      </Tabs>
    </div>
  );
};

export default Flat;
