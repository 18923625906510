import classNames from "classnames";
import "./Status.scss";
const Status = ({ text = "", status = "unset" }) => {
  const statusClasses = classNames("status", {
    status: true,
    [`status_${status}`]: true,
  });
  return <div className={statusClasses}>{text}</div>;
};

export default Status;
