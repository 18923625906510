import classNames from "classnames";
import "./index.scss";
import { Link } from "react-router-dom";
import { Loader } from "../Loader/Loader";

const Button = ({
  buttonType = "primary",
  size = "default", // xs - 11, s - 12, m - 14, default - 16
  addClass = "",
  isLabel = false,
  isAnchor = false,
  isFull = false,
  isLink = false,
  isClean = false,
  type = "button",
  disabled = false,
  isLoading = false,
  className = "",
  children,
  ...arg
}) => {
  const btnClass = classNames({
    btn: true,
    [`btn_size_${size}`]: true,
    [`btn_${buttonType}`]: true,
    [`btn_label`]: isLabel,
    [`btn_full`]: isFull,
    [`btn_clean`]: isClean,
    [`${addClass}`]: addClass,
    [`${className}`]: true,
  });

  return isLabel ? (
    <span className={btnClass} {...arg}>
      {children}
    </span>
  ) : isAnchor ? (
    <a href={isAnchor} className={btnClass} {...arg}>
      {children}
    </a>
  ) : isLink ? (
    <Link to={isLink} className={btnClass} {...arg}>
      {children}
    </Link>
  ) : (
    <button type={type} className={btnClass} disabled={disabled} {...arg}>
      {isLoading ? <Loader /> : children}
    </button>
  );
};

export default Button;
