import { useState } from "react";
import Button from "../../../../components/ui/Button/Button";
import Input from "../../../../components/ui/Input/Input";
import "./EditForm.scss";
import { useForm, FormProvider } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { editEmitter } from "../../../../api";
import useEmitter from "../../../../hooks/fetchers/useEmitter";

const EmitterEditForm = ({ id, userId, isAdmin = false }) => {
  const [editMode, setEditMode] = useState(false);

  const queryClient = useQueryClient();

  const { data: currentEmitter } = useEmitter();

  const methods = useForm({
    defaultValues: currentEmitter,
  });

  const editEmitterMutation = useMutation({
    mutationFn: (body) => {
      return editEmitter(body, id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["emitters"],
      });
      // afterSubmit();
    },
  });

  const onSubmit = (data) => {
    editEmitterMutation.mutate({
      ...data,
    });
    setEditMode(false);
  };

  return (
    <FormProvider {...methods}>
      <form
        className="form edit-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <p className="h8 form__title">Описание</p>
        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="ID эмитента"
              addClass="form__input"
              size="lg"
              labelSize="small"
              disabled
              name={"id"}
            />
          </div>
        </div>
        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="ID ЦМ пользователя"
              addClass="form__input"
              size="lg"
              labelSize="small"
              disabled
              name={"userId"}
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="ID ОИС эмитента"
              addClass="form__input"
              size="lg"
              labelSize="small"
              disabled
              name={"idOis"}
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__edit">
            <div className="form__admin-btns">
              <Button
                type="button"
                buttonType="secondary"
                size={"m"}
                addClass="form__edit-btn"
                onClick={() => {}}
              >
                Удалить эмитента
              </Button>
            </div>
            {/* {!editMode ? (
              <Button
                type="button"
                buttonType="secondary"
                size={"m"}
                addClass="form__edit-btn"
                onClick={(evt) => {
                  evt.preventDefault();
                  setEditMode(true);
                }}
              >
                Редактировать
              </Button>
            ) : (
              <Button
                type="submit"
                isLoading={editEmitterMutation.isLoading}
                buttonType="primary"
                size={"m"}
                addClass="form__edit-btn"
              >
                Сохранить
              </Button>
            )} */}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default EmitterEditForm;
