import { useQuery } from "react-query";
import { getReleasesByPoolId } from "../../../api";

const useReleasesByPoolId = (poolId) => {
  return useQuery({
    queryKey: ["releases", poolId],
    queryFn: () => getReleasesByPoolId(poolId),
    enabled: !!poolId,
  });
};

export default useReleasesByPoolId;
