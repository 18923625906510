import { useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import Button from "../../ui/Button/Button";
import Input from "../../ui/Input/Input";
import EditIcon from "../../../admin/components/forms/EditForm/EditIcon.jsx";
import Modal from "react-modal";
import useModal from "../../../hooks/useModal.jsx";
import LoginModal from "../../modals/LoginModal/LoginModal.jsx";
import BanBtn from "../../../admin/components/BanBtn.jsx";
import { editUser } from "../../../api.js";
import { useForm, FormProvider } from "react-hook-form";
import { useMutation } from "react-query";
import { useUserStore } from "../../../store.js";
import DateInput from "../../ui/DateInput/DateInput.jsx";
import useUserById from "../../../hooks/fetchers/user/useUserById.jsx";
import CreateAdminButton from "./CreateAdminButton.jsx";
import { Loader } from "../../ui/Loader/Loader.jsx";

const PersonalEditForm = ({ isAdmin = false }) => {
  const [editMode, setEditMode] = useState(true);
  const [modalIsOpen, openEditPhoneModal, closeEditPhoneModal] = useModal();

  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const { data: userById, isLoading } = useUserById();

  const chosenUser = isAdmin ? userById : user;

  const methods = useForm();
  const queryClient = useQueryClient();
  const editUserMutation = useMutation({
    mutationFn: (body) => {
      return editUser(body, chosenUser?.id);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["users", chosenUser.id],
      });
      !isAdmin && setUser(data);
    },
  });

  useEffect(() => {
    chosenUser &&
      chosenUser.birthDay &&
      methods.setValue("birthDay", new Date(chosenUser.birthDay));
  }, [methods, chosenUser]);

  const onSubmit = (data) => {
    editUserMutation.mutate({
      ...data,
      password: "123",
      receiveNotificationsByEmail: true,
      newTokenNotification: true,
      changePriceNotification: true,
      changeBuildingStatusNotification: true,
      confirmationCode: "string",
      type: "PHONE",
    });
  };

  if (isLoading) return <Loader isBlue isLarge />;
  if (!chosenUser) return null;
  return (
    <FormProvider {...methods}>
      <form
        className="form edit-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="Фамилия"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              // withError={"Обязательное поле*"}
              disabled={!editMode}
              defaultValue={chosenUser.lastName}
              name={"lastName"}
            />
          </div>
          <div className="form__item">
            <Input
              withLabel="Имя"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              // withError={"Обязательное поле*"}
              disabled={!editMode}
              defaultValue={chosenUser.firstName}
              name={"firstName"}
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="Отчество"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              disabled={!editMode}
              defaultValue={chosenUser.middleName}
              name={"middleName"}
            />
          </div>
          <div className="form__item">
            <DateInput
              withLabel="Дата рождения"
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              disabled={!editMode}
              name={"birthDay"}
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__item">
            <Input
              withLabel="Email"
              withNotification="На новый адрес отправлено письмо для подтверждения."
              addClass="form__input"
              size="lg"
              labelSize="small"
              placeholder={""}
              disabled={!editMode}
              defaultValue={chosenUser.email}
              name={"email"}
            />
          </div>

          <div className="form__item">
            <div className="input-wrapper">
              <span className="input-wrapper__label text-gray body-smaller">
                Номер телефона
              </span>
              <div className="input input_lg form__input input-wrapper__edit-box">
                <span
                  className={`input-wrapper__edit-content${
                    editMode ? "" : " text-gray"
                  }`}
                  placeholder=""
                >
                  {chosenUser?.phone}
                </span>
                {editMode && (
                  <button
                    type="button"
                    className="input-wrapper__edit-btn"
                    onClick={() => openEditPhoneModal()}
                  >
                    <EditIcon />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__edit">
            {isAdmin && (
              <div className="form__admin-btns">
                {/* <BanBtn /> */}
                <CreateAdminButton />
              </div>
            )}
            {!editMode ? (
              <Button
                type="button"
                buttonType="secondary"
                size={"m"}
                onClick={() => setEditMode(true)}
                addClass="form__edit-btn"
              >
                Редактировать
              </Button>
            ) : (
              <Button
                type="submit"
                buttonType="primary"
                size={"m"}
                isLoading={editUserMutation.isLoading}
                disabled={editUserMutation.isLoading}
                // onClick={() => setEditMode(false)}
                addClass="form__edit-btn"
              >
                Сохранить
              </Button>
            )}
          </div>
        </div>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeEditPhoneModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <LoginModal closeModal={closeEditPhoneModal} changePhoneRequest />
      </Modal>
    </FormProvider>
  );
};

export default PersonalEditForm;
