import Input from "../../ui/Input/Input";
import Select from "../../ui/Select/Select";
import { Controller } from "react-hook-form";
import Dropzone from "../../ui/Dropzone/Dropzone";

/** @type {{label : string, value : import("../../../api").FlatTokenizationStatus}[]} */
export const tokenizationOptions = [
  { label: "Не токенизирована", value: "NOT_TOKENIZED" },
  { label: "Ожидает токенизации", value: "WAIT_TOKENIZATION" },
  { label: "Идет токенизация", value: "TOKENIZATION" },
  { label: "Токенизирована", value: "TOKENIZED" },
];

/** @type {{label : string, value : import("../../../api").FlatBookingStatus}[]}*/
export const bookingOptions = [
  { label: "Доступна для бронивания", value: "NOT_BOOKED" },
  { label: "Забронирована", value: "BOOKED" },
  { label: "Продана", value: "RELEASED" },
];

/**
 * @typedef {{
 *   blockNumber : string | undefined,
 *   entranceNumber : string | undefined,
 *   floor : string | undefined,
 *   number : string | undefined,
 *   footage : string | undefined,
 *   roomsCount : string | undefined,
 *   tokenizationStatus : import("../../../api").FlatTokenizationStatus | undefined,
 *   bookingStatus : import("../../../api").FlatBookingStatus | undefined,
 * }} DefaultFlatFormValues
 */

/** Component is exracted for ease of reusability in other forms
 * FlatDescription component contains a number of input fields
 * @param {{
 *  control : import("react-hook-form").Control<import("react-hook-form").FieldValues, any>
 *  defaultValue : DefaultFlatFormValues | undefined
 *  disabled : boolean | undefined
 * }}
 * @returns {import("react").ReactNode}
 */

export function FlatDescription({ control, defaultValue, disabled, planImg }) {
  return (
    <>
      <div className="form__row">
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="№ корпуса"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="blockNumber"
            defaultValue={defaultValue?.blockNumber}
            type="number"
          />
        </div>
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="№ подъезда"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="entranceNumber"
            defaultValue={defaultValue?.entranceNumber}
            type="number"
          />
        </div>
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="Этаж"
            addClass="form__input"
            size="lg"
            labelSize="small"
            name="floor"
            defaultValue={defaultValue?.floor}
            type="number"
          />
        </div>
      </div>
      <div className="form__row">
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="№ квартиры"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="number"
            defaultValue={defaultValue?.number}
            type="number"
          />
        </div>
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="Площадь м²"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="footage"
            defaultValue={defaultValue?.footage}
            type="number"
            step={0.01}
          />
        </div>
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="Количество комнат"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="roomsCount"
            defaultValue={defaultValue?.roomsCount}
            type="number"
          />
        </div>
      </div>
      <div className="form__row">
        <div className="form__item">
          <Controller
            name="tokenizationStatus"
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <Select
                {...field}
                disabled={disabled}
                options={tokenizationOptions}
                withLabel="Статус ЖК"
                whiteType
                onChangeValue={onChange}
                defaultOption={
                  tokenizationOptions.find(
                    (opt) => opt.value === defaultValue?.tokenizationStatus
                  ) ?? tokenizationOptions[0]
                }
              />
            )}
          />
        </div>
      </div>
      <div className="form__row">
        <div className="form__item">
          <Controller
            name="bookingStatus"
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <Select
                {...field}
                disabled={disabled}
                options={bookingOptions}
                whiteType
                onChangeValue={onChange}
                defaultOption={
                  bookingOptions.find(
                    (opt) => opt.value === defaultValue?.bookingStatus
                  ) ?? bookingOptions[0]
                }
              />
            )}
          />
        </div>
      </div>
      <div className="form__row">
        <div className="form__item">
          <Input
            disabled={disabled}
            withLabel="Внешняя ссылка на квартиру"
            addClass="form__input"
            size="lg"
            labelSize="small"
            placeholder={""}
            name="externalLink"
            defaultValue={defaultValue?.externalLink}
            type="text"
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__item">
          {planImg && (
            <>
              <p className="input-wrapper__label body-smaller">
                Загруженный план
              </p>
              <img
                src={planImg}
                width={200}
                alt=""
                style={{ marginBottom: 20 }}
              />
            </>
          )}
          <Dropzone
            accept={{
              "image/*": [".jpeg", ".jpg", ".png"],
            }}
            name="photos"
            disabled={disabled}
            label="Загрузите план квартиры"
          />
          {/* <Controller
            name="photos"
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <UploadField
                {...field}
                setValue={onChange}
                withLabel="Загрузите план квартиры"
                preview={photo}
                handleSetPreview={setPhoto}
                fullPreview
              />
            )}
          />
           */}
        </div>
      </div>
    </>
  );
}
