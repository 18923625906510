import CloseButton from "../../../components/ui/CloseButton/CloseButton";

import AddFlatForm from "../forms/AddForm/AddFlatForm";
const AddFlatModal = ({ buildingId = null, closeModal }) => {
  return (
    <div className={`modal`}>
      <CloseButton addClass="modal__close-btn" onClick={closeModal} />

      <AddFlatForm buildingId={buildingId} closeModal={closeModal} />
    </div>
  );
};

export default AddFlatModal;
