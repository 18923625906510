import { useRef, useState } from "react";
import { fileToBase64 } from "../../../utils.js";
import Button from "../Button/Button";
import { Check } from "./Icon";
import classNames from "classnames";

// Загрузка файлов - само поле

// To Do порефакторить

export const UploadField = ({
  setValue,
  preview = null,
  handleSetPreview,
  smallView = false,
  withLabel = "",
  fullPreview = false,
  name,
}) => {
  const [fileList, setFileList] = useState([]);
  const [dragActive, setDragActive] = useState(false);

  const inputRef = useRef(null);

  const handleUploadClick = () => {
    inputRef.current && inputRef.current.click();
  };

  const handleFileSelect = (evt) => {
    evt.target.files && setFileList(evt.target.files);
    setValue(evt.target.files[0]);
    fileToBase64(evt.target.files[0]).then((res) => {
      // setValue(res.replace("data:", "").replace(/^.+,/, ""));
      handleSetPreview && handleSetPreview(res);
    });
  };

  // handle drag events
  const handleDrag = function (evt) {
    evt.preventDefault();
    evt.stopPropagation();

    if (evt.type === "dragenter" || evt.type === "dragover") {
      setDragActive(true);
      return;
    }

    setDragActive(false);
  };

  const handleDrop = function (evt) {
    evt.preventDefault();
    evt.stopPropagation();
    setDragActive(false);
    if (evt.dataTransfer.files && evt.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      // handleFiles(e.dataTransfer.files);

      setFileList(evt.dataTransfer.files);

      setValue(evt.dataTransfer.files[0]);

      fileToBase64(evt.dataTransfer.files[0]).then((res) => {
        // setValue(res.replace("data:", "").replace(/^.+,/, ""));
        handleSetPreview && handleSetPreview(res);
      });
    }
  };

  const files = fileList ? Array.from(fileList) : [];

  const uploadClasses = classNames({
    "upload-field": true,
    "upload-field_small": smallView,
    "upload-field_full-preview": fullPreview,
  });

  return (
    <label
      className="input-wrapper"
      style={smallView ? { width: "fit-content" } : {}}
    >
      {withLabel && (
        <span className="input-wrapper__label body-smaller">{withLabel}</span>
      )}

      <div
        className={uploadClasses}
        // onDragEnter={handleDrag}
        // onDragLeave={handleDrag}
        // onDragOver={handleDrag}
        // onDrop={handleDrop}
        // пока не работает - не добавляются файлы в форму
        // https://www.npmjs.com/package/react-dropzone - попробовать это
        style={dragActive ? { opacity: "0.5" } : { opacity: "1" }}
      >
        <Button
          size="s"
          addClass="upload-field__btn"
          onClick={handleUploadClick}
        >
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.6585 10.2526C15.0741 9.88889 15.1163 9.25713 14.7526 8.84149C14.3889 8.42586 13.7571 8.38374 13.3415 8.74742L11 10.7962V1C11 0.447715 10.5523 0 10 0C9.44771 0 9 0.447715 9 1V10.7962L6.6585 8.74742C6.24287 8.38374 5.61111 8.42586 5.24742 8.84149C4.88374 9.25713 4.92586 9.88889 5.3415 10.2526L9.34149 13.7526C9.71852 14.0825 10.2815 14.0825 10.6585 13.7526L14.6585 10.2526ZM2 13C2 12.4477 1.55228 12 1 12C0.447715 12 0 12.4477 0 13V16C0 17.1046 0.895431 18 2 18H18C19.1046 18 20 17.1046 20 16V13C20 12.4477 19.5523 12 19 12C18.4477 12 18 12.4477 18 13V16H2V13Z"
              fill="#AED6EE"
            />
          </svg>
          <input
            type="file"
            name={name ?? "files"}
            //multiple
            className="visually-hidden"
            onChange={handleFileSelect}
            ref={inputRef}
          />

          {preview && (
            <img src={preview} alt="" className="upload-field__img-preview" />
          )}
        </Button>
        {/* {files && files.length > 0 && (
          <ul className="upload-field__files">
            {files.map((file, i) => (
              <li key={i}>
                <Check />
                <span>{file.name}</span>
              </li>
            ))}
          </ul>
        )} */}
      </div>
    </label>
  );
};
