import AdminTable from "../AdminTable/AdminTable";
import Tabs from "../../../components/ui/Tabs/Tabs";
import SearchInput from "../SearchInput/SearchInput";
import ChangePersonal from "./ChangePersonal";
import "./User.scss";
import { config } from "../../../config";
const User = () => {
  return (
    <div className="admin-user">
      <SearchInput disabled />
      <Tabs underlineView size="14" addClass="admin-user__tabs">
        <div label="Персональные данные">
          <ChangePersonal />
        </div>
        {config.dev_mode && (
          <div label="Заявки на покупку">
            <AdminTable data={{}} />
          </div>
        )}

        {config.dev_mode && (
          <div label="Заявки на продажу">
            <AdminTable data={{}} />
          </div>
        )}

        {config.dev_mode && (
          <div label="Заявка на биржу">
            <AdminTable data={{}} />
          </div>
        )}
      </Tabs>
    </div>
  );
};

export default User;
