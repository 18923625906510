import { useMutation } from "react-query";
import { authOis } from "../../../api";
const useOisAuth = () => {
  return useMutation({
    mutationFn: (body) => {
      return authOis(body);
    },
    onSuccess: (data) => {
      window.location.href = data;
    },
  });
};

export default useOisAuth;
