export const aboutLinks = [
  {
    id: 0,
    title: "О проекте",
    link: "about",
  },

  {
    id: 1,
    title: "Ответы на вопросы",
    link: "faq",
  },

  // {
  //   id: 2,
  //   title: "Публичная оферта",
  // },
  // {
  //   id: 3,
  //   title: "Правовая информация",
  // },
];
