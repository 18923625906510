import Button from "../Button/Button";
import CoinsIcon from "../../../assets/img/icons/coins.svg";
import useOisAuth from "../../../hooks/fetchers/user/useOisAuth";

const NeedToEnterScreen = ({ closeModal }) => {
  const oisAuth = useOisAuth();
  return (
    <div className="success-screen success-screen_inner-padding">
      <div className="success-screen__content">
        <div>
          <img src={CoinsIcon} alt="" />
        </div>

        <span className="h7 text-blue">Учётная запись не подтверждена</span>
        <p className="text-gray" style={{ marginTop: 8 }}>
          Для продолжения оформления заявок необходимо пройти регистрацию у
          партнёра
        </p>
      </div>

      <div className="sign__btns">
        <Button
          buttonType="primary"
          size={"m"}
          onClick={() => {
            oisAuth.mutate();
          }}
          disabled={oisAuth.isLoading}
          isFull
        >
          Зарегистрироваться у партнёра
        </Button>

        <Button buttonType="secondary" size="m" isFull onClick={closeModal}>
          Отмена
        </Button>
      </div>
    </div>
  );
};

export default NeedToEnterScreen;
