import { getEmitterById } from "../../api";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
const useEmitter = () => {
  const { id } = useParams();

  const emitter = useQuery({
    queryKey: ["emitters", id],
    queryFn: () => getEmitterById(id),
    enabled: !!id,
  });
  return emitter;
};

export default useEmitter;
