import { getPoolById, getAllPools } from "../../api";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

export const usePool = () => {
  const { id } = useParams();

  const pool = useQuery({
    queryKey: ["pools", id],
    queryFn: () => getPoolById(id),
    enabled: !!id,
  });
  return pool;
};

export const usePoolId = (id) => {
  return useQuery({
    queryKey: ["pools", id],
    queryFn: () => getPoolById(id),
    enabled: !!id,
  });
};

export const usePools = () => {
  return useQuery({
    queryKey: ["pools"],
    queryFn: getAllPools,
  });
};
