import { useState } from "react";
import Button from "../../components/ui/Button/Button";
const BanBtn = ({ isBanned = false, text = "пользователя" }) => {
  const [banState, setBanState] = useState(isBanned);
  const banClickHandler = () => {
    // logic
    setBanState(!banState);
  };
  return (
    <Button
      type="button"
      buttonType={banState ? "secondary-gray" : "secondary-red"}
      size={"m"}
      onClick={banClickHandler}
    >
      {`${banState ? "Разблокировать" : "Заблокировать"} ${text}`}
    </Button>
  );
};

export default BanBtn;
