import AdminTable from "../AdminTable/AdminTable";
import SearchInput from "../SearchInput/SearchInput";
import AddBtn from "../AddButton";
import Flexbox from "../../../components/ui/Flexbox/Flexbox";
import useModal from "../../../hooks/useModal";
import Modal from "react-modal";
import AddFlatModal from "../AddModal/AddFlatModal";
import { useQuery } from "react-query";
import { getAllFlats } from "../../../api";
import { formatFlatsInfo } from "../AdminTable/AdminTableFormaters";
import { Loader } from "../../../components/ui/Loader/Loader";

const Flats = () => {
  const [modalIsOpen, openModal, closeModal] = useModal();

  const { data: flatsData, isLoading } = useQuery("flats", getAllFlats);

  if (isLoading) return <Loader isBlue isLarge />;
  if (!flatsData) return null;
  return (
    <>
      {flatsData && flatsData.length > 0 && <SearchInput />}

      <Flexbox flexDirection="column" fitContent>
        {flatsData.length === 0 ? (
          <p style={{ marginTop: 15 }}>Нет квартир</p>
        ) : (
          <AdminTable data={formatFlatsInfo(flatsData)} />
        )}

        <AddBtn onClick={openModal}>Добавить квартиру</AddBtn>
      </Flexbox>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-wrapper"
        overlayClassName="modal-overlay"
      >
        <AddFlatModal closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default Flats;
