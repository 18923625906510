import { Navigate, Outlet } from "react-router-dom";
import useRole from "../hooks/useRole";
const AdminRoute = ({ redirectPath = "/" }) => {
  const { isAdmin } = useRole();

  if (!isAdmin) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default AdminRoute;
