import React from "react";
import Button from "../../components/ui/Button/Button";

const AddBtn = ({ children, ...args }) => {
  return (
    <Button
      size="m"
      addClass="add-developer-btn"
      style={{ marginLeft: "auto" }}
      {...args}
    >
      {children}
    </Button>
  );
};

export default AddBtn;
