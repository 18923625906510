import Container from "../components/layout/Container/Container";

const FaqPage = () => {
  return (
    <section className="home">
      <Container isReading>
        <h1 className="title">­Ответы на вопросы</h1>

        <div className="text-block">
          <p className="h5 text-title">Как это работает?</p>
          <p>
            1. Выбираете подходящее предложение по приобретению токенов в
            представленных жилых комплексах.
          </p>
          <p>
            2. Указываете количество токенов/сумму. 1 токен = стоимости одного
            квадратного метра. Отправляете заявку на приобретение токенов.
          </p>
          <p>
            3. В установленные сроки Выпуск засчитывается состоявшимся, токены
            начисляются на баланс.
            <br />* В случае если выпуск не состоится денежные средства будут
            возвращены.
          </p>
          <p>
            4. После завершения строительства ЖК, в установленные сроки
            происходит начисление средств.
          </p>
        </div>
        <div className="text-block">
          <p className="h5 text-title">Как определяется доходность?</p>

          <p>
            В <span className="h8">Цифровой метр. Новостройки</span> существует 2 вида получения
            доходности:
          </p>
          <p>
            1. Гарантированная выплата. После завершения строительства
            Застройщик производит заранее определенную сумму. Например, 1 токен
            будет равен 300 000 руб, исходя из этих данных будет производиться
            выплата пропорционально купленным токенам.
          </p>
          <p>
            2. Гарантированных доход 1%, после завершения строительства
            «токенизированные» квартиры выставляются на продажу по рыночной
            цене, исходя от суммы за которую будет продана квартира, происходит
            начисление средств пропорционально купленным токенам.
          </p>

          <p>
            В разных жилых комплексах может использоваться один из двух видов.
          </p>
        </div>
        <div className="text-block">
          <p className="h5 text-title">Сколько токенов можно приобрести?</p>
          <p>
            Токены можно приобрести в любом количестве, в рамках доступного.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default FaqPage;
