export const ArrowIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8 0H4.2C1.8804 0 0 1.8804 0 4.2V16.8C0 19.1196 1.8804 21 4.2 21H16.8C19.1196 21 21 19.1196 21 16.8V4.2C21 1.8804 19.1196 0 16.8 0ZM2.1 4.2C2.1 3.0402 3.0402 2.1 4.2 2.1H16.8C17.9598 2.1 18.9 3.0402 18.9 4.2V16.8C18.9 17.9598 17.9598 18.9 16.8 18.9H4.2C3.0402 18.9 2.1 17.9598 2.1 16.8V4.2ZM10.8075 6.60754C11.2176 6.19749 11.8824 6.19749 12.2925 6.60754C12.7025 7.01759 12.7025 7.68241 12.2925 8.09246L9.88492 10.5L12.2925 12.9075C12.7025 13.3176 12.7025 13.9824 12.2925 14.3925C11.8824 14.8025 11.2176 14.8025 10.8075 14.3925L7.65754 11.2425C7.24749 10.8324 7.24749 10.1676 7.65754 9.75754L10.8075 6.60754Z"
      fill="white"
    />
  </svg>
);

export const ArrowIconBlue = () => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8 0.5H4.2C1.8804 0.5 0 2.3804 0 4.7V17.3C0 19.6196 1.8804 21.5 4.2 21.5H16.8C19.1196 21.5 21 19.6196 21 17.3V4.7C21 2.3804 19.1196 0.5 16.8 0.5ZM2.1 4.7C2.1 3.5402 3.0402 2.6 4.2 2.6H16.8C17.9598 2.6 18.9 3.5402 18.9 4.7V17.3C18.9 18.4598 17.9598 19.4 16.8 19.4H4.2C3.0402 19.4 2.1 18.4598 2.1 17.3V4.7ZM10.8075 7.10754C11.2176 6.69749 11.8824 6.69749 12.2925 7.10754C12.7025 7.51759 12.7025 8.18241 12.2925 8.59246L9.88492 11L12.2925 13.4075C12.7025 13.8176 12.7025 14.4824 12.2925 14.8925C11.8824 15.3025 11.2176 15.3025 10.8075 14.8925L7.65754 11.7425C7.24749 11.3324 7.24749 10.6676 7.65754 10.2575L10.8075 7.10754Z"
      fill="#8FC7E6"
    />
  </svg>
);
