import Modal from "react-modal";
import { useLoginModalStore } from "../store";

const useLoginModal = () => {
  Modal.setAppElement("#root");
  const modalIsOpen = useLoginModalStore((state) => state.loginModalIsOpen);
  const openModal = useLoginModalStore((state) => state.openLoginModal);
  const closeModal = useLoginModalStore((state) => state.closeLoginModal);
  const isLoginAction = useLoginModalStore((state) => state.isLoginAction);
  const setIsLoginAction = useLoginModalStore(
    (state) => state.setIsLoginAction
  );

  return {
    modalIsOpen,
    openModal,
    closeModal,
    isLoginAction,
    setIsLoginAction,
  };
};

export default useLoginModal;
