import "./ErrorMessage.scss";

const ErrorMessage = ({ forRequest, isHidden = false }) => {
  const errorText = forRequest?.error?.response?.data;

  return forRequest.isError && !isHidden ? (
    <p className="error-message">
      {typeof errorText === "string"
        ? errorText
        : Array.isArray(errorText)
        ? errorText[0]?.message
        : "Ошибка сервера"}
    </p>
  ) : null;
};

export default ErrorMessage;
