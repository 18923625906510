import { useQuery } from "react-query";
import Tabs from "../../../components/ui/Tabs/Tabs";
import SearchInput from "../SearchInput/SearchInput";
import ChangePool from "./ChangePool.jsx";
import { getPoolById } from "../../../api";
import { Loader } from "../../../components/ui/Loader/Loader";
import { PoolInfo } from "./PoolInfo";
import { formatFlatsInfo } from "../AdminTable/AdminTableFormaters.js";
import AdminTable from "../AdminTable/AdminTable";
import ReleasesOfPool from "./ReleasesOfPool.jsx";
import usePathId from "../../../hooks/usePathId.jsx";

const Pool = () => {
  const id = usePathId();

  const { data, isSuccess } = useQuery("pools", async () => getPoolById(id));

  return data && isSuccess ? (
    <div className="admin-user">
      <SearchInput disabled />
      <Tabs underlineView size="14" addClass="admin-user__tabs">
        <div
          label="Данные о пуле"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 540px",
            justifyContent: "space-between",
            gap: "10%",
          }}
        >
          <ChangePool pool={data} />
          <PoolInfo pool={data} />
        </div>
        <div label="Список квартир">
          <AdminTable data={formatFlatsInfo(data.flatsList)} />
        </div>
        <div label="Выпуски пула">
          <ReleasesOfPool poolId={data.id} />
        </div>
      </Tabs>
    </div>
  ) : (
    <Loader isBlue isLarge />
  );
};

export default Pool;
