import {useFormContext} from "react-hook-form";
import {useMutation} from "react-query";
import {addFiles, deleteImagesToBuildingById} from "../../../api";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {getFilesFormData} from "../../../utils";
import {useDropzone} from "react-dropzone";
import classNames from "classnames";
import {Loader} from "../Loader/Loader";

const UploadIcon = () => (
    <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6585 10.2526C15.0741 9.88889 15.1163 9.25713 14.7526 8.84149C14.3889 8.42586 13.7571 8.38374 13.3415 8.74742L11 10.7962V1C11 0.447715 10.5523 0 10 0C9.44771 0 9 0.447715 9 1V10.7962L6.6585 8.74742C6.24287 8.38374 5.61111 8.42586 5.24742 8.84149C4.88374 9.25713 4.92586 9.88889 5.3415 10.2526L9.34149 13.7526C9.71852 14.0825 10.2815 14.0825 10.6585 13.7526L14.6585 10.2526ZM2 13C2 12.4477 1.55228 12 1 12C0.447715 12 0 12.4477 0 13V16C0 17.1046 0.895431 18 2 18H18C19.1046 18 20 17.1046 20 16V13C20 12.4477 19.5523 12 19 12C18.4477 12 18 12.4477 18 13V16H2V13Z"
            fill="#AED6EE"
        />
    </svg>
);

const DropzoneImage = (props) => {

    const { name, disabled = false, onSubmit, buildingimagelink, buildingimageid, editmode, buildingid } = props;
    const { register, unregister, setValue, watch, reset } = useFormContext();
    const files = watch(name);
    const addFilesMutation = useMutation({ mutationFn: addFiles });
    const deleteImageMutation = useMutation({ mutationFn: deleteImagesToBuildingById });

    useEffect(() => {
        let isMounted = true;
        if(buildingimageid) {
            isMounted && setValue(name, buildingimageid, { shouldValidate: true })
        }
        return () => {
            isMounted = false;
        }
    },[])

    const onDrop = useCallback(
        (droppedFiles) => {
                addFilesMutation.mutate(getFilesFormData(droppedFiles), {
                    onSuccess: (data) => {
                        if(name === 'addImage') {
                            setValue(name, data[0], { shouldValidate: true });
                            onSubmit();
                            reset();
                        } else {
                            deleteImageMutation.mutate({
                                id: buildingid, imageId: buildingimageid
                            }, {
                                onSuccess: () => {
                                    setValue('addImage', data[0], { shouldValidate: false });
                                    onSubmit();
                                    reset();
                                }
                            })
                        }
                    },
                })
        },
        [setValue, name]
    );

    const onButtonClickDelete = (buildingId, imageId) => {
        deleteImageMutation.mutate({id: buildingId, imageId: imageId}, {
            onSuccess: () => {
                onSubmit();
                }
            }
        )
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: props.accept,
        disabled: disabled,
    });

    useEffect(() => {
        register(name);
        return () => {
            unregister(name);
        };
    }, [register, unregister, name]);

    const uploadClasses = classNames({
        "upload-field": true,
        "upload-field_hovered": isDragActive,
    });

    const uploadNameDocumentClasses = classNames({
        "upload-field-name-doc": true
    });

    const uploadEditDocumentClasses = classNames({
        "upload-field-edit-doc": true
    });

    return (
        <>
            {editmode === 'false' && buildingimageid && (
                <label className="input-wrapper">
                    <div
                        className={uploadNameDocumentClasses}
                        style={{backgroundImage: `url(${buildingimagelink})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            height: '165px',
                            border: '1px dashed #8FC7E6'
                        }}
                    >
                    </div>
                </label>
            )}
            {editmode === 'true' && buildingimageid && (
                <label className="input-wrapper" htmlFor={name}>
                    <div
                        className={uploadEditDocumentClasses}
                        style={{backgroundImage: `url(${buildingimagelink})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            height: '165px',
                        }}
                    >
                        {/*<a href={buildingimagelink}>Открыть</a>/*/}
                        {/*<div*/}
                        {/*    {...getRootProps()}*/}
                        {/*    type="file"*/}
                        {/*    role="button"*/}
                        {/*    aria-label="File Upload"*/}
                        {/*    id={name}*/}
                        {/*>*/}
                        {/*    <p>Заменить</p>*/}
                        {/*    <input disabled={disabled} {...props} {...getInputProps()} />*/}
                        {/*</div>*/}
                        {/*<p>/</p>*/}
                        <p onClick={() => onButtonClickDelete(buildingid, buildingimageid)}>Удалить</p>
                    </div>
                </label>
            )}
            {editmode === 'true' && buildingimageid === null && (
                <label className="input-wrapper" htmlFor={name}>
                    <div
                        {...getRootProps()}
                        type="file"
                        role="button"
                        aria-label="File Upload"
                        id={name}
                        className={uploadClasses}
                        disabled={disabled}
                        style={{height: '165px'}}
                    >
                        <UploadIcon/>
                        <input disabled={disabled} {...props} {...getInputProps()} />
                    </div>
                </label>
            )}
        </>
    );
};

export default DropzoneImage;