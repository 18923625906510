import classNames from "classnames";
import "./index.scss";
import infoSvg from "../../../assets/img/icons/info.svg";
import { ComparisonSvg } from "./Icons";
import Popup from "../Popup/Popup";

const ComparisonBlock = ({ data, repaymentMethod = "" }) => {
  const comparisonClass = classNames({
    "comparison-block": true,
  });

  if (!data) return null;

  return (
    <article className={comparisonClass}>
      <div className="comparison-block__content">
        <div className="comparison-block__box">
          <div className="comparison-block__title">
            Цена токена на данный момент
            <Popup>
              Стоимость токена в действующем выпуске ЦФА. 1 токен равен 100 ЦФА.
            </Popup>
          </div>

          <p className="comparison-block__price">
            1 токен = {data.price.toLocaleString()} ₽
          </p>
          <span className="comparison-block__text">1 токен = 1 м²</span>
        </div>

        <div className="comparison-block__box">
          <div className="comparison-block__title">
            Цена токена по завершению строительства
            <Popup>
              {repaymentMethod === "GUARANTEED_INCOME" &&
                "В данном выпуске ЦФА, после завершения строительства Застройщик выплатит фиксированную гарантированную доходность."}
              {repaymentMethod === "SALES_INCOME" &&
                "В данном выпуске ЦФА, после завершения строительства Застройщик продаст токенизированные квартиры и распределит доходность между инвесторами."}
            </Popup>
          </div>

          <p className="comparison-block__price">
            1 токен = {data.pool.exitPrice.toLocaleString()} ₽
          </p>
          <span className="comparison-block__text">1 токен = 1 м²</span>
        </div>
      </div>

      <ComparisonSvg />
    </article>
  );
};

export default ComparisonBlock;
