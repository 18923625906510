import AdminTable from "../AdminTable/AdminTable";
import SearchInput from "../SearchInput/SearchInput";
import { getUsers } from "../../../api";
import { useQuery } from "react-query";
import { formatId, getFullName } from "../../../utils";
import { Loader } from "../../../components/ui/Loader/Loader";
const Users = () => {
  const { data: users, isLoading } = useQuery({
    queryKey: ["emitters"],
    queryFn: getUsers,
  });

  if (isLoading) return <Loader isLarge isBlue />;
  if (!users) return null;

  return (
    <>
      <SearchInput />
      {users && users.length > 0 && (
        <AdminTable
          data={{
            "ID ЦМ пользователя": users?.map((user) => ({
              name: formatId(user?.id),
              link: `../users/${user?.id}`,
            })),
            "ID ОИС": users?.map((it) => it.idOis && formatId(it.idOis)),
            Пользователь: users?.map((it) => getFullName(it)),
            "Номер телефона": users?.map((it) => it.phone),
            Email: users?.map((it) => it.email),
          }}
        />
      )}
    </>
  );
};

export default Users;
