import classNames from "classnames";
import Button from "../Button/Button";
import Input from "../Input/Input";
import CloseButton from "../CloseButton/CloseButton";
import { useState, useMemo, useEffect, useCallback } from "react";
import "./index.scss";
import ReleaseLink from "../ReleaseLink/ReleaseLink";
import { getOldestRelease, formatTokensForDisplay } from "../../../utils";
import LimitInvestor from "./LimitInvestor";
import SignForm from "./SignForm";
import PaymentScreen from "./PaymentScreen";
import NeedToEnterScreen from "./NeedToEnterScreen";
import NeedToVerifyScreen from "./NeedToVerifyScreen";
import NoTokensScreen from "./NoTokensScreen";
import usePathId from "../../../hooks/usePathId";
import usePurchaseData from "../../../hooks/usePurchaseData";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import useIsVerified from "../../../hooks/useIsVerified";
import { useMutation } from "react-query";
import { initOrderMutation } from "../../../api";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useActiveOrdersForUser } from "../../../hooks/fetchers/orders";
import useOisHealth from "../../../hooks/fetchers/useOisHealth";
import NoApiScreen from "./NoApiScreen";
import ProspectusErrorScreen from "./ProspecusErrorScreen";
import SuccessScreen from "./SuccessScreen";
const PurchaseCard = ({
  activeReleases,
  isHorizontal,
  isMySells,
  addClass,
  isOpened,
  setIsOpened,
  availableTokens,
  buttonText = "Далее",
}) => {
  const id = usePathId();
  const isLoggedIn = useIsLoggedIn();
  const isVerified = useIsVerified();

  const [step, setStep] = useState(
    availableTokens > 0 ? "calculator" : "no-tokens"
  );

  // Шаг покупки
  // "calculator" -> "sign-form" -> "payment-screen" -> "loading-screen" -> "success-screen" | "need-to-enter" | "need-to-verify" | "no-tokens" | "no-api"

  const setOrderStatus = useCallback(
    (status) => {
      console.log("status in setOrderStatus: ", status);
      if (availableTokens <= 0) setStep("no-tokens");
      if (status === "INIT_PROSPECTUS_CREATED") setStep("sign-form");
      if (status === "INIT_PROSPECTUS_SENT") setStep("loading-screen");
      if (status === "WAITING_FOR_PAYMENT") setStep("payment-screen");
      if (
        [
          "WAITING_FOR_CONFIRMATION",
          "PAYMENT_CONFIRMED",
          "WAITING_ISSUE",
        ].includes(status)
      )
        setStep("success-screen");
    },
    [availableTokens]
  );

  const {
    data: activeOrders,
    isLoading: isActiveOrdersLoading,
    refetch: refetchActiveOrders,
  } = useActiveOrdersForUser(
    {
      pool_id: id,
    },

    // onSuccess
    (data) => {
      if (data && data[0]) {
        const lastActiveOrder = data[data.length - 1];
        setOrder({
          internalId: lastActiveOrder.id,
          prospectusId: lastActiveOrder.initProspectusId,
        });
        setOrderStatus(lastActiveOrder.status);
        return;
      }
      setStep(availableTokens > 0 ? "calculator" : "no-tokens");
    }
  );

  const { data: oisHealth } = useOisHealth({
    onSuccess: () => {
      if (!oisHealth) setStep("no-api");
    },
  });

  const release = getOldestRelease(activeReleases);

  const MIN_PRICE = 10000;
  const MAX_PRICE = 6000000000;

  const { savePurchaseData, getPurchaseData, clearPurchaseData } =
    usePurchaseData();
  const data = getPurchaseData();
  const [price, setPrice] = useState(data?.price);
  const [amount, setAmount] = useState(data?.amount);
  const [showLimit, setShowLimit] = useState(false);

  const [order, setOrder] = useState({
    internalId: "",
    prospectusId: "",
  });

  const totalAmount = useMemo(
    () =>
      activeReleases &&
      activeReleases.reduce(
        (sumTokens, release) =>
          sumTokens + formatTokensForDisplay(release.availableTokens),
        0
      ),
    [activeReleases]
  );

  const onAmountChange = useCallback(
    (value) => {
      setAmount(value);
      setPrice((value * release.price).toFixed(2).replace(/\.00$/, ""));
    },
    [release]
  );

  const onPriceChange = useCallback(
    (value) => {
      setPrice(value);
      setAmount(
        Math.floor((value * 100) / release.price).toFixed(2) / 100
        // так нужно, чтобы округляло вниз корректно после toFixed
      );
    },
    [release]
  );

  // Пункт 1 - инициализация order
  const initOrder = useMutation({
    mutationFn: initOrderMutation,
    onSuccess: (data) => {
      const convertValues = Object.values(data);
      const lastActiveOrder = convertValues[convertValues.length - 1];
      const getValues = Object.values(lastActiveOrder); // ToDo - переделать под несколько релизов
      setOrder({
        internalId: getValues[0].id,
        prospectusId: getValues[0].initProspectusId,
      });

      setOrderStatus(getValues[0].status);
      clearPurchaseData();
    },
  });

  const refetchOrder = useCallback(() => {
    refetchActiveOrders().then(() => {
      if (
        activeOrders &&
        activeOrders[0] &&
        activeOrders[activeOrders.length - 1].status === "WAITING_FOR_PAYMENT"
      ) {
        setStep("payment-screen");
      }
    });
  }, [activeOrders, refetchActiveOrders]);

  const isLoading = initOrder.isLoading;

  useEffect(() => {
    if (amount > totalAmount) {
      onAmountChange(totalAmount);
    }
  }, [amount, price, onAmountChange, totalAmount, onPriceChange]);

  useEffect(() => {
    // Рефакторинг
    !oisHealth
      ? setStep("no-api")
      : availableTokens === 0
      ? setStep("no-tokens")
      : activeOrders && activeOrders[0]
      ? setOrderStatus(activeOrders[activeOrders.length - 1].status)
      : setStep("calculator");
  }, [
    availableTokens,
    setOrderStatus,
    activeOrders,
    isOpened,
    isLoggedIn,
    isVerified,
    oisHealth,
  ]);

  useEffect(() => {
    let timeoutId;
    if (step === "loading-screen") {
      timeoutId = setTimeout(() => {
        refetchOrder();
      }, 3000);
    }
    return () => {
      if (!!timeoutId) clearTimeout(timeoutId);
    };
  }, [step, refetchOrder, activeOrders]);

  const checkPrice = () => {
    if (price < MIN_PRICE) {
      onPriceChange(MIN_PRICE);
    }
    if (price > MAX_PRICE) {
      onPriceChange(MAX_PRICE);
      setShowLimit(true);
    }
  };

  if (!activeReleases) return null;

  const enableToBuy = () => price > 0 && amount > 0;

  const purchaseCardClass = classNames({
    "purchase-card": true,
    "purchase-card_h": isHorizontal,
    "purchase-card_my-sells": isMySells,
    "purchase-card_opened": isOpened,
    [`${addClass}`]: addClass,
  });

  let currentAmount = 0; // хранит количество токенов в отрендереных релизах

  const buttonNextHandler = () => {
    savePurchaseData({ id, price, amount });

    if (!isLoggedIn) {
      setStep("need-to-enter");
      return;
    }
    if (!isVerified) {
      setStep("need-to-verify");
      return;
    }

    //---Запрос на инициализацию заявки---//
    initOrder.mutate([
      {
        DfaOisKey: release.idOis,
        Amount: amount * 100,
      },
    ]);
  };

  return (
    <article className={purchaseCardClass}>
      <CloseButton
        addClass="purchase-card__close-btn"
        onClick={() => setIsOpened(false)}
      />

      {step === "calculator" && (
        <>
          <div className="purchase-card__body">
            {!isMySells && (
              <div className="purchase-card__item purchase-card__item_token">
                <span className="purchase-sell-info__key">Цена токена</span>
                <span className="purchase-sell-info__value">
                  {`1 токен = ${release.price.toLocaleString()} ₽`}
                </span>
                {isHorizontal ? (
                  <span className="purchase-card__descr body-smaller">
                    Рыночная цена на данный момент
                  </span>
                ) : (
                  <span className="purchase-card__descr base-14">
                    От застройщика
                  </span>
                )}
              </div>
            )}

            <div className="purchase-card__item">
              <span className="purchase-sell-info__key">О токене</span>
              <div className="purchase-card__releases-grid">
                {activeReleases.map((release, i) => {
                  const availableTokens = formatTokensForDisplay(
                    release.availableTokens
                  );
                  if (i === 0 || amount > currentAmount) {
                    currentAmount += availableTokens;
                    return (
                      <div key={`purchase-release-${release.id}`}>
                        <ReleaseLink release={release} />
                        {availableTokens > 0 && (
                          <span className="purchase-card__release-info">
                            {availableTokens.toLocaleString()}
                          </span>
                        )}
                      </div>
                    );
                  }
                  return null; // остальные токены отображаться не будут, если количество токенов меньше
                })}
              </div>
            </div>

            <div className="purchase-card__item purchase-card__item_count">
              <span className="purchase-sell-info__key">Количество</span>
              <Input
                type="number"
                min={0}
                labelSize={"small"}
                value={amount}
                onChange={(evt) => onAmountChange(evt.target.value)}
                onBlur={checkPrice}
                addClass={amount > 0 ? null : "text-gray"}
              />
              <span className="purchase-card__descr base-12">
                {`Всего доступно ${totalAmount} токенов`}
              </span>
            </div>

            <div className="purchase-card__item purchase-card__item_price">
              <span className="purchase-sell-info__key">Стоимость</span>
              <Input
                type="number"
                min={MIN_PRICE}
                labelSize={"small"}
                value={price}
                onChange={(evt) => onPriceChange(evt.target.value)}
                onBlur={checkPrice}
                addClass={price > 0 ? null : "text-gray"}
              />
              <span className="purchase-card__descr base-12">
                {isMySells ? (
                  `Рекомендованная цена  100 тыс. ₽ за 1 токен`
                ) : (
                  <>
                    Минимальная сумма ввода <b>50</b> тыс. ₽
                  </>
                )}
              </span>
            </div>
          </div>

          {showLimit && <LimitInvestor limit={MAX_PRICE} />}

          <Button
            addClass="purchase-card__btn"
            buttonType={enableToBuy() ? "primary" : "secondary"}
            isFull
            disabled={!enableToBuy() || isLoading}
            onClick={buttonNextHandler}
            isLoading={isLoading}
          >
            {buttonText}
          </Button>
          <ErrorMessage forRequest={initOrder} isHidden={isLoading} />
        </>
      )}

      {step === "sign-form" && (
        <SignForm
          releases={activeReleases
            .map((release, i) => {
              if (i === 0 || amount > currentAmount) {
                currentAmount += release.availableTokens;
                return release;
              }
              return null; // остальные релизы отображаться не будут, если количество токенов меньше
            })
            .filter((it) => it !== null)}
          onProspectusError={() => {
            setStep("prospectus-error");
          }}
          afterSubmit={() => refetchOrder()}
          amount={amount}
          order={order}
        />
      )}

      {step === "loading-screen" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 15,
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: 18 }}>Запрос обрабатывается</p>
          <Button
            onClick={refetchOrder}
            isLoading={isActiveOrdersLoading}
            disabled={isActiveOrdersLoading}
          >
            Обновить статус сделки
          </Button>
        </div>
      )}

      {step === "payment-screen" && (
        <PaymentScreen
          orderId={order.internalId}
          onSuccessPayment={() => setStep("success-screen")}
        />
      )}

      {step === "success-screen" && (
        <SuccessScreen orderId={order.internalId} />
      )}

      {step === "need-to-enter" && (
        <NeedToEnterScreen closeModal={() => setIsOpened(false)} />
      )}
      {step === "need-to-verify" && (
        <NeedToVerifyScreen closeModal={() => setIsOpened(false)} />
      )}

      {step === "prospectus-error" && (
        <ProspectusErrorScreen
          closeModal={() => {
            setStep("calculator");
            setIsOpened(true);
          }}
        />
      )}

      {step === "no-tokens" && (
        <NoTokensScreen closeModal={() => setIsOpened(false)} />
      )}
      {step === "no-api" && (
        <NoApiScreen closeModal={() => setIsOpened(false)} />
      )}
    </article>
  );
};

export default PurchaseCard;
